import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import "./style.css";
import Button from "../../components/library/Button";
import FormAlertPopUp from "../../components/FormAlertPopUp";
import { useEffect, useState } from "react";
import CreateWalletForm from "../../components/formComponents/CreateWalletForm";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import moment from "moment";
import AlertPopUp from "../../components/AlertPopUp";
import { errorToast, successToast } from "../../utils/toast";
import SearchInput from "../../components/SearchInput";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../components/Pagination";
import Lottie from "react-lottie";
import noData from "../../base/Animation/No Data Found.json";
import { useLoader } from "../../base/Context/loaderProvider";
import { useApi } from "../../base/Context/apiProvider";
import ImagePopUpModal from "../../components/ImagePopUpModal";
import jsPDF from "jspdf";
import { exportToCSV, exportToExcel } from "react-easy-export";

const Coin = () => {
  const [showCreateWallet, setShowCreateWallet] = useState(false);
  const [getCoin, setGetCoin] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showEditAlert, setShowEditAlert] = useState(false);
  const [id, setId] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [img, setImg] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [exportAllData, setExportAllData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    getCoin.map((item,index) => {
      let obj = {
        "S.No":index+1,

        " Coin Id": item?.walletId,
       ' No of Coin': item?.coins,
        Price: item?.price,
        "Offer Price": item?.offer,
        "Coin Image": item?.walletUrl,
        "Created At": moment(item?.createdAt).format("DD/MM/YYYY , LT"),

        "Updated At": moment(item?.updatedAt).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });

    setExportAllData(array);
  }, [getCoin]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Coins";
    const headers = [
      [
        "S.No",
        " Coin Id",
        "No of Coin",
        "Price",
        "Offer Price",
        "Coin Image",
        "Created At",
        "Updated At",
      ],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1[" Coin Id"],
      item1["Coin"],
      item1["Price"],
      item1["Offer Price"],
      item1["Coin Image"],
      item1["Created At"],
      item1["Updated At"],
    ]);
    const filename =`Coins Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const handleDeleteAlert = () => {
    setShowDeleteAlert(true);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };
  useEffect(() => {
    fetchCoin();
  }, [page, perPage, apiProvider?.getApiUrl(), endDate]);

  const fetchCoin = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETWALLET,
      "POST",
      {
        page,
        startDate,
        endDate,
        perPage,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setGetCoin(res.result);
        setTotalCount(res.totalCount);
        setTotalPages(res.totalPages);
      })
      .catch((err) => {
        setGetCoin([]);
        loader.showLoader(false);
        console.log(err);
      });
  };

  const handleOnClickAlert = (id) => {
    setId(id);
    setShowDeleteAlert(true);
  };

  const handleDelete = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DELETECOIN + `/${id}`,
      "DELETE"
    )
      .then((res) => {
        setShowDeleteAlert(false);
        fetchCoin();
      })
      .catch((err) => {
        console.log(err);
        errorToast(err.message);
      });
  };

  const onSubmit = () => {
    setShowCreateWallet(false);
    fetchCoin();
  };

  const handleCreateWallet = () => {
    setShowCreateWallet(true);
  };

  const handleCreateWalletClose = () => {
    setShowCreateWallet(false);
  };

  const handleOnClickEdit = (id) => {
    setShowEditAlert(true);
    setId(id);
  };

  const handleOnClickEditClose = () => {
    setShowEditAlert(false);
  };

  const onClickEdit = () => {
    setShowEditAlert(false);
    fetchCoin();
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleEyeClick = (img) => {
    setShowImage(true);
    setImg(img);
  };

  const handleEyeClickClose = () => {
    setShowImage(false);
  };

  const handleExportData = () => {
    const filename =`Coins Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCSV = () => {
    const filename =`Coins Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  return (
    <>
      {/* <div onClick={handleCreateWallet} className="add__wallet">
        <Button text="Create Coin" />
      </div> */}
      <div className="banner__search__btn">
        <SearchInput placeholder="Search" icon={searchIcon()} />
      </div>
      <div className="coin_history_btn">
        <label>Start Date</label>
        <input
          type="date"
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        />
        <label>End Date</label>
        <input
          type="date"
          onChange={handleEndDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        />

        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCSV} />
        <Button text="Export Pdf" onClick={exportPDF} />
        <div onClick={handleCreateWallet} className="add__wallet_coin">
          <Button text="Create Coin" />
        </div>
      </div>
      <div className="wallet__container web_table_container">
        <table className="wallet__table">
          <thead>
            <th className="wallet__header">S.No.</th>
            <th className="wallet__header">Coin ID</th>

            <th className="wallet__header">No of Coin</th>
            <th className="wallet__header">Price</th>
            {/* <th className="wallet__header">No.of Shares</th> */}

            <th className="wallet__header">Offer Price</th>
            <th className="wallet__header">Coin Image</th>
            <th className="wallet__header">Created At</th>
            <th className="wallet__header">Updated At</th>
            <th className="wallet__header">Action</th>
          </thead>
          <tbody>
            {getCoin && getCoin.length > 0
              ? getCoin.map((data, index) => {
                  return (
                    <tr>
                      <td className="wallet__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="wallet__data">{data?.walletId}</td>

                      <td className="wallet__data">{data?.coins}</td>
                      <td className="wallet__data">{data?.price}</td>
                      {/* <td className="wallet__data">{data?.count}</td> */}

                      <td className="wallet__data">{data?.offer}</td>
                      <td className="wallet__data">
                        {data?.walletUrl ? (
                          <AiFillEye
                            className="wallet__eye__icon"
                            onClick={() => handleEyeClick(data?.walletUrl)}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                      <td className="wallet__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY LT")}
                      </td>
                      <td className="wallet__data">
                        {moment(data?.updatedAt).format("DD/MM/YYYY LT")}
                      </td>
                      <td className="wallet__data wallet__icons">
                        <AiFillEdit
                          onClick={() => {
                            handleOnClickEdit(data?._id);
                          }}
                          className="wallet__edit__icon"
                        />
                        <AiFillDelete
                          onClick={() => {
                            handleOnClickAlert(data._id);
                          }}
                          className="wallet__delete__icon"
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        {!getCoin.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
      </div>

      {getCoin.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          perPage={perPage}
          setPerPage={setPerPage}
          options={[20, 25, 30, 35]}
        />
      ) : null}

      <FormAlertPopUp
        open={showCreateWallet}
        onRequestClose={handleCreateWalletClose}
      >
        <CreateWalletForm onSubmit={onSubmit} />
      </FormAlertPopUp>

      <AlertPopUp
        open={showDeleteAlert}
        handleOpen={handleDeleteAlert}
        handleClose={handleDeleteAlertClose}
        header="Delete Alert"
        description="Are you sure you want to delete this coin?"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleDelete}
        onCancelClick={handleDeleteAlertClose}
      />

      <FormAlertPopUp
        open={showEditAlert}
        onRequestClose={handleOnClickEditClose}
      >
        <CreateWalletForm onClickEdit={onClickEdit} id={id} edit={true} />
      </FormAlertPopUp>

      <ImagePopUpModal
        open={showImage}
        handleClose={handleEyeClickClose}
        img={img}
      />
    </>
  );
};

export default Coin;
