import { useEffect, useState } from "react";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { errorToast, successToast } from "../../../utils/toast";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";

const CreateWalletForm = ({ onSubmit, id, onClickEdit, edit }) => {
  const [coins, setCoins] = useState("");
  const [price, setPrice] = useState("");
  const [offer, setOffer] = useState("");
  const [image, setImage] = useState("");
  const [error, setError] = useState({
    coinError: "",
    priceError: "",
    offerError: "",
    imageError: "",
  });
  console.log(coins, price, offer, image, "huhuhhuhuuuuuuuuuu", error);
  const loader = useLoader();
  const apiProvider = useApi();

  const handleCreateCoin = () => {
    if (validate()) {
      loader.showLoader(true);
      let data = new FormData();
      data.append("coins", coins);
      data.append("price", price);
      data.append("offer", offer);
      data.append("image", image);
      loader.showLoader(true);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.ADDWALLET,
        "POST",
        data,
        {
          "Content-Type": "multipart/form-data",
        }
      )
        .then((res) => {
          successToast(res.message);
          onSubmit();
        })
        .catch((err) => {
          errorToast(err.message);
        })
        .finally(() => {
          loader.showLoader(false);
        });
    }
  };

  useEffect(() => {
    getOneCoin();
  }, []);

  const handleEditCoin = () => {
    if (validate()) {
      loader.showLoader(true);
      let data = new FormData();
      data.append("coins", coins);
      data.append("price", price);
      data.append("offer", offer);
      data.append("image", image);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.UPDATEWALLET + `/${id}`,
        "PUT",
        data,
        {
          "Content-Type": "multipart/form-data",
        }
      )
        .then((res) => {
          onClickEdit();
          loader.showLoader(false);
          // successToast(res.message);
        })
        .catch((err) => {
          loader.showLoader(false);
          errorToast(err.message);
        });
    }
  };

  const getOneCoin = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETONECOIN + `/${id}`,
      "GET"
    )
      .then((res) => {
        loader.showLoader(false);
        setCoins(res?.result?.coins);
        setOffer(res?.result?.offer ? res?.result?.offer : 0);
        setPrice(res?.result?.price);
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };

  const handleCoin = (e) => {
    setError({ ...error, coinError: "" });
    setCoins(e.target.value.replace(/\D/g, ""));
  };

  const handlePrice = (e) => {
    setError({ ...error, priceError: "" });
    setPrice(e.target.value.replace(/\D/g, ""));
  };

  const validate = () => {
    let result = true;
    if (!coins) {
      setError({ ...error, coinError: "Number of coins is required" });
      result = false;
    } else if (!price) {
      setError({ ...error, priceError: "Price is required" });
      result = false;
    } else if (!offer) {
      setError({ ...error, offerError: "offer Price  is required" });
      result = false;
    } else if (!image) {
      setError({ ...error, imageError: "Image is required" });
      result = false;
    }
    return result;
  };

  return (
    <div style={{ padding: "12px 10px" }}>
      {edit ? (
        <h2 className="create__wallet__header">Update Coins</h2>
      ) : (
        <h2 className="create__wallet__header">Create Coins</h2>
      )}

      <div style={{ padding: "1rem 2rem" }}>
        <InputField
          type="text"
          onChange={handleCoin}
          placeholder="Coin"
          error={error.coinError}
          // pattern="\d"
          value={coins}
          maxlength="5"
        />
        <br />
        <InputField
          type="text"
          onChange={handlePrice}
          placeholder="Price"
          error={error.priceError}
          value={price}
          maxlength="5"
        />
        <br />
        <InputField
          type="text"
          onChange={(e) => {
            setOffer(e.target.value.replace(/\D/g, ""));
          }}
          placeholder="Offer Price"
          value={offer}
          error={error.offerError}
          maxlength="5"
        />
        <br />
        <InputField
          type="file"
          onChange={(e) => {
            setImage(e.target.files[0]);
          }}
          accept=".jpg , .png , .jpeg"
          error={error.imageError}
        />
        <p className="add_gift_para">NOTE: Image should be 1200x1200 px</p>

        <br />
        <Button
          onClick={edit ? handleEditCoin : handleCreateCoin}
          className="create__wallet__btn"
          text={edit ? "Update" : "Done"}
        />
      </div>
    </div>
  );
};

export default CreateWalletForm;
