import { useEffect, useState } from "react";
import NotificationTable from "../../components/Table/NotificationTable";
import Button from "../../components/library/Button";
import "./style.css";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";

import { useNavigate, useSearchParams } from "react-router-dom";
import jsPDF from "jspdf";
import moment from "moment";

const Notification = () => {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [array, setArray] = useState([]);

  useEffect(() => {
    let arr = [];
    array.map((item, index) => {
      let obj = {
        "S.No": index + 1,

        "Notification Type": item?.to,
        "Send To": item?.notificationType,
        Title: item?.title,
        Message: item?.body,
        Image: item?.imageUrl,
        "Opened By": item?.count,
        "Bounce Back": item?.failedUser?.length || item?.failedHost?.length,
      };
      arr.push(obj);
    });

    console.log("hsjkhsjdkhs");
    setExportAllData(arr);
  }, [array]);

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Gift";
    const headers = [
      [
        "S.No.",
        "Notification Type",
        "Send To",
        "Title",
        " Message",
        "Image",
        "Opened By",
        "Bounce Back",
      ],
    ];

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Notification Type"],
      item1["Send To"],
      item1["Title"],
      item1["Message"],
      item1["Image"],
      item1["Opened By"],
      item1["Bounce Back"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Notifications Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const handleExportCsv = () => {
    const filename =`Snapshots Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handleExportData = () => {
    const filename =`Snapshots Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  return (
    <>
      <div className="send__notification__btn">
        <label>Start Date</label>
        <input
          type="date"
          onChange={handleStartDate}
          value={startDate}
          max={new Date().toISOString().split("T")[0]}
        />
        <label>End Date</label>
        <input
          type="date"
          onChange={handleEndDate}
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        />
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
        {/* </div> */}
        {/* <div className="send__notification__btn"> */}
        <Button
          text="Send Notification"
          style={{
            cursor: "pointer",
            textAlign: "center",
            // display:"flex",
            // justifycontent:"center",
          }}
          onClick={() => {
            navigate(
              `${
                searchParams.get("appType")
                  ? "/sendnotification?appType=" + searchParams.get("appType")
                  : "/sendnotification"
              }`
            );
          }}
        />
      </div>

      <NotificationTable
        setArray={setArray}
        endDate={endDate}
        startDate={startDate}
      />
    </>
  );
};

export default Notification;
