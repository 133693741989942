import axios from "axios";
import { toast } from "react-toastify";
import { successToast } from "../utils/toast";

export const fetchDataFromAPI = (url, method, body, requestHeaders) => {
  let reqHeader = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("token"),
    sessionId:localStorage.getItem('sessionId'),
    ...requestHeaders,
  };

  switch (method) {
    case "GET": {
      return new Promise((resolve, reject) => {
        axios
          .get(url, {
            headers: reqHeader,
          })
          .then((res) => {
           
            resolve(res.data);
           
          })
          .catch((err) => {
            reject(err.response?.data);
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 2000);
            }
          });
      });
    }
    case "POST": {
      return new Promise((resolve, reject) => {
        axios
          .post(url, body, {
            headers: reqHeader,
          })
          .then((res) => {
            console.log(res,'res')
            resolve(res.data);
            // toast.success(res.data.message)
          })
          .catch((err) => {
            console.log("error on post connection ", err?.response?.status);
            if (err.response?.status === 401 || err.response?.status === 403) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 2000);
            }
            reject(err?.response?.data);
          });
      });
    }

    case "DELETE": {
      return new Promise((resolve, reject) => {
        axios
          .delete(url, { headers: reqHeader })
          .then((res) => {
            console.log(res,'res')
            successToast(res.data.message)
            resolve(res.data);
          })
          .catch((err) => {

            reject(err.response.data);
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 2000);
            }
          });
      });
    }

    case "PUT": {
      return new Promise((resolve, reject) => {
        axios
          .put(url, body, {
            headers: reqHeader,
          })
          .then((res) => {
            console.log(res,'res')
            successToast(res.data.message);
            resolve(res.data);
            // (res.data.message)
          })
          .catch((err) => {
            reject(err.response.data);
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 2000);
            }
          });
      });
    }
    default: {
      return new Promise((resolve, reject) => {
        axios
          .get(url, {
            headers: reqHeader,
          })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.response.data);
            if (
              err?.response?.status === 401 ||
              err?.response?.status === 403
            ) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 2000);
            }
          });
      });
    }
  }
};
