import { AiFillDelete } from "react-icons/ai";
import "./style.css";
import { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import AlertPopUp from "../../AlertPopUp";
import { useSearchParams } from "react-router-dom";
// import moment from "moment";
import { useLoader } from "../../../base/Context/loaderProvider";
import { FiSearch } from "react-icons/fi";
import SearchInput from "../../SearchInput";
import { useDebounce } from "use-debounce";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { useApi } from "../../../base/Context/apiProvider";
import Button from "../../library/Button";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import moment from "moment";
import jsPDF from "jspdf";

const WarnedHostTable = () => {
  const [warnedHostList, setWarnedHostList] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);

  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    warnedHostList.map((item, index) => {
      let obj = {
        "S.No": index + 1,
        "Host ID": item?._id,
        "Host Name": item?.hostId?.name,
        Title: item?.title,
        Description: item?.body,
        "Created At": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [warnedHostList]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Warned Host Report";
    const headers = [
      ["Host ID", "Host Name", "Title", "Description", "Created At"],
    ];
    console.log(exportAllData);
    const filename =`Warned Host Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    const data = exportAllData.map((item1) => [
      item1["Host ID"],
      item1["Host Name"],
      item1?.Title,
      item1?.Description,
      item1["Created At"],
      // item1["Reason"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const handleDeleteAlert = () => {
    setShowDeleteAlert(true);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  useEffect(() => {
    getWarnedHost();
  }, [value, perPage, page, apiProvider?.getApiUrl(), endDate]);

  const getWarnedHost = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.WARNEDHOST,
      "POST",
      searchParams.get("id")
        ? { hostId: searchParams.get("id") }
        : {
            startDate,
            endDate,
            key: value,
            page,
            perPage,
          }
    )
      .then((res) => {
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
        setWarnedHostList(res.result);
      })
      .catch((err) => {
        console.log(err);
        setWarnedHostList([]);
      });
  };

  const handleOnClickAlert = (id) => {
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleDelete = () => {
    loader.showLoader(true);

    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DELETEWARNING + `/${id}`,
      "DELETE"
    )
      .then((res) => {
        loader.showLoader(false);
        setShowDeleteAlert(false);
        getWarnedHost();
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err);
      });
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportExcel = () => {
    const filename =`Warned Host Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCsv = () => {
    const filename =`Warned Host Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;
    
    exportToCSV(exportAllData,filename);
  };

  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div
        className="user_request_row_export_buttons_row"
        style={{ display: "flex" }}
      >
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
          type="date"
          value={endDate}
          onChange={handleEndDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportExcel} />
        <Button text="Export Pdf" onClick={exportPDF} />
        <Button text="Export CSV" onClick={handleExportCsv} />
      </div>
      <div className="warned__host__container web_table_container">
        <table className="warned__host__table">
          <thead>
            <th className="warned__host__header">S.No.</th>
            {!searchParams.get("id") && (
              <>
                <th className="warned__host__header">Host ID</th>
                <th className="warned__host__header">Host Name</th>
                <th className="warned__host__header">Warning Count</th>
              </>
            )}
            <th className="warned__host__header">Title</th>
            <th className="warned__host__header">Description</th>
            <th className="warned__host__header">Created At</th>
            <th className="warned__host__header">Action</th>
          </thead>
          <tbody>
            {warnedHostList.length > 0
              ? warnedHostList.map((data, index) => {
                  return (
                    <tr>
                      <td className="warned__host__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      {!searchParams.get("id") && (
                        <>
                          <td className="warned__host__data">
                            {data?.hostId?.userId}
                          </td>
                          <td className="warned__host__data">
                            {data?.hostId?.name}
                          </td>
                          <td className="warned__host__data">
                            {data?.hostId?.worningCount}
                          </td>
                        </>
                      )}
                      <td className="warned__host__data">{data?.title}</td>
                      <td className="warned__host__data">{data?.body}</td>
                      <td className="warned__host__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY")}
                      </td>
                      <td className="warned__host__data warned__host__icon">
                        <AiFillDelete
                          onClick={() => {
                            handleOnClickAlert(data?._id);
                          }}
                          className="warned__host__delete__icon"
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        {!warnedHostList.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
      </div>

      {warnedHostList.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          perPage={perPage}
          setPerPage={setPerPage}
          options={[20, 25, 30, 35]}
        />
      ) : null}

      <AlertPopUp
        open={showDeleteAlert}
        handleOpen={handleDeleteAlert}
        handleClose={handleDeleteAlertClose}
        header="Delete Alert"
        description="Are you sure you want to delete this warning?"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleDelete}
        onCancelClick={handleDeleteAlertClose}
      />
    </>
  );
};

export default WarnedHostTable;
