import { useEffect, useState } from "react";
import AlertPopUp from "../../AlertPopUp";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import Button from "../../library/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import profile from "../../../base/Assets/dashboardProfile.png";
import { errorToast, successToast } from "../../../utils/toast";
// import { useParams } from "react-router-dom";

const HostManagementTable = ({ id }) => {
  const [showDeleteAlert, setShowDeleteAlert] = useState();
  const [getOneHost, setGetOneHost] = useState("");
  const [showBlockHost, setShowBlockHost] = useState(false);
  const [reason, setReason] = useState("");
  const [showReason, setShowReason] = useState(false);
  const loader = useLoader();
  const apiProvider = useApi();
  const [showUnblockAlert, setShowUnblockAlert] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showProfilePopUp, setShowProfilePopUp] = useState(false);
  const navigate = useNavigate();

  const handleHostUnblock = () => {
    setShowUnblockAlert(true);
    // setId(id);
  };

  const handleHostUnblockClose = () => {
    setShowUnblockAlert(false);
  };

  const handleDelete = () => {
    setShowDeleteAlert(true);
  };

  const handleDeleteClose = () => {
    setShowDeleteAlert(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteAlert(false);
  };

  const handleBlockedReason = () => {
    setShowReason(true);
    setShowBlockHost(false);
  };

  const handleBlockedReasonClose = () => {
    setShowReason(false);
  };

  useEffect(() => {
    fetchOneHost();
  }, []);

  const fetchOneHost = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETONEHOST + `/${id}`,
      "GET"
    )
      .then((res) => {
        loader.showLoader(false);
        setGetOneHost(res?.result);
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err);
      });
  };

  const handleBlockClick = () => {
    setShowBlockHost(true);
  };

  const handleBlockClickClose = () => {
    setShowBlockHost(false);
  };

  const handleBlockedHost = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.BLOCKHOST,
      "PUT",
      {
        id,
        blockReasion: reason,
      }
    )
      .then((res) => {
        setShowBlockHost(false);
        navigate(
          `${
            searchParams.get("appType")
              ? "/blockdata?appType=" + searchParams.get("appType")
              : "/blockdata"
          }`
        );
      })
      .catch((err) => {
        setShowBlockHost(false);
      });
  };

  const handleUnblockClick = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.UNBLOCKHOST,
      "PUT",
      {
        id,
      }
    )
      .then((res) => {
        setShowUnblockAlert(false);
        fetchOneHost();
      })
      .catch((err) => {
        setShowUnblockAlert(false);
      });
  };

  const handleProfileOpen = () => {
    setShowProfilePopUp(true);
  };

  const handleProfileOpenClose = () => {
    setShowProfilePopUp(false);
  };

  const handleProfilePic = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.REMOVEHOSTPROFILE,
      "PUT",
      {
        id: id,
      }
    )
      .then((res) => {
        setShowProfilePopUp(false);
        fetchOneHost();
        successToast(res?.message);
      })
      .catch((err) => {
        errorToast(err?.message);
      });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          height: "3rem",
          position: "absolute",
          top: 5,
          right: 0,
        }}
      >
        {getOneHost?.isBlock ? (
          <Button
            style={{
              backgroundColor: "#fe3b3b",
              width: "8rem",
              fontSize: "18px",
              textAlign: "center",
            }}
            text="Unblock Host"
            onClick={handleHostUnblock}
          />
        ) : (
          <Button
            style={{
              backgroundColor: "#fe3b3b",
              width: "8rem",
              fontSize: "18px",
              textAlign: "center",
            }}
            text="Block Host"
            onClick={handleBlockClick}
          />
        )}
      </div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
        }}
      > */}
      <div className="userdetails_main_container">
        <div className="user__management__profile">
          {/* {getOneHost?.profilePic ? (
            // <div className="user_management_icon" onClick={handleProfileOpen}>
            //   <AiFillEdit style={{ width: "18px", height: "18px" }} />
            // </div>
          ) : null} */}
          <div className="image_profile_container" onClick={handleProfileOpen}>
            <img
              className="user__management__profile_pic"
              src={getOneHost?.profilePic ? getOneHost?.profilePic : profile}
              alt=""
            />
          </div>
        </div>
        <div className="user__management__profile_name_container">
          <div className="user__management__profile__name">
            <div>
              <p>{getOneHost?.name && getOneHost?.name}</p>
              <p>user</p>
            </div>

            <p>Details</p>
          </div>
        </div>
        <div className="user__details__container">
          <div className="user__details__row">
            <div className="user__details__table">
              <div className="user__table__data">
                <div>Usernames :</div>
                <div className="host__management__data">{getOneHost?.name}</div>
              </div>
              <div className="user__table__data">
                <div className="host__management__header">Email ID :</div>
                <div className="host__management__data">
                  {getOneHost?.email}
                </div>
              </div>
              <div className="user__table__data">
                <div className="host__management__header">Contact :</div>
                <div className="host__management__data">
                  {getOneHost?.mobileNumber}
                </div>
              </div>
              <div className="user__table__data">
                <div className="host__management__header">PinCode :</div>
                <div className="host__management__data">
                  {getOneHost?.pinCode}
                </div>
              </div>
              <div className="user__table__data">
                <div className="host__management__header">State :</div>
                <div className="host__management__data">
                  {getOneHost?.state}
                </div>
              </div>
              <div className="user__table__data">
                <div className="host__management__header">Country :</div>
                <div className="host__management__data">
                  {getOneHost?.country}
                </div>
              </div>

              <div className="user__table__data">
                <div className="host__management__header">Profession :</div>
                <div className="host__management__data">
                  {getOneHost?.proffession}
                </div>
              </div>
              <div className="user__table__data">
                <div className="host__management__header">Bio :</div>
                <div className="host__management__data">
                  {getOneHost?.addBio}
                </div>
              </div>
          <div className="edit_suspend_button_container">
          <div className="edit_suspend_edit">Edit</div>
          <div className="edit_suspend_suspend">suspend</div>
        </div>
            </div>
          </div>
        </div>
        
      </div>
      <AlertPopUp
        open={showDeleteAlert}
        handleOpen={handleDelete}
        handleClose={handleDeleteClose}
        header="Delete Alert"
        description="Are you sure you want to delete this Host?"
        submitText="Yes"
        cancelText="No"
        onCancelClick={handleCancelDelete}
      />

      <AlertPopUp
        open={showBlockHost}
        handleOpen={handleBlockClick}
        handleClose={handleBlockClickClose}
        header="Block Alert"
        description="Are you sure you want to block this host?"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleBlockedReason}
        onCancelClick={handleBlockClickClose}
      />

      <AlertPopUp
        open={showReason}
        handleClose={handleBlockedReasonClose}
        onChangeField={(e) => setReason(e.target.value)}
        textField={true}
        header="Reason for blocking this profile"
        placeholder="Type your reason here..."
        submitText="Submit"
        cancelText="Cancel"
        onSubmitClick={handleBlockedHost}
        onCancelClick={handleBlockedReasonClose}
      />

      <AlertPopUp
        open={showUnblockAlert}
        handleClose={handleHostUnblockClose}
        handleOpen={handleHostUnblock}
        header="Unblock Alert"
        description="Are you sure you want to unblock this host?"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleUnblockClick}
        onCancelClick={handleHostUnblockClose}
      />

      <AlertPopUp
        open={showProfilePopUp}
        handleOpen={handleProfileOpen}
        handleClose={handleProfileOpenClose}
        header="Delete Alert"
        description="Are you sure you want to remove this profile pic?"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleProfilePic}
        onCancelClick={handleProfileOpenClose}
      />
      {/* </div> */}
    </>
  );
};

export default HostManagementTable;
