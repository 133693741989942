import { useEffect, useState } from "react";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { errorToast, successToast } from "../../../utils/toast";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import { useSearchParams } from "react-router-dom";

const AddGiftForm = ({ onSubmit, edit, onClickEdit, editedGift }) => {
  const [giftName, setGiftName] = useState("");
  const [giftPrice, setGiftPrice] = useState("");
  const [giftUploadImage, setGiftUploadImage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [error, setError] = useState({
    id: "",
    name: "",
    price: "",
    uploadImage: "",
  });

  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    if (edit && editedGift) {
      setGiftData(
        editedGift?.giftId,
        editedGift?.giftName,
        editedGift?.giftPrice,
        editedGift?.giftImage
      );
    }
  }, [edit]);

  const setGiftData = (giftId, giftName, giftPrice, giftImage) => {
    setId(giftId);
    setGiftName(giftName);
    setGiftPrice(giftPrice);
    setGiftUploadImage(giftImage);
  };

  const handleGiftForm = () => {
    let data = new FormData();
    data.append("name", giftName);
    data.append("price", giftPrice);
    data.append("image", giftUploadImage);
    if (validate()) {
      loader.showLoader(true);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.ADDGIFT,
        "POST",
        data,
        {
          "Content-Type": "multipart/form-data",
        }
      )
        .then((res) => {
          loader.showLoader(false);
          onSubmit();
        })
        .catch((err) => {
          errorToast(err);
          errorToast(err);
          loader.showLoader(false);
        });
    }
  };

  const handleEditForm = () => {
    if (validate1()) {
      loader.showLoader(true);
      const appType =
        searchParams.get("appType") === "catchwoo" ? "catchwoo" : "";
      let data = new FormData();
      data.append("name", giftName);
      data.append("price", giftPrice);
      data.append("image", giftUploadImage);
      loader.showLoader(true);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.UPDATEGIFT + `/${id}`,
        "PUT",
        data,
        {
          "Content-Type": "multipart/form-data",
          appType,
        }
      )
        .then((res) => {
          loader.showLoader(false);
          onClickEdit();
        })
        .catch((err) => {
          loader.showLoader(false);
          errorToast(err);
          errorToast(err);
        });
    }
  };

  const handleGiftName = (e) => {
    setError({ ...error, name: "" });
    setGiftName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ""));
  };

  const handlePrice = (e) => {
    setError({ ...error, price: "" });
    setGiftPrice(e.target.value.replace(/\D/g, ""));
  };

  const handleImage = (e) => {
    setError({ ...error, uploadImage: "" });
    setGiftUploadImage(e.target.files[0]);
  };

  const validate = () => {
    let result = true;
    if (!giftName || giftName.trim()?.length === 0 ||giftName.trim()?.length<=3) {
      setError({ ...error, name: "Enter valid Gift Name, Name should be atleast more than three characters" });
      result = false;
    } else if (!giftPrice) {
      setError({ ...error, price: "Enter valid Price" });
      result = false;
    } else if (!giftUploadImage) {
      setError({ ...error, uploadImage: "Upload Image" });
      result = false;
    }
    return result;
  };

  const validate1 = () => {
    let result = true;
    if (!giftName || giftName.trim().length === 0 || giftName?.length <= 3) {
      setError({ ...error, name: "Enter valid Gift Name" });
      result = false;
    } else if (!giftPrice) {
      setError({ ...error, price: "Enter valid Price" });
      result = false;
    } else if (!giftUploadImage) {
      setError({ ...error, uploadImage: "Upload Image" });
      result = false;
    }
    return result;
  };

  return (
    <div className="add__gift__container">
      {edit ? (
        <h2 className="add__gift__heading">Update Gift</h2>
      ) : (
        <h2 className="add__gift__heading">Add Gift</h2>
      )}
      <div className="add__gift__form">
        <InputField
          value={giftName}
          onChange={handleGiftName}
          placeholder="Gift Name"
          error={error.name}
          maxlength="25"
        />
        <br />
        <InputField
          type="text"
          maxlength="4"
          value={giftPrice}
          onChange={handlePrice}
          placeholder="Gift Price"
          error={error.price}
        />
        <br />
        <div className="add_gift_div">
          <InputField
            type="file"
            onChange={handleImage}
            placeholder="Upload Image"
            accept=".png, .jpg, .jpeg , .gif"
            error={error.uploadImage}
          />
          <p className="add_gift_para">NOTE: Image should be 1000x1000 px</p>
        </div>
        <br />

        <Button
          className="add__gift__form__btn"
          text={edit ? "Update" : "Save"}
          onClick={edit ? handleEditForm : handleGiftForm}
        />
      </div>
    </div>
  );
};

export default AddGiftForm;
