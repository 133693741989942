import { useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { errorToast, successToast } from "../../../utils/toast";
import { useApi } from "../../../base/Context/apiProvider";
import { useLoader } from "../../../base/Context/loaderProvider";

const InterestForm = ({
  onSubmit,
  id,
  fetchInterestData,
  name,
  setName,
  edit,
  onEdit,
}) => {
  //const [edit, setEdit] = useState("");
  const [interestName, setInterestName] = useState("");
  const [error, setError] = useState({
    name: "",
  });
  const apiProvider = useApi();
  const loader = useLoader();

  const handleSetBannerName = (e) => {
    setName(e.target.value.replace(/[^a-zA-Z0-9/s]/g, ""));
    setError({ ...error, name: "" });
  };

  const handleOnEdit = () => {
    if (validate1()) {
      loader.showLoader(true);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() +
          NetworkConfiguration.UPDATEINTEREST +
          `/${id}`,
        "PUT",
        {
          name: name,
        }
      )
        .then((res) => {
          loader.showLoader(false);
          successToast(res?.message);
          onEdit();
          console.log(res);
        })

        .catch((err) => {
          loader.showLoader(false);
          errorToast(err?.message);
          console.log(err);
        });
    }
  };

  const handleOnSubmit = () => {
    if (validate()) {
      loader.showLoader(true);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.ADDINTEREST,
        "POST",
        {
          name: interestName,
        }
      )
        .then((res) => {
          loader.showLoader(false);
          successToast(res?.message);
          console.log(res);
          onSubmit();
        })
        .catch((err) => {
          errorToast(err?.message);
          loader.showLoader(false);
          console.log(err);
        });
    }
  };

  const validate = () => {
    let result = true;
    if (!interestName || interestName.trim()?.length === 0) {
      setError({ ...error, name: "Enter valid Interest Name" });
      result = false;
    }
    return result;
  };

  const validate1 = () => {
    let result = true;
    if (!name || name.trim()?.length === 0) {
      setError({ ...error, name: "Enter valid Interest Name" });
      result = false;
    }
    return result;
  };

  const handleOnChange = (e) => {
    setError({ ...error, name: "" });
    // setInterestName(e.target.value.replace(/[^a-zA-Z0-9/s]/g, ""));
    setInterestName(e.target.value)
  };
  return (
    <div className="banner__container">
      {edit ? (
        <h2 className="banner__heading">Update Interest</h2>
      ) : (
        <h2 className="banner__heading">Add Interest</h2>
      )}
      <div className="banner__fields__gap">
        <InputField
          onChange={edit ? handleSetBannerName : handleOnChange}
          value={edit ? name : interestName}
          placeholder="Interest Name"
          maxlength="25"
          error={error.name}
        />
        <br /> <br />
        <Button
          style={{ cursor: "pointer" }}
          text={edit ? "Update" : "Submit"}
          onClick={edit ? handleOnEdit : handleOnSubmit}
          className="add__banner__button"
        />
      </div>
    </div>
  );
};

export default InterestForm;
