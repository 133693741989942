// import InputField from "../../library/InputField";
// import "./style.css";
// import Button from "../../library/Button";
// import { useEffect, useState } from "react";
// import { fetchDataFromAPI } from "../../../network/NetworkConnection";
// import {
//   API_URL,
//   NetworkConfiguration,
// } from "../../../network/NetworkConfiguration";
// import { useLoader } from "../../../base/Context/loaderProvider";
// import { useApi } from "../../../base/Context/apiProvider";
// import Dropdown from "../../library/Dropdown";

// const AddLeaderForm = ({ onSubmit, edit, data, setData, id }) => {
//   //const [a,b]=useState()
//   const [leaderName, setLeaderName] = useState("");
//   const [mobileNumber, setMobileNumber] = useState("");
//   const [email, setEmail] = useState("");
//   const [gender, setGender] = useState("");
//   const [groupName, setGroupName] = useState("");
//   const [pin, setPin] = useState("");
//   const [country, setCountry] = useState("");
//   const [state, setState] = useState("");
//   const [city, setCity] = useState("");
//   const [idProof, setIdProof] = useState("");
//   const [password, setPassword] = useState("");

//   const [error, setError] = useState({
//     leaderNameError: "",
//     mobileNumberError: "",
//     emailError: "",
//     genderError: "",
//     groupNameError: "",
//     pinCodeError: "",
//     countryError: "",
//     stateError: "",
//     cityError: "",
//     idProofError: "",
//     passwordError: "",
//   });
 

//   const loader = useLoader();
//   const apiProvider = useApi();

//   const handleAddLeader = () => {
//     if (validate()) {
//       loader.showLoader(true);
//       loader.showLoader(true);

//       fetchDataFromAPI(
//         apiProvider?.getApiUrl() + NetworkConfiguration.ADDLEADER,
//         "POST",
//         {
//           leaderName: leaderName,
//           mobileNumber: mobileNumber,
//           email: email,
//           groupName: groupName,
//           pin: pin,
//           country: country,
//           state: state,
//           gender: gender,
//           city: city,
//           idProof: idProof,
//           password: password,
//         }
//       )
//         .then((res) => {
//           loader.showLoader(false);
//           onSubmit();
//         })
//         .catch((err) => {
//           loader.showLoader(false);
//         });
//     }
//   };

//   const handleEditValue = (e) => {
//     const { name, value } = e.target;
//     setData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleLeaderName = (e) => {
//     setError({ ...error, leaderNameError: "" });
//     setLeaderName(e.target.value.replace(/[^a-zA-Z\s]/g, ""));
//   };

//   const handleMobileNumber = (e) => {
//     setError({ ...error, mobileNumberError: "" });
//     setMobileNumber(e.target.value.replace(/\D/g, ""));
//   };

//   const handleEmail = (e) => {
//     setError({ ...error, emailError: "" });
//     setEmail(e.target.value);
//   };

//   const handleGender = (e) => {
//     setError({ ...error, genderError: "" });
//     setGender(e.target.value);
//   };

//   const handleGroupName = (e) => {
//     setError({ ...error, groupNameError: "" });
//     setGroupName(e.target.value);
//   };

//   const handlePinCode = (e) => {
//     setError({ ...error, pinCodeError: "" });
//     setPin(e.target.value.replace(/\D/g, ""));
//     fetchDataFromAPI(
//       apiProvider?.getApiUrl() + NetworkConfiguration.PINCODE,
//       "POST",
//       {
//         zipCode: e.target.value,
//       }
//     )
//       .then((res) => {
//         // setPin(e.target.value);
//         setCountry(res.result.country);
//         setState(res.result.state);
//         setCity(res.result.city);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

//   const handleCountry = (e) => {
//     setError({ ...error, countryError: "" });
//     setCountry(e.target.value);
//   };

//   const handleState = (e) => {
//     setError({ ...error, stateError: "" });
//     setState(e.target.value);
//   };

//   const handleCity = (e) => {
//     setError({ ...error, cityError: "" });
//     setCity(e.target.value);
//   };

//   const handleIdProof = (e) => {
//     setError({ ...error, idProofError: "" });

//     setIdProof(e.target.value.replace(/\D/g, ""));
//   };

//   const handlePassword = (e) => {
//     setError({ ...error, passwordError: "" });
//     setPassword(e.target.value);
//   };

//   const dropdownOptions = [
//     {
//       name: "--Select--",
//     },
//     {
//       name: "Male",
//       value: "Male",
//     },
//     {
//       name: "Female",
//       value: "Female",
//     },
//   ];

//   const handleEditLeader = () => {
//     if (editvalidate()) {
//       loader.showLoader(true);
//       fetchDataFromAPI(
//         apiProvider?.getApiUrl() + NetworkConfiguration.EDITLEADER,
//         "PUT",
//         {
//           id,
//           ...data,
//         }
//       )
//         .then((res) => {
//           loader.showLoader(false);
//           onSubmit();
//         })
//         .catch((err) => {
//           loader.showLoader(false);
//         });
//     }
//   };

//   const validate = () => {
//     let result = true;
//     console.log(leaderName, "lll");
//     if (!leaderName || leaderName.trim()?.length === 0) {
//       setError({ ...error, leaderNameError: "Enter valid leader name" });
//       result = false;
//     } else if (!mobileNumber.match(/^[5-9]{1}[0-9]{9}/)) {
//       setError({ ...error, mobileNumberError: "Enter valid mobile number" });
//       result = false;
//     } else if (
//       !email.match(/^[a-zA-Z0-9_\-.]{3,}@[A-Za-z0-9]{2,}.[a-zA-Z]{2,5}$/)
//     ) {
//       setError({ ...error, emailError: "Enter valid email address" });
//       result = false;
//     } else if (!gender) {
//       setError({ ...error, genderError: "Enter valid gender" });
//       result = false;
//     } else if (!groupName) {
//       setError({ ...error, groupNameError: "Enter valid group name" });
//       result = false;
//     } else if (!pin.match(/^[0-9]{4,6}/)) {
//       setError({ ...error, pinCodeError: "Enter valid pin Code" });
//       result = false;
//     } else if (!country) {
//       setError({ ...error, countryError: "Enter valid Country" });
//       result = false;
//     } else if (!state) {
//       setError({ ...error, stateError: "Enter valid State" });
//       result = false;
//     } else if (!city) {
//       setError({ ...error, cityError: "Enter valid City" });
//       result = false;
//     } else if (!idProof.match(/^[0-9]{16}/)) {
//       setError({ ...error, idProofError: "Enter valid Id Proof" });
//       result = false;
//     } else if (!password) {
//       setError({ ...error, passwordError: "Enter Password" });
//       result = false;
//     }
//     return result;
//   };
//   const editvalidate = () => {
//     let result = true;
//     console.log(leaderName, "lll");
//     if (!data.leaderName || data.leaderName.trim()?.length === 0) {
//       setError({ ...error, leaderNameError: "Enter valid leader name" });
//       result = false;
//     } else if (!data.mobileNumber.match(/^[5-9]{1}[0-9]{9}/)) {
//       setError({ ...error, mobileNumberError: "Enter valid mobile number" });
//       result = false;
//     } else if (
//       !data.email.match(/^[a-zA-Z0-9_\-.]{3,}@[A-Za-z0-9]{2,}.[a-zA-Z]{2,5}$/)
//     ) {
//       setError({ ...error, emailError: "Enter valid email address" });
//       result = false;
//     } else if (!data.gender) {
//       setError({ ...error, genderError: "Enter valid gender" });
//       result = false;
//     } else if (!data.groupName) {
//       setError({ ...error, groupNameError: "Enter valid group name" });
//       result = false;
//     } else if (!data.pin.match(/^[0-9]{4,6}/)) {
//       setError({ ...error, pinCodeError: "Enter valid pin Code" });
//       result = false;
//     } else if (!data.country) {
//       setError({ ...error, countryError: "Enter valid Country" });
//       result = false;
//     } else if (!data.state) {
//       setError({ ...error, stateError: "Enter valid State" });
//       result = false;
//     } else if (!data.city) {
//       setError({ ...error, cityError: "Enter valid City" });
//       result = false;
//     } else if (!data.idProof.match(/^[0-9]{16}/)) {
//       setError({ ...error, idProofError: "Enter valid Id Proof" });
//       result = false;
//     } else if (!data.password) {
//       setError({ ...error, passwordError: "Enter Password" });
//       result = false;
//     }
//     return result;
//   };
//   return (
//     <div style={{ padding: "5px 0" }}>
//       <h2 className="add__leader__heading">Add Leader</h2>

//       <div className="add__leader__form">
//         <InputField
//           onChange={edit ? handleEditValue : handleLeaderName}
//           value={edit ? data.leaderName : leaderName}
//           error={error.leaderNameError}
//           placeholder="Leader Name"
//           maxlength="20"
//           name="leaderName"
//         />
//         <InputField
//           value={edit ? data.mobileNumber : mobileNumber}
//           onChange={edit ? handleEditValue : handleMobileNumber}
//           type="text"
//           placeholder="Mobile Number"
//           error={error.mobileNumberError}
//           //min={10}
//           maxlength="10"
//           name="mobileNumber"
//         />
//         <InputField
//           error={error.emailError}
//           value={edit ? data.email : email}
//           onChange={edit ? handleEditValue : handleEmail}
//           placeholder="Email"
//           type="email"
//           name="email"
//         />

//         <Dropdown
//           dropDownClass={"input__field__container"}
//           value={edit ? data.gender : gender}
//           options={dropdownOptions}
//           onChange={edit ? handleEditValue : handleGender}
//           placeholder="Gender"
//           error={error.genderError}
//           name="gender"
//         />
//         <InputField
//           value={edit ? data.groupName : groupName}
//           onChange={edit ? handleEditValue : handleGroupName}
//           placeholder="Group Name"
//           error={error.groupNameError}
//           name="groupName"
//           maxlength="20"
//         />
//         <InputField
//           value={edit ? data.pin : pin}
//           onChange={edit ? handleEditValue : handlePinCode}
//           type="text"
//           minlength="4"
//           maxlength="6"
//           placeholder="Pin Code"
//           error={error.pinCodeError}
//           name="pin"
//         />
//         <InputField
//           value={edit ? data.country : country}
//           onChange={edit ? handleEditValue : handleCountry}
//           placeholder="Country"
//           error={error.countryError}
//           name="country"
//         />
//         <InputField
//           value={edit ? data.state : state}
//           onChange={edit ? handleEditValue : handleState}
//           placeholder="State"
//           error={error.stateError}
//           name="state"
//         />
//         <InputField
//           value={edit ? data.city : city}
//           onChange={edit ? handleEditValue : handleCity}
//           placeholder="City"
//           error={error.cityError}
//           name="city"
//         />
//         <InputField
//           value={edit ? data.idProof : idProof}
//           onChange={edit ? handleEditValue : handleIdProof}
//           type="text"
//           placeholder="ID Proof (must be 16 digits)"
//           error={error.idProofError}
//           //onKeyUp={handleKeyPress}
//           maxlength="16"
//           // max="16"
//           name="idProof"
//         />

//         <InputField
//           value={edit ? data.password : password}
//           onChange={edit ? handleEditValue : handlePassword}
//           placeholder="Password"
//           error={error.passwordError}
//           name="password"
//         />
//       </div>

//       <Button
//         onClick={edit ? handleEditLeader : handleAddLeader}
//         className="add__leader__button"
//         text={edit ? "Update" : "Submit"}
//       />
//     </div>
//   );
// };

// export default AddLeaderForm;
import InputField from "../../library/InputField";
import "./style.css";
import Button from "../../library/Button";
import { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import Dropdown from "../../library/Dropdown";
import { FaEye, FaEyeSlash } from "react-icons/fa6";

const AddLeaderForm = ({ onSubmit, edit, data, setData, id }) => {
  const [leaderName, setLeaderName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [groupName, setGroupName] = useState("");
  const [pin, setPin] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [idProof, setIdProof] = useState("");
  const [password, setPassword] = useState("");
const[showPassword,setShowPassword]=useState(false);
  const [error, setError] = useState({
    leaderNameError: "",
    mobileNumberError: "",
    emailError: "",
    genderError: "",
    groupNameError: "",
    pinCodeError: "",
    countryError: "",
    stateError: "",
    cityError: "",
    idProofError: "",
    passwordError: "",
  });
  const handleEyeTwo = () => {
    if (!showPassword) {
      return <FaEyeSlash />;
    } else {
      return <FaEye />;
    }
  };
  const handleTypeTwo = () => {
    setShowPassword(!showPassword);
  };
  const loader = useLoader();
  const apiProvider = useApi();

  const handleAddLeader = () => {
    if (validate(leaderName, mobileNumber, email, gender, groupName, pin, country, state, city, idProof, password)) {
      loader.showLoader(true);

      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.ADDLEADER,
        "POST",
        {
          leaderName,
          mobileNumber,
          email,
          groupName,
          pin,
          country,
          state,
          gender,
          city,
          idProof,
          password,
        }
      )
        .then((res) => {
          loader.showLoader(false);
          onSubmit();
        })
        .catch((err) => {
          loader.showLoader(false);
        });
    }
  };

  const handleEditLeader = () => {
    console.log(data)
    if (validate(data.leaderName, data.mobileNumber, data.email, data.gender, data.groupName, data.pin, data.country, data.state, data.city, data.idProof, data.password)) {
      loader.showLoader(true);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.EDITLEADER,
        "PUT",
        {
          id,
          ...data,
        }
      )
        .then((res) => {
          loader.showLoader(false);
          onSubmit();
        })
        .catch((err) => {
          loader.showLoader(false);
        });
    }
  };

  const handleEditValue = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setError({ ...error, [`${name}Error`]: "" });
  };

  const handleInputChange = (e, setter) => {
    const { name, value } = e.target;
    setError({ ...error, [`${name}Error`]: "" });
    setter(value);
  };

  const handleLeaderName = (e) => handleInputChange(e, setLeaderName);
  const handleMobileNumber = (e) => handleInputChange(e, (value) => setMobileNumber(value.replace(/\D/g, "")));
  const handleEmail = (e) => handleInputChange(e, setEmail);
  const handleGender = (e) => handleInputChange(e, setGender);
  const handleGroupName = (e) => handleInputChange(e, setGroupName);
  const handlePinCode = (e) => {
    handleInputChange(e, (value) => setPin(value.replace(/\D/g, "")));
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.PINCODE,
      "POST",
      {
        zipCode: e.target.value,
      }
    )
      .then((res) => {
        setCountry(res.result.country);
        setState(res.result.state);
        setCity(res.result.city);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCountry = (e) => handleInputChange(e, setCountry);
  const handleState = (e) => handleInputChange(e, setState);
  const handleCity = (e) => handleInputChange(e, setCity);
  const handleIdProof = (e) => handleInputChange(e, (value) => setIdProof(value.replace(/\D/g, "")));
  const handlePassword = (e) => handleInputChange(e, setPassword);

  const dropdownOptions = [
    { name: "--Select--" },
    { name: "Male", value: "Male" },
    { name: "Female", value: "Female" },
  ];

  const validate = (leaderName, mobileNumber, email, gender, groupName, pin, country, state, city, idProof, password) => {
    let isValid = true;
    let newError = {};

    if (!leaderName || leaderName.trim().length === 0) {
      newError.leaderNameError = "Enter valid leader name";
      isValid = false;
    }
    if (!mobileNumber.match(/^[5-9]{1}[0-9]{9}/)) {
      newError.mobileNumberError = "Enter valid mobile number";
      isValid = false;
    }
    if (!email.match(/\S+@\S+\.\S+/)) {
      newError.emailError = "Enter valid email address";
      isValid = false;
    }
    if (!gender) {
      newError.genderError = "Enter valid gender";
      isValid = false;
    }
    if (!groupName) {
      newError.groupNameError = "Enter valid group name";
      isValid = false;
    }
    if (!String(pin)?.match(/^[0-9]{4,6}$/)) {
      newError.pinCodeError = "Enter valid pin Code";
      isValid = false;
    }
    if (!country) {
      newError.countryError = "Enter valid Country";
      isValid = false;
    }
    if (!state) {
      newError.stateError = "Enter valid State";
      isValid = false;
    }
    if (!city) {
      newError.cityError = "Enter valid City";
      isValid = false;
    }
    if (!idProof.match(/^[0-9]{16}/)) {
      newError.idProofError = "Enter valid Id Proof";
      isValid = false;
    }
    if (!password) {
      newError.passwordError = "Enter Password";
      isValid = false;
    }

    setError(newError);
    return isValid;
  };

  return (
        <div style={{ padding: "5px 0" }}>
          <h2 className="add__leader__heading">{edit?'Edit Leader' :'Add Leader'}</h2>
    
          <div className="add__leader__form">
            <InputField
              onChange={edit ? handleEditValue : handleLeaderName}
              value={edit ? data.leaderName : leaderName}
              error={error.leaderNameError}
              placeholder="Leader Name"
              maxlength="20"
              name="leaderName"
            />
            <InputField
              value={edit ? data.mobileNumber : mobileNumber}
              onChange={edit ? handleEditValue : handleMobileNumber}
              type="text"
              placeholder="Mobile Number"
              error={error.mobileNumberError}
              //min={10}
              maxlength="10"
              name="mobileNumber"
            />
            <InputField
              error={error.emailError}
              value={edit ? data.email : email}
              onChange={edit ? handleEditValue : handleEmail}
              placeholder="Email"
              type="email"
              name="email"
            />
    
            <Dropdown
              dropDownClass={"input__field__container"}
              value={edit ? data.gender : gender}
              options={dropdownOptions}
              onChange={edit ? handleEditValue : handleGender}
              placeholder="Gender"
              error={error.genderError}
              name="gender"
            />
            <InputField
              value={edit ? data.groupName : groupName}
              onChange={edit ? handleEditValue : handleGroupName}
              placeholder="Group Name"
              error={error.groupNameError}
              name="groupName"
              maxlength="20"
            />
            <InputField
              value={edit ? data?.pin : pin}
              onChange={edit ? handleEditValue : handlePinCode}
              type="text"
              minlength="4"
              maxlength="6"
              placeholder="Pin Code"
              error={error.pinCodeError}
              name="pin"
            />
            <InputField
              value={edit ? data.country : country}
              onChange={edit ? handleEditValue : handleCountry}
              placeholder="Country"
              error={error.countryError}
              name="country"
            />
            <InputField
              value={edit ? data.state : state}
              onChange={edit ? handleEditValue : handleState}
              placeholder="State"
              error={error.stateError}
              name="state"
            />
            <InputField
              value={edit ? data.city : city}
              onChange={edit ? handleEditValue : handleCity}
              placeholder="City"
              error={error.cityError}
              name="city"
            />
            <InputField
              value={edit ? data.idProof : idProof}
              onChange={edit ? handleEditValue : handleIdProof}
              type="text"
              placeholder="ID Proof (must be 16 digits)"
              error={error.idProofError}
              //onKeyUp={handleKeyPress}
              maxlength="16"
              // max="16"
              name="idProof"
            />
    
            <InputField
              value={edit ? data.password : password}
              onChange={edit ? handleEditValue : handlePassword}
              placeholder="Password"
              error={error.passwordError}
              name="password"
              type={showPassword?'text':'password'}
              icon={handleEyeTwo()}
              onEyeClick={handleTypeTwo}
            />
          </div>
    
          <Button
            onClick={edit ? handleEditLeader : handleAddLeader}
            className="add__leader__button"
            text={edit ? "Update" : "Submit"}
          />
        </div>
      );
    };

export default AddLeaderForm;