import Lottie from "react-lottie";
import SearchInput from "../../SearchInput";
import Button from "../../library/Button";
import "./style.css";
import Pagination from "../../Pagination";
import noData from "../../../base/Animation/No Data Found.json";
import jsPDF from "jspdf";
import moment from "moment";
import { useEffect, useState } from "react";
import { useApi } from "../../../base/Context/apiProvider";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useParams, useSearchParams } from "react-router-dom";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { FiSearch } from "react-icons/fi";
import {
  exportToCSV,
  exportToExcel,
} from "react-easy-export/dist/react-easy-export";

export const WarnedSingleHost = () => {
  const [warnedUserList, setWarnedUserList] = useState([]);
  //   const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  //   const [id, setId] = useState("");
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);

  //   const [key] = useDebounce(value, 1000);

  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    warnedUserList.map((item,index) => {
      let obj = {
"S.No":index+1,
        "User Name": item?.userId?.name,
        Title: item?.title,
        Description: item?.body,
        "Created At": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [warnedUserList]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Warned Users Report";
    const headers = [["User Name", "Title", "Description", "Created At"]];
    console.log(exportAllData);

    const data = exportAllData.map((item1) => [
      item1["User Name"],
      item1?.Title,
      item1?.Description,
      item1["Created At"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Warned single Host Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  useEffect(() => {
    fetchSingleUser();
  }, [perPage, page, apiProvider?.getApiUrl()]);

  const fetchSingleUser = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.SINGLEWARNUSER,
      "POST",
      {
        page,
        perPage,
        id: id,
        key:value,
      }
    )
      .then((res) => {
        setWarnedUserList(res?.result);
        loader.showLoader(false);
        console.log(res);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err);
      });
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportExcel = () => {
    const filename =`Warned single Host Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCsv = () => {
    const filename =`Warned single Host Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="warned_user_btn">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          max={new Date().toISOString().split("T")[0]}
          onChange={handleStartDate}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
          type="date"
          value={endDate}
          onChange={handleEndDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportExcel} />
        <Button text="Export Pdf" onClick={exportPDF} />
        <Button text="Export CSV" onClick={handleExportCsv} />
      </div>
      <div className="warned__user__container web_table_container">
        <table className="warned__user__table">
          <thead>
            <th className="warned__user__header">S.No.</th>
            {/* {!searchParams.get("id") && (
                <>
                  <th className="warned__user__header">User ID</th>
                  <th className="warned__user__header">User Name</th>
                </>
              )} */}
            <th className="warned__user__header">Title</th>
            <th className="warned__user__header">Description</th>
            <th className="warned__user__header">Created At</th>
            {/* <th className="warned__user__header">Action</th> */}
          </thead>
          <tbody>
            {warnedUserList.length > 0
              ? warnedUserList.map((data, index) => {
                  return (
                    <tr>
                      <td className="warned__user__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      {/* {!searchParams.get("id") && (
                          <>
                            <td className="warned__user__data">
                              {data?.userId?.userId}
                            </td>
                            <td className="warned__user__data">
                              {data?.userId?.name}
                            </td>
                          </>
                        )} */}
                      <td className="warned__user__data">{data?.title}</td>
                      <td className="warned__user__data">{data?.body}</td>
                      <td className="warned__user__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY")}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        {!warnedUserList?.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : ""}
      </div>

      {warnedUserList.length > 0 ? (
        <Pagination
          page={page}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}

      {/* <AlertPopUp
          open={showDeleteAlert}
          handleOpen={handleDeleteAlert}
          handleClose={handleDeleteAlertClose}
          header="Delete Alert"
          description="Are you sure you want to delete this warning?"
          submitText="Yes"
          cancelText="No"
          onSubmitClick={handleDelete}
          onCancelClick={handleDeleteAlertClose}
        /> */}
    </>
  );
};
