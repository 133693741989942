import { FiSearch } from "react-icons/fi";
import NotificationCard from "./NotificationCard";
import "./style.css";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import { useEffect, useState } from "react";
import { useApi } from "../../base/Context/apiProvider";
import profile from "../../base/Assets/dashboardProfile.png";
import { useNavigate, useSearchParams } from "react-router-dom";

const Notification = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const apiProvider = useApi();
  const navigate = useNavigate();

  const notificationSearchIcon = () => {
    return <FiSearch />;
  };

  const navigateTo = (type) => {
    if (type === "reports") {
      navigate(
        `${
          searchParams.get("appType")
            ? "/report&spam?appType=" + searchParams.get("appType")
            : "/report&spam"
        }`
      );
    } else {
      navigate(
        `${
          searchParams.get("appType")
            ? "/feedback?appType=" + searchParams.get("appType")
            : "/feedback"
        }`
      );
    }
  };

  useEffect(() => {
    fetchNotificationData();
  }, [apiProvider?.getApiUrl(), searchParams]);

  const fetchNotificationData = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DASHBOARDNOTIFICATION,
      "POST",
      {
        key: searchParams.get("appType") === "host" ? "hosts" : "users",
      }
    )
      .then((res) => {
        setNotificationData(res?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="notification_content_box">
      <h2 className="notification__heading">Recent Notifications</h2>
      {/* <div className="notification__search_icon_bar">
        <i>{notificationSearchIcon()}</i>
        <input
          className="notification__search"
          placeholder="search connections"
        ></input>
      </div> */}
      <div style={{width:'100%',height:'85%',overflow:'auto',marginTop:'5px'}}>

      <div style={{width:'100%',height:'85%',overflow:'auto',marginTop:'5px'}}>

      {notificationData &&
        notificationData?.map((data) => {
          return (
            <div>
              <NotificationCard
                onClick={() => {
                  navigateTo(data?.notificationType);
                }}
                profile={
                  searchParams.get("appType") === "catchwoo"
                    ? data?.userId?.profilePic
                      ? data?.userId?.profilePic
                      : profile
                    : data?.hostId?.profilePic
                    ? data?.hostId?.profilePic
                    : profile
                }
                name={data?.title}
                message={data?.body}
                feedback={data?.notificationType}
                // statusType={data?.statusType}
              />
            </div>
          );
        })}
        {notificationData && ! notificationData?.length&& 'No notifications to show.'}
      </div>
    </div>
    </div>
  );
};

export default Notification;
