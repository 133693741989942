export const API_URL = process.env.REACT_APP_BASE_URL;
export const SOCKET_URL=process.env.REACT_APP_SOCKET_URL
export const CATCHWOO_URL = process.env.REACT_APP_CATCHWOO_URL;

export const NetworkConfiguration = {
  ACTIVATEUSER:'admin/activeSuspended',
  SOCKETUPDATE:'admin/admin',
  DELETEAPK:'admin/apkDeleted',
  EDITAPK:'admin/updateApkField',
  SENDMAILTOFEWHOST:"admin/sendMailTwoHost",
  SENDMAILTOFEWUSERS:'admin/sendMailTwoUser  ',
  ADDBANNERTYPE: "banner/addBannerType",
  ACTIVATESUSPENDEDHOST:'admin/activeSuspendedHost',
  GETBANNERTYPE: "banner/getBannerType",
  DEVICETOKEN: "admin/updateDeviceToken",
  ADMINLOGIN: "admin/adminlogin",
  TOTALHOST: "admin/getMontlyHost",
  TOTALUSER: "admin/getMontlyUser",
  USERPURCHASE: "admin/adminFindUserEarning",
  ONLINEUSER: "admin/adminOnlineUser",
  OFFLINEHOST: "admin/findOfflineHost",
  ONLINEHOST: "admin/findOnlineHost",
  OFFLINEUSER: "admin/adminOfflineUser",
  GETINACTIVEUSER: "admin/inActiveProfileUser",
  GETINACTIVEHOST: "admin/inActiveProfileHost",
  DASHBOARDNOTIFICATION: "admin/dashboardNotification",
  FORGOTPASSWORD: "admin/adminForgetPassword",
  SENDTOALL: "admin/sendMultiNotification",
  SENDTOFEW: "admin/sendMultiNotiToMore",
  RESETPASSWORD: "admin/adminResetPasword",
  PINCODE: "admin/searchByPinCode",
  ENTEROTP: "admin/verifyOtp",
  FINDONEUSER: "admin/findOneUser",
  DELETELEADER: "admin/adminDeletedLeader",
  EDITLEADER: "admin/updateLeaderData",
  USERREPORT: "admin/userReportsList",
  HOSTEARNING: "admin/adminGetHostEarning",
  DELETEBUCKET: "admin/deleteCoinsData",
  HOSTREPORT: "admin/hostReportsList",
  HOSTBLOCKDETAILS: "admin/hostBlockListDetails",
  HOSTFOLLOWER: "admin/hostFollowerDetails",
  REJECTHOST: "admin/rejectHostRequest",
  HOSTFOLLOWING: "admin/hostFollowingsList",
  HOSTCALLHISTORY: "admin/hostCallHistory",
  GETINTEREST: "interest/getAllInterest",
  POSTSUSPENDEDUSER: "admin/adminSuspendUser",
  POSTSUSPENDEDHOST: "admin/adminSuspendHost",
  SUSPENDEDUSER: "admin/getListUserSuspended",
  SUSPENDEDHOST: "admin/getListHostSuspended",
  SUSPENDHOST: "admin/adminSuspendHost",
  WARNEDHOST: "admin/getWaringHostList",
  WARNEDUSER: "admin/getWaringUserList",
  WARNHOSTNOTIFICATION: "admin/sendWorningNotificationHost",
  GETHOSTNOTIFICATION: "admin/getNotificationHost",
  GETBANNER: "banner/getAllBanner",
  GETONEGIFT: "gift/getOneGift",
  GETONESTICKER: "sticker/adminGetOneSticker",
  GETSTICKERUSER: "admin/adminGetUserSendSticker",
  GETONEHOST: "admin/getOneHostProfile",
  GETUSERGIFT: "admin/adminGetUserSendGift",
  GETAUDIOCALLTIME: "admin/getSetAudioCallTime",
  PENDINGHOST: "admin/getHostPending",
  GETHELPLINENUMBER: "helpline/findAllhelplinenumber",
  ACCEPTEDHOST: "admin/getAllAcceptHost",
  REQUESTEDHOST: "admin/acceptHostRequest",
  GETLEADER: "admin/getAllLeader",
  GETONEBANNER: "banner/getOneBanner",
  GETAMOUNT: "admin/getBucketUserCoins",
  GETONEUSER: "admin/findOneUser",
  BLOCKUSER: "admin/adminBlockUser",
  GETONECOIN: "wallet/findOnewallet",
  GETHOSTGIFT: "admin/adminGetHostSendGift",
  GETBUCKETLIST: "admin/findIncDecCoins",
  GETPAYMENTHISTORY: "admin/userPaymentDetails",
  TOTALHOSTEARNING: "admin/adminFindHostEarning",
  CHANGEPASSWORD: "admin/adminSetPassword",
  GETUSERFOLLOWING: "admin/userFollowingsList",
  GETPREMIUMCOINS: "admin/adminGetSetCoins",
  GETUSERFOLLOWER: "admin/userFollowerDetails",
  GETTOPTALENT: "admin/findTopTalentUser",
  REJECTEDHOST: "admin/getHostRejected",
  RANDOMCALLCHARGE: "admin/adminGetRandomCoins",
  GETHOSTFEEDBACK: "admin/hostFeedbackList",
  GETUSERFEEDBACK: "admin/userFeedbackList",
  GETUSERBLOCKLIST: "admin/userBlockListDetails",
  WEEKLYSTAR: "admin/findTopWeeklyStarHost",
  GETLEADERHOSTS: "admin/getLeaderHostlist",
  GETUSERCALLHISTORY: "admin/userCallHistory",
  COINSDEDUCTION: "admin/coinDeductionUser",
  USERWARNINGNOTIFICATION: "admin/sendWorningNotification",
  GETUSERS: "admin/getUsersPending",
  GETUSERNOTIFICATION: "admin/getNotificationUser",
  GETHOSTAUDIOCHARGE: "admin/getHostAudioFee",
  GETVIDEOCALLBALANCE: "admin/getHostFeesBalance",
  GETGIFT: "gift/getAllGift",
  SHAREHISTORY:'admin/count-gift',
  GETWALLET: "wallet/getWallet",
  GETNOTIFICATION: "admin/adminGetAllNotification",
  GETSTICKER: "sticker/adminFindAllSticker",
  ADDGIFT: "gift/addGiftPicture",
  SENDREPLYHOST: "admin/updateFeedbackStatusHost",
  SENDREPLYUSER: "admin/updateFeedbackStatus",
  EDITHOST: "admin/adminEditHost",
  GETUSERMOMENT: "admin/adminFindUserMoment",
  HOSTMOMENT: "admin/adminFindHostMoment",
  DELETEUSER: "admin/adminDeletedUser",
  DELETEUSERMOMENT: "admin/adminDeletedUserMoment",
  DELETECOIN: "wallet/walletDeleted",
  DELETEGIFT: "gift/giftDelete",
  DELETEBANNER: "banner/bannerDelete",
  DELETEINTEREST: "interest/interestDeleted",
  DELETEWARNING: "admin/adminDeleteWarning",
  DELETESTICKER: "sticker/adminStickerDelete",
  DELETESUSPENSION: "admin/adminDeleteSuspended",
  DELETENOTIFICATION: "admin/adminDeleteNotification",
  DELETEHOSTMOMENT: "admin/adminDeletedHostMoment",
  DELETEHOST: "admin/adminDeletedHost",
  UPDATELEADER: "admin/addleaderId",
  UPDATEUSER: "admin/adminUpdateUser",
  UPDATEHOSTCHARGE: "admin/adminUpdateHostBalance",
  UPDATEAUDIOCALLCHARGE: "admin/addAudioCallFees",
  UPDATEPREMIUMCOINS: "admin/AdminSetPostCoins",
  UPDATERANDOMCOINS: "admin/randomCallCoins",
  UPDATENORMALCALLTIME: "admin/adminSetNormalCallTime",
  UPDATERANDOMCALLTIME: "admin/adminSetRandomCallTime",
  GETRANDOMCALLTIME: "admin/adminGetRandomPerSecCoins",
  GETNORMALCALLTIME: "admin/adminGetSetPerSecCoins",
  ADDWALLET: "wallet/addWallet",
  UPDATEWALLET: "wallet/updateWallet",
  ADDHELPLINE: "helpline/addhelplinenumber",
  ADDINTEREST: "interest/addInterest",
  UPDATEBANNERNAME: "banner/updateBannerField",
  UPDATEHOSTVIDEOFEES: "admin/adminAddHostuserfress",
  UPDATEHELPLINE: "helpline/updateNumber",
  UPDATEAUDIOCALLTIME: "admin/setAudioCallTime",
  UPDATESTICKER: "sticker/adminUpdateStickerData",
  UPDATEINTEREST: "interest/updateInterest",
  UPDATEGIFT: "gift/updateGiftdata",
  ADDLEADER: "admin/adminAddleader",
  ADDBANNER: "banner/addBannerPicture",
  ADDSTICKER: "sticker/addStickerPicture",
  WEEKLYTALENT: "admin/findWeeklyTalentUser",
  TOPSTAR: "admin/findTopStarHost",
  NEWSTAR: "admin/findNewStarHost",
  SUSPICIOUSDATA: "admin/suspicious",
  GETFLOWER: "gift/getRoseDetails",
  BLOCKLIST: "admin/adminBlocklist",
  BLOCKHOST: "admin/adminBlockHost",
  UNBLOCKHOST: "admin/adminHostUnblock",
  UNBLOCKUSER: "admin/adminUserUnblock",
  SNAPSHOTS: "admin/snapshots",
  UPDATEFLOWER: "gift/updateRosePic",
  ALLNOTIFICATIONS: "admin/adminGetLeaderRequest",
  CLEARALLNOTIFICATIONS: "admin/adminUpdateNotifications",
  CLEARSINGLENOTIFICATION: "admin/singleUpdateNotification",
  EDITSUSPENSION: "admin/adminSuspendUser",
  REMOVESUSPENSION: "admin/removeSuspended",
  REMOVESUSPENDEDHOST: "admin/removeSuspendedHost",
  GETCONTACTFORMLIST: "admin/getListContact",
  DELETECONTACTMESSAGE: "admin/deleteContact",
  GETFORMDATA: "admin/listNewRequest",
  DELETEFORMDATA: "admin/deleteRequest/",
  ADDAPKFILE: "admin/addApkApi",
  DELETESNAPSHOT: "admin/deleteSnapShot",
  DELETEBLOCKEDUSER: "admin/deleteAdminBlockList",
  GETCOINHISTORY: "admin/getAllUsersSpendCoins1",
  GETCOINHISTORYHOST: "admin/getAllUsersSpendCoins",
  USERRECHARGE: "admin/getTodayRecharge",
  USERCOINBUYED: "admin/getTodayCoins",
  DELETEDACCOUNT: "admin/deletedProfile",
  DELETEDHOSTACCOUNT: "admin/deletedProfileHost",

  PERDAYEARNING: "admin/getHostBalanceToday",
  APKLIST: "admin/getApkList",
  GETHOSTSTICKER: "admin/adminGetHostSendSticker",
  REMOVEUSERPROFILE: "admin/removePicUser",
  REMOVEHOSTPROFILE: "admin/removePicHost",
  POSTDETAILS: "admin/getUserReports",
  DELETEDMOMENTS: "admin/listDeletedUsermoment",
  CATCHWOOEARNING: "admin/adminFindCatchwooEarning",
  GETLIKEPOST: "admin/getByIdUserMoment",
  GETLIKEHOSTPOST: "admin/getByIdHostMoment",
  SINGLESUSPENSION: "admin/userSuspendedList",
  SINGLESUSPENSIONHOST: "admin/hostSuspendedList",
  SINGLEWARNHOST: "admin/hostWarningList",
  SINGLEUSERWARNUSER: "admin/userWorningList",
  SINGLEWARNUSER: "admin/hostWarningList",

  //subAdmin
  SUBADMINLIST: "admin/subAdminList",
  ADDSUBADMIN: "admin/adminAddSubAdmin",
  GETONESUBADMIN: "admin/subAdminDetails",
  EDITSUBADMIN: "admin/editSubAdmin",
  DELETESUBADMIN: "admin/adminDeleteSubadmin",
  EDITPASSWORD: "admin/changePassword",

  //allcallhistory
  DROPPEDCALL: "admin/getAllCallTypeUser",
  HOSTCALL: "admin/getAllCallTypeHost",

  //NOTIFICATION(ACTIVE/INACTIVE)
  INACTIVEHOST: "admin/adminInActiveHostNotification",
  INACTIVEUSER: "admin/adminInActiveUserNotification",
  SENDMAILTOALL: "admin/sendMailToAll",
  SENDMAILTOFEW: "admin/sendBulkEMail",
  BULKMAILLIST: "admin/bulkMailListing",
  GETNOTIFICATIONBYID: "admin/getAdminNotificationById",
  GETMAILBYID: "admin/getMailById",
};
