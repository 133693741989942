import "./style.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserManagementData } from "../../../../utils/UserManagementData";

const IconContainer = ({ id }) => {
  let navigate = useNavigate();
  const [hovereditem, setHoveredItem] = useState(null);
  const handleHoveredItem = (id) => {
    setHoveredItem(id);
  };
  let params = useParams();

  return (
    // <div className="user__management__icon__container">
    <div className="user__management__icon__row">
      {UserManagementData.map((data) => {
        return (
          <div
            onMouseEnter={() => handleHoveredItem(data?.name)}
            onMouseLeave={()=>setHoveredItem(null)}
            className="icon__container"
            onClick={() => {
              navigate(
                `/usermanagement/${data?.name}/${id}${
                  sessionStorage.getItem("selectedType") === "catchwoo"
                    ? "?appType=catchwoo"
                    : ""
                }`
              );
            }}
          >
            <div>
              <img
                className="icon"
                src={data?.name === hovereditem ? data.activeIcon : data?.icon}
                alt=""
              />
              <p className="icon__name" style={{color:data?.name === hovereditem ?'white':''}}>{data?.name}</p>
            </div>
          </div>
        );
      })}
    </div>
    // </div>
  );
};

export default IconContainer;
