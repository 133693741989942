import { useContext, useState } from "react";
import "./style.css";
import TablePopUp from "../../TablePopUp";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import AlertPopUp from "../../AlertPopUp";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import FormAlertPopUp from "../../FormAlertPopUp";
import AddLeaderForm from "../../formComponents/AddLeaderform/index";
// import noData from "../../../base/Animation/No Data Found.json";
import { useApi } from "../../../base/Context/apiProvider";
import { Modal } from "../../../base/Context/modalProvider";
import moment from "moment";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { toast } from "react-toastify";

const LeaderTable = ({ showLeaderList, page, perPage, getAllLeaders }) => {
  const [showHostData, setShowHostData] = useState(false);
  const [id, setId] = useState("");
  const[fileNameData,setFileNameData]=useState({})
  const [data, setData] = useState();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showEditAlert, setShowEditAlert] = useState(false);
  const apiProvider = useApi();
  const modalProvider = useContext(Modal);

  const handleEditAlert = (id, data) => {
    setShowEditAlert(true);
    setId(id);
    setData(data);
  };

  const handleEditAlertClose = () => {
    setShowEditAlert(false);
  };

  const handleDeleteAlert = (id) => {
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const handleViewHostData = (id,name,userId) => {
    setShowHostData(true);
    setFileNameData((prev)=>({...prev,name:name,id:userId}))
    setId(id);
  };

  const handleViewHostDataClose = () => {
    setShowHostData(false);
  };

  const handleDelete = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DELETELEADER + `/${id}`,
      "DELETE"
    )
      .then((res) => {
console.log(res)
        setShowDeleteAlert(false);
        getAllLeaders();
        toast.success(res?.message)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = () => {
    setShowEditAlert(false);
    getAllLeaders();
  };

  return (
    <>
      <div className="leader__table__container web_table_container">
        <div className="table_parent_box">
          <table className="leader__table">
            <thead>
              <th className="leader__table__header">S.No.</th>
              <th className="leader__table__header">Leader ID</th>
              <th className="leader__table__header">Name</th>
              <th className="leader__table__header">Gender</th>
              <th className="leader__table__header">Email</th>
              <th className="leader__table__header">Mobile Number</th>
              <th className="leader__table__header">ID Proof</th>
              <th className="leader__table__header">Group Name</th>
              <th className="leader__table__header">Country</th>
              <th className="leader__table__header">State</th>
              <th className="leader__table__header">City</th>
              <th className="leader__table__header">Pin Code</th>
              <th className="leader__table__header">Host</th>
              <th className="leader__table__header">Created At</th>
              <th className="leader__table__header">Action</th>
            </thead>
            <tbody>
              {showLeaderList && showLeaderList?.length > 0
                ? showLeaderList.map((data, index) => {
                    return (
                      <tr>
                        <td className="leader__table__body">
                          {(page - 1) * perPage + index + 1}
                        </td>
                        <td className="leader__table__body">
                          {data?.leaderId}
                        </td>
                        <td className="leader__table__body">
                          <div
                            className="feedback__table__comment"
                            onClick={
                              data?.leaderName?.length > 10
                                ? () =>
                                    modalProvider.handleCommentClick(
                                      data?.leaderName,
                                      "Name"
                                    )
                                : () => {}
                            }
                          >
                            {data?.leaderName}
                          </div>
                        </td>
                        <td className="leader__table__body">{data?.gender}</td>
                        <td className="leader__table__body">{data?.email}</td>
                        <td className="leader__table__body">
                          {data?.mobileNumber}
                        </td>
                        <td className="leader__table__body">{data?.idProof}</td>
                        <td className="leader__table__body">
                          {data?.groupName}
                        </td>
                        <td className="leader__table__body">{data?.country}</td>
                        <td className="leader__table__body">{data?.state}</td>
                        <td className="leader__table__body">{data?.city}</td>
                        <td className="leader__table__body">{data?.pin}</td>
                        {data?._id ? (
                          <td
                            onClick={() => {
                              handleViewHostData(data?._id,data?.leaderName,data?.leaderId);
                            }}
                            className="leader__table__body leader__table__view"
                          >
                            View
                          </td>
                        ) : (
                          <td className="leader__table__body">No Data Found</td>
                        )}
                        <td className="leader__table__body">
                          {moment(data?.createdAt).format("DD/MM/YYYY")}
                        </td>

                        <td className="leader__table__body">
                          <div style={{ display: "flex" ,alignItems:'center',justifyContent:'center' }}>
                            <AiFillEdit
                              onClick={() => handleEditAlert(data?._id, data)}
                              className="leader__edit__icon"
                            />
                            <AiFillDelete
                              onClick={() => handleDeleteAlert(data?._id)}
                              className="leader__delete__icon"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
          {!showLeaderList?.length ? (
            <div className="host__no__data__found__icon">
              <Lottie
                options={{ animationData: noData, loop: true }}
                style={{ width: "40%", height: "30%" }}
              />
              <p className="no__data__found">No Data Found</p>
            </div>
          ) : null}
        </div>

        <FormAlertPopUp
          open={showEditAlert}
          handleOpen={handleEditAlert}
          onRequestClose={handleEditAlertClose}
        >
          <AddLeaderForm
            onSubmit={onSubmit}
            edit={true}
            data={data}
            setData={setData}
            id={id}
          />
        </FormAlertPopUp>

        <AlertPopUp
          open={showDeleteAlert}
          handleOpen={handleDeleteAlert}
          handleClose={handleDeleteAlertClose}
          header="Delete Alert"
          description="Are you sure you want to delete this leader?"
          submitText="Yes"
          cancelText="No"
          onSubmitClick={handleDelete}
          onCancelClick={handleDeleteAlertClose}
        />
        {id ? (
          <TablePopUp
            open={showHostData}
            handleClose={handleViewHostDataClose}
            id={id}
            fileNameData={fileNameData}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default LeaderTable;
