import React from "react";
import SubAdminTable from "../../../components/Table/SubAdminTable";
import "./style.css";



export default function SubAdminList() {
 

  
  return (
    <>
      <div className="sub_admin_header_btns">

      </div>
      <>       
            <SubAdminTable /> 
        

        </>
      </>
    
  );
}
