import { AiFillEdit, AiFillEye } from "react-icons/ai";
import "./style.css";
import { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../network/NetworkConfiguration";
import moment from "moment";
import ImagePopUpModal from "../../components/ImagePopUpModal";
import FormAlertPopUp from "../../components/FormAlertPopUp";
import noData from "../../base/Animation/No Data Found.json";
import FlowerForm from "../../components/formComponents/FlowerForm";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";

const Flower = () => {
  const [flowerData, setFlowerData] = useState();
  const [img, setImg] = useState("");
  const [showFlowerImage, setShowFlowerImage] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [id, setId] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(1);
  const [totalPages, setTotalPages] = useState("");
const[count,setCount]=useState('')
  useEffect(() => {
    fetchFlowerData();
  }, []);

  const fetchFlowerData = () => {
    fetchDataFromAPI(API_URL + NetworkConfiguration.GETFLOWER, "GET")
      .then((res) => {
        setFlowerData(res?.result);
        setCount(res?.count)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFlower = (id) => {
    setShowEdit(true);
    setId(id);
  };

  const handleFlowerClose = () => {
    setShowEdit(false);
  };

  const handleFlowerImage = (img) => {
    setImg(img);
    setShowFlowerImage(true);
  };

  const handleFlowerImageClose = () => {
    setShowFlowerImage(false);
  };

  const onSubmit = () => {
    setShowEdit(false);
    fetchFlowerData();
  };
const navigate=useNavigate()
  return (
    <div className="flower__container">
      <table className="flower__table">
        <thead>
          <th className="flower__header">Flower Name</th>
          <th className="flower__header">Flower Price</th>
          <th className="flower__header">No.of Shares</th>

          <th className="flower__header">Flower Image</th>
          <th className="flower__header">Created At</th>
          <th className="flower__header">Updated At</th>
          <th className="flower__header">Action</th>
        </thead>
        {flowerData && (
          <tbody>
            <td className="flower__data">{flowerData?.name}</td>
            <td className="flower__data">{flowerData?.price}</td>
            <td className="flower__data" onClick={()=>navigate(`/shareHistory/${flowerData._id}`)}>{count}</td>

            <td className="flower__data">
              {flowerData && (
                <AiFillEye
                  className="flower__eye__icon"
                  onClick={() => handleFlowerImage(flowerData?.giftUrl)}
                />
              )}
            </td>
            <td className="flower__data">
              {flowerData?.createdAt &&
                moment(flowerData?.createdAt).format("DD/MM/YYYY , LT")}
            </td>
            <td className="flower__data">
              {flowerData?.createdAt &&
                moment(flowerData?.updatedAt).format("DD/MM/YYYY , LT")}
            </td>

            <td className="flower__data">
              {flowerData && (
                <AiFillEdit
                  className="flower__edit__icon"
                  onClick={() => handleFlower(flowerData?._id)}
                />
              )}
            </td>
          </tbody>
        )}
      </table>
        {!flowerData ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}
      <ImagePopUpModal
        open={showFlowerImage}
        handleClose={handleFlowerImageClose}
        img={img}
      />

      <FormAlertPopUp
        open={showEdit}
        handleOpen={handleFlower}
        onRequestClose={handleFlowerClose}
      >
        <FlowerForm id={id} onSubmit={onSubmit} flowerData={flowerData} />
      </FormAlertPopUp>
      <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
          perPage={perPage}
          setPerPage={setPerPage}
        />
    </div>
  );
};

export default Flower;
