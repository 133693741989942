import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Button from "../../components/library/Button";
import BannerTable from "../../Constant/BannerTable";
import FormAlertPopUp from "../../components/FormAlertPopUp";
import "./style.css";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../network/NetworkConfiguration";
import BannerForm from "../../components/formComponents/BannerForm";
import AlertPopUp from "../../components/AlertPopUp";
import { errorToast, successToast } from "../../utils/toast";
import SearchInput from "../../components/SearchInput";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../components/Pagination";
import { useLoader } from "../../base/Context/loaderProvider";
import { useApi } from "../../base/Context/apiProvider";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import moment from "moment";
import jsPDF from "jspdf";
import AddCatogery from "../../components/formComponents/AddCatogery";

const Banner = () => {
  const [showBannerForm, setShowBannerForm] = useState(false);
  const [showBannerData, setShowBannerData] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState();
  const [bannerId, setBannerId] = useState("");
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [showCatogeryForm, setShowCatogeryForm] = useState(false);
  const loader = useLoader("");
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    showBannerData.map((item, index) => {
      let obj = {
        "S No": index + 1,
        "Banner Id": item?.bannerId,
        "Banner Name": item?.name,
        "Banner Image": item?.imageUrl,
        "Banner Type": item?.bannerType,
        "Date & Time": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
        "Updated Date & Time": moment(item?.updatedAt).format(
          "DD/MM/YYYY , LT"
        ),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [showBannerData]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Banner Report";
    const headers = [
      [
        "S.No",
        "Banner Id",
        "Banner Name",
        "Banner Image",
        "Banner Type",
        "Date & Time",
        "Updated Date & Time",
      ],
    ];
    console.log("exportAllData", exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Banner Id"],
      item1["Banner Name"],
      item1["Banner Image"],
      item1["Banner Type"],
      item1["Date & Time"],
      item1["Updated Date & Time"],
      // item1["Reason"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Banners Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const handleShowDeleteAlert = () => {
    setShowDeleteAlert(true);
  };

  const handleShowDeleteAlerClose = () => {
    setShowDeleteAlert(false);
  };

  const handleCancelDelete = () => {
    setShowDeleteAlert(false);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  useEffect(() => {
    fetchBannerList();
  }, [value, page, perPage, apiProvider?.getApiUrl(), endDate]);

  const fetchBannerList = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETBANNER,
      "POST",
      {
        key: value,
        page,
        perPage,
        startDate,
        endDate,
      }
    )
      .then((res) => {
        setShowBannerData(res?.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        setShowBannerData([]);
        console.log("err", err);
      });
  };

  const handleAddBanner = () => {
    setShowBannerForm(true);
  };
  const handleAddCatogery = () => {
    setShowCatogeryForm(true);
  };
  const handleCloseCatogery = () => {
    setShowCatogeryForm(false);
  };
  const handleAddBannerClose = () => {
    setShowBannerForm(false);
  };

  const onSubmit = () => {
    setShowBannerForm(false);
    fetchBannerList();
  };

  const handleDelete = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.DELETEBANNER +
        `/${bannerId}`,
      "DELETE"
    )
      .then((res) => {
        console.log(res);
        setShowDeleteAlert(false);
        successToast(res.message);
        fetchBannerList();
      })
      .catch((err) => {
        console.log(err, "err==========");
        errorToast(err.message);
      });
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleExportCsv = () => {
    const filename =`Banners Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handleExportExcel = () => {
    const filename =`Banners Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  return (
    <>
      <SearchInput
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
        value={value}
      />

      <div className="add__banner__styling">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
          type="date"
          value={endDate}
          onChange={handleEndDate}
        ></input>
        <Button text="Export PDF" onClick={exportPDF} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Excel" onClick={handleExportExcel} />
        <Button text="Add Catogery" onClick={handleAddCatogery} />
        <Button text="Add Banner" onClick={handleAddBanner} />
      </div>

      <BannerTable
        showBannerData={showBannerData}
        setBannerId={setBannerId}
        setShowDeleteAlert={setShowDeleteAlert}
        fetchBannerList={fetchBannerList}
        value={value}
        page={page}
        perPage={perPage}
      />
      <FormAlertPopUp
        open={showBannerForm}
        onRequestClose={handleAddBannerClose}
      >
        <BannerForm
          onSubmit={onSubmit}
          handleClose={handleAddBannerClose}
          fetchBannerList={fetchBannerList}
          // id={id}
        />
      </FormAlertPopUp>
      <FormAlertPopUp
        open={showCatogeryForm}
        onRequestClose={handleCloseCatogery}
        // fetchCatogeryList={AddCatogery}
      >
        <AddCatogery setShowBannerForm={setShowBannerForm} />
      </FormAlertPopUp>

      {showBannerData && showBannerData.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}

      <AlertPopUp
        open={showDeleteAlert}
        handleOpen={handleShowDeleteAlert}
        handleClose={handleShowDeleteAlerClose}
        header="Delete Alert"
        description="Are you sure you want to delete this banner?"
        submitText="Yes"
        cancelText="No"
        onCancelClick={handleCancelDelete}
        onSubmitClick={handleDelete}
      />
    </>
  );
};

export default Banner;
