import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import SearchInput from "../../SearchInput";
import Button from "../../library/Button";
import "./style.css";
import Lottie from "react-lottie";
import AlertPopUp from "../../AlertPopUp";
import InputField from "../../library/InputField";
import WebModal from "../../WebModal";
import Pagination from "../../Pagination";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import jsPDF from "jspdf";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import moment from "moment";
import { FiSearch } from "react-icons/fi";
import {
  exportToCSV,
  exportToExcel,
} from "react-easy-export/dist/react-easy-export";
import noData from "../../../base/Animation/No Data Found.json";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";

export const SuspendedSingleUser = () => {
  const [suspendedUserList, setSuspendedUserList] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  // const [showEditAlert, setShowEditAlert] = useState(false);
  //   const [id, setId] = useState("");
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  //   const [endDate1, setEndDate1] = useState("");
  const [showSuspendedPop, setShowSuspendedPop] = useState(false);
  const [exportAllData, setExportAllData] = useState([]);
  const [showEditAlert, setShowEditAlert] = useState(false);

  useEffect(() => {
    let array = [];
    suspendedUserList.map((item,index) => {
      let obj = {
        "S.No":index+1,
        "User Name": item?.userId?.name,
        "Suspended ID": item?.suspendedId,
        "Suspended From": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
        "Suspended To": moment(item?.suspensionEndDate).format(
          "DD/MM/YYYY , LT"
        ),
        "Suspended Reason": item?.suspendedResion,
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [suspendedUserList]);

  const loader = useLoader();
  const apiProvider = useApi();

  // const handleDeleteAlert = () => {
  //   setShowDeleteAlert(true);
  // };

  // const handleDeleteAlertClose = () => {
  //   setShowDeleteAlert(false);
  // };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExcelExport = () => {
    const filename =`Suspended Single user Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCSV = () => {
    const filename =`Suspended Single user Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Suspended Users Report";
    const headers = [
      [
        "User Name",
        "Suspended ID",
        "Suspended From",
        "Suspended To",
        "Suspended Reason",
      ],
    ];
    console.log(exportAllData);
    const filename =`Suspended Single user Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    const data = exportAllData.map((item1) => [
      item1["User Name"],
      item1["Suspended ID"],
      item1["Suspended From"],
      item1["Suspended To"],
      item1["Suspended Reason"],
      // item1["Reason"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  useEffect(() => {
    fetchSingleUser();
  }, [apiProvider?.getApiUrl(), page, perPage, value, endDate]);

  const fetchSingleUser = () => {
    const appType =
      searchParams.get("appType") === "catchwoo" ? "catchWoo" : "";
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.SINGLESUSPENSION,
      "POST",
      {
        appType,
        key: value,
        startDate,
        endDate,
        id: id,
      }
    )
      .then((res) => {
        setSuspendedUserList(res?.result);
        loader.showLoader(false);
        console.log(res,'resssssssss',res.result?.length);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        loader.showLoader(false);
        setSuspendedUserList([])
        console.log(err);
      });
  };

  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="suspended_table_btn">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          onChange={handleEndDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        ></input>
        <Button text="Export Excel" onClick={handleExcelExport} />
        <Button text="Export Pdf" onClick={exportPDF} />
        <Button text="Export CSV" onClick={handleExportCSV} />
      </div>
      <div className="suspended__table__container web_table_container">
        <div className="table_parent_box">
          <table className="suspended__table">
            <thead>
              <th className="suspended__table__header">S.no</th>
              {/* {!searchParams.get("id") && (
                <>
                  <th className="suspended__table__header">User ID</th>
                  <th className="suspended__table__header">User Name</th>
                </>
              )} */}
              <th className="suspended__table__header">Suspended ID</th>

              <th className="suspended__table__header">Suspended From</th>
              <th className="suspended__table__header">Suspended To</th>
              <th className="suspended__table__header">Suspended Reason</th>
              {/* <th className="suspended__table__header">Is Suspended</th> */}

              {/* <th className="suspended__table__header">Action</th> */}
            </thead>
            <tbody>
              {suspendedUserList && suspendedUserList.length > 0
                ? suspendedUserList.map((data, index) => {
                    return (
                      <tr>
                        <td className="suspended__table__data">
                          {(page - 1) * perPage + index + 1}
                        </td>
                        {/* {!searchParams.get("id") && (
                          <>
                            <td className="suspended__table__data">
                              {data?._id}
                            </td>

                            <td className="suspended__table__data">
                              {data?.userId?.name}
                            </td>
                          </>
                        )} */}
                        <td className="suspended__table__data">
                          {data?.suspendedId}
                        </td>
                        <td className="suspended__table__data">
                          {moment(data?.createdAt).format("DD/MM/YYYY")}
                        </td>
                        <td className="suspended__table__data">
                          {moment(data?.suspensionEndDate).format("DD/MM/YYYY")}
                        </td>
                        <td className="suspended__table__data">
                          {data?.suspendedResion}
                        </td>
                        {/* <td
                          className="suspended__table__data"
                          onClick={() => handleSuspendedUser(data?._id)}
                        >
                          {`${data?.isSuspended}`}
                        </td>
                        <td className="suspended__table__data suspended__table__edit__icon ">
                          <AiFillEdit
                            onClick={() => {
                              handleOnClickEdit(data?.suspendedId);
                            }}
                          />
                          <AiFillDelete
                            className="suspended__table__delete__icon"
                            onClick={() => {
                              handleOnClickDelete(data?._id);
                            }}
                          />
                        </td> */}
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>

        {!suspendedUserList?.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%" }}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}
      </div>
      {suspendedUserList?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          setPerPage={setPerPage}
          perPage={perPage}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};
