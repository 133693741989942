import EarningTable from "../../components/Table/EarningTable";
import "./style.css";

const Earnings = () => {
  return (
    <div>
      <EarningTable />
    </div>
  );
};

export default Earnings;
