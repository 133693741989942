import { useEffect, useState } from "react";
import "./style.css";
import { useLoader } from "../../base/Context/loaderProvider";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import { useSearchParams } from "react-router-dom";

const OpenedBy = () => {
  const [openedByData, setOpenedByData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    fetchNotification();
  }, []);

  const fetchNotification = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.GETNOTIFICATIONBYID +
        `/${searchParams.get("id")}`,
      "GET"
    )
      .then((res) => {
        setOpenedByData(res?.data?.openBy);
        loader.showLoader(false);
      })
      .catch((err) => {
        console.log(err);
        loader.showLoader(false);
      });
  };
  console.log(openedByData, "opendekdneuu");
  return (
    <div className="opened__by__container">
      <table className="opened__by__table">
        <thead>
          <th className="opened__by__header">S.No</th>
          <th className="opened__by__header">User Id</th>
          <th className="opened__by__header">User Name</th>
          <th className="opened__by__header">User Email</th>
          <th className="opened__by__header">User Mobile Number</th>
        </thead>

        <tbody>
          {openedByData.map((data, index) => {
            return (
              <tr>
                <td className="opened__by__data">{index + 1}</td>
                <td className="opened__by__data">{data?._id}</td>
                <td className="opened__by__data">{data?.name}</td>
                <td className="opened__by__data">{data?.email}</td>
                <td className="opened__by__data">{data?.mobileNumber}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OpenedBy;
