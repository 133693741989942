import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import TotalHOstEarningTable from "../../components/Table/TotalHostEarningTable";
import { useLocation, useSearchParams } from "react-router-dom";
import { useApi } from "../../base/Context/apiProvider";
import SearchInput from "../../components/SearchInput";
import { FiSearch } from "react-icons/fi";

const TotalHostEarning = () => {
  const [tableData, setTableData] = useState([]);
  const [isHost, setIsHost] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [value, setValue] = useState("");
  const data = useLocation();
  const apiProvider = useApi();
  useEffect(() => {
    setTableData(data.state);
  }, []);
  console.log(data.state, "daaaattaaa");
  const handleText = (e) => {
    setValue(e.target.value);
  };
  const searchIcon = () => {
    return <FiSearch />;
  };
  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      {/* <div> */}
      <TotalHOstEarningTable
        isHost={isHost}
        tableData={tableData}
        page={page}
        perPage={perPage}
      />

      {tableData && tableData.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : (
        ""
      )}
      {/* </div> */}
    </>
  );
};

export default TotalHostEarning;
