import { useEffect, useState } from "react";
import Button from "../../components/library/Button";
import "./style.css";
import BulkPopup from "../../components/sendMails/bulkPopup";
import MailPopup from "../../components/sendMails/mailPopup";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import { useSearchParams } from "react-router-dom";

const BounceBack = () => {
  const [mail, setMail] = useState(false);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [bounceBackUser, setBounceBackUser] = useState([]);
  const [bounceBackHost, setBounceBackHost] = useState([]);
  const [checkHeaderClick, setCheckHeaderClick] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [people, setPeople] = useState();
  const apiProvider = useApi();

  const handleChange = (e) => {
    if (data.length === 0) {
      setData([e]);
    } else if (!data.includes(e)) {
      setData([...data, e]);
    } else {
      const array = data.filter((item) => {
        return item !== e;
      });

      setData(array);
    }
  };

  const handleAllChecked = (e) => {
    if (e.target.checked) {
      if (bounceBackUser.length) {
        let inactiveNotificationList = bounceBackUser.map((email) => {
          return email?.email;
        });
        setAllData(inactiveNotificationList);
        setCheckHeaderClick(true);
        setData([]);
      } else if (bounceBackHost.length) {
        let inactiveNotificationList = bounceBackHost.map((email) => {
          return email?.email;
        });
        setAllData(inactiveNotificationList);
        setCheckHeaderClick(true);
        setData([]);
      }
    } else {
      setCheckHeaderClick(false);
      setAllData([]);
    }
  };

  const handleSendMail = () => {
    setMail(true);
  };

  useEffect(() => {
    getNotificationById();
  }, []);

  const getNotificationById = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.GETNOTIFICATIONBYID +
        `/${searchParams.get("id")}`,
      "GET"
    )
      .then((res) => {
        setBounceBackHost(res?.data?.failedHost);
        setBounceBackUser(res?.data?.failedUser);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (searchParams.get("appType") !== "host") {
      setPeople(bounceBackUser);
    } else {
      setPeople(bounceBackHost);
    }
  }, [bounceBackHost, bounceBackUser]);

  return (
    <>
      <div className="send_mails_header">
        <Button
          onClick={handleSendMail}
          text="Send Mail"
          className="header_btn"
          type="button"
        />
      </div>
      <div className="bounce_back_container">
        <table className="bounce_back_table">
          <thead>
            <th className="bounce_back_header">
              {" "}
              <input
                className="bounce_back_check"
                type="checkbox"
                onChange={handleAllChecked}
              />
            </th>
            <th className="bounce_back_header">S.No.</th>
            {searchParams.get("appType") === "host" ? (
              <>
                <th className="bounce_back_header">Host ID</th>
                <th className="bounce_back_header">Host Name</th>{" "}
              </>
            ) : (
              <>
                {" "}
                <th className="bounce_back_header">User ID</th>
                <th className="bounce_back_header">User Name</th>{" "}
              </>
            )}
            <th className="bounce_back_header">Email</th>
            <th className="bounce_back_header">Mobile Number</th>
          </thead>
          <tbody>
            {people &&
              people.map((data, index) => {
                return (
                  <tr>
                    <td className="bounce_back_data">
                      <input
                        className="bounce_back_check"
                        type="checkbox"
                        onChange={() => handleChange(data?.email)}
                        {...(checkHeaderClick ? { checked: true } : {})}
                      />
                    </td>

                    <td className="bounce_back_data">{index + 1}</td>
                    <td className="bounce_back_data">{data?._id}</td>
                    <td className="bounce_back_data">{data?.name}</td>
                    <td className="bounce_back_data">{data?.email}</td>
                    <td className="bounce_back_data">{data?.mobileNumber}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="send_mail_box">
        {mail ? (
          <MailPopup
            show={mail}
            setShow={setMail}
            allMailsData={allData}
            mailsData={data}
            // handleMailList={handleMails}
          />
        ) : null}
      </div>
    </>
  );
};

export default BounceBack;
