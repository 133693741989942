import { useContext, useEffect, useState } from "react";
import "./style.css";
import moment from "moment/moment";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import AlertPopUp from "../../AlertPopUp";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../../base/Context/loaderProvider";
import FormAlertPopUp from "../../FormAlertPopUp";
import HostVideoCallCharge from "../../FormAlertPopUp/HostVideoCallCharge";
import HostAudioCharge from "../../FormAlertPopUp/HostAudioCharge";
import SearchInput from "../../SearchInput";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { BsFillEyeFill } from "react-icons/bs";
import ImagePopUpModal from "../../ImagePopUpModal";
import { useApi } from "../../../base/Context/apiProvider";
// import Breadcrumbs from "../../Breadcrumbs";
import HostEditForm from "../../formComponents/HostEditForm";
import { Modal } from "../../../base/Context/modalProvider";
import { exportToExcel, exportToCSV, exportToPDF } from "react-easy-export";
import Button from "../../library/Button";
import jsPDF from "jspdf";
import "jspdf-autotable";

const AcceptedHostTable = () => {
  let navigate = useNavigate();
  const [acceptedHost, setAcceptedHost] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showChangeLeaderAlert, setShowChangeLeaderAlert] = useState(false);
  const [showChargeAlert, setShowChargeAlert] = useState(false);
  const [showAudioAlert, setShowAudioAlert] = useState(false);
  const [id, setId] = useState("");
  const [leaderId, setLeaderId] = useState("");
  const [leaderName, setLeaderName] = useState("");
  const [leaderNames, setLeaderNames] = useState([
    {
      name: "--Select--",
      value: "",
    },
  ]);

  const [img, setImg] = useState("");
  const [images, setImages] = useState("");
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [showProfileAlert, setShowProfileAlert] = useState(false);
  const [showMultipleImages, setShowMultipleImages] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [exportAllData, setExportAllData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const loader = useLoader();
  const apiProvider = useApi();
  const modalProvider = useContext(Modal);

  useEffect(() => {
    getAcceptedHost();
    getHostLeader();
  }, [value, page, perPage, apiProvider?.getApiUrl(), endDate]);

  useEffect(() => {
    let array = [];
    acceptedHost.map((item, index) => {
      let obj = {
        "S.No": index + 1,
        "Host ID": item?.userId,
        Name: item?.name,
        "Date Of Birth": item?.dateOfBirth,
        Age: item?.age,
        "Mobile Number": item?.mobileNumber,
        "Email ID": item?.email,
        "Profile Pic": item?.profilePic,
        "Images & Videos": item?.presentationPic,
        Bio: item?.addBio,
        Profession: item?.proffession,
        "Audio Charge/sec": item?.audioCall_fees,
        "Video Charge/sec": item?.hostuser_fees,
        Leader: item?.leader?.leaderName,
        "Accepted At": moment(item?.acceptedDate).format("DD/MM/YYYY , LT"),
        Reason: item?.reasionUpdateProfile ? item?.reasionUpdateProfile : "-",
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [acceptedHost]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Accepted Host Report";
    const headers = [
      [
        "S.No",
        "Host ID",
        "Name",
        "Date Of Birth",
        "Age",
        "Mobile Number",
        "Email ID",
        "Profile Pic",
        "Images & Videos",
        "Bio",
        "Profession",
        "Audio Charge/sec",
        "Video Charge/sec",
        "Leader",
        "Accepted At",
        "Reason",
      ],
    ];

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Host ID"],
      item1?.Name,
      item1["Date Of Birth"],
      item1.Age,
      item1["Mobile Number"],
      item1["Email ID"],
      item1["Profile Pic"],
      item1['Images & Videos'],
      item1?.Bio,
      item1?.Profession,
      item1["Audio Charge/sec"],
      item1["Video Charge/sec"],
      item1["Leader"],
      item1["Accepted At"],
      item1["Reason"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`AcceptedHost Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`);
  };
  const handleExportCsv = () => {
    const filename =`AcceptedHost Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;
   ( exportAllData.length>0&&exportToCSV(exportAllData,filename))
  };
  
  const handleExportData = () => {
    const filename = `AcceptedHost Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;
    ( exportAllData.length>0&& exportToExcel(exportAllData,filename));
  };
  const handleHostEdit = (id) => {
    setEditForm(true);
    setId(id);
  };

  const handleHostEditClose = () => {
    setEditForm(false);
  };

  const handleProfilePic = (img) => {
    setShowProfileAlert(true);
    setImg(img);
  };

  const handleProfilePicClose = () => {
    setShowProfileAlert(false);
  };

  const handlePresentationPic = (images) => {
    setShowMultipleImages(true);
    setImages(images);
  };

  const handlePresentationPicClose = () => {
    setShowMultipleImages(false);
  };

  const handleAudioCallCharge = (hostId) => {
    setShowAudioAlert(true);
    setId(hostId);
  };

  const handleAudioCallChargeClose = () => {
    setShowAudioAlert(false);
  };

  const handleChangeCharge = (hostId) => {
    setShowChargeAlert(true);
    setId(hostId);
  };

  const handleChangeChargeClose = () => {
    setShowChargeAlert(false);
  };

  const handleDeleteAcceptedHostAlert = (id) => {
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleDeleteAcceptedHostAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const handleChangeLeader = (hostId) => {
    setShowChangeLeaderAlert(true);
    setId(hostId);
  };

  const handleChangeLeaderClose = () => {
    setShowChangeLeaderAlert(false);
  };

  const getHostLeader = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETLEADER,
      "POST",
      { page: 1, perPage: 100 }
    )
      .then((res) => {
        const leaders = res?.result?.map((leader) => ({
          name: leader?.leaderName,
          value: leader?._id,
        }));
        setLeaderNames([...leaderNames, ...leaders]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAcceptedHost = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.ACCEPTEDHOST,
      "POST",
      {
        startDate,
        endDate,
        key: value,
        page,
        perPage,
      }
    )
      .then((res) => {
        setAcceptedHost(res.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        if (!err?.status) {
          setAcceptedHost([]);
        }
      });
  };

  const handleDeleteAcceptedHost = (id) => {
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleDeletedAcceptedHost = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DELETEHOST + `/${id}`,
      "DELETE"
    )
      .then((res) => {
        setShowDeleteAlert(false);
        getAcceptedHost();
        loader.showLoader(false);
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };

  const handleAcceptedHostLeader = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.UPDATELEADER + `/${id}`,
      "PUT",
      {
        leader: leaderId,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        getAcceptedHost();
        setShowChangeLeaderAlert(false);
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err);
      });
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

 

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

 

  return (
    <>
      <SearchInput
        onChange={handleText}
        value={value}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="accepted_host_btn">
        <label>Start Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={endDate}
          onChange={handleEndDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportData} textStyle={{fontSize:'16px'}}/>
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
      <div className="accepted_host_container web_table_container">
        <div className="table_parent_box">
          <table className="accepted__host__table">
            <thead>
              <th className="accepted__host__header">S.No.</th>
              <th className="accepted__host__header">Host ID</th>
              <th className="accepted__host__header">Name</th>
              <th className="accepted__host__header">Date Of Birth</th>
              <th className="accepted__host__header">Age</th>
              <th className="accepted__host__header">Mobile Number</th>
              <th className="accepted__host__header">Email ID</th>
              <th className="accepted__host__header">Profile Pic </th>
              <th className="accepted__host__header">Images & Videos </th>
              <th className="accepted__host__header">Bio</th>
              <th className="accepted__host__header">Profession</th>
              <th className="accepted__host__header">Audio Charge/sec</th>
              <th className="accepted__host__header">Video Charge/sec</th>
              <th className="accepted__host__header">Leader</th>
              <th className="accepted__host__header">Accepted At</th>
              <th className="accepted__host__header">Reason</th>
              <th className="accepted__host__header">Details</th>
              <th className="accepted__host__header">Action</th>
            </thead>
            <tbody>
              {acceptedHost?.length > 0
                ? acceptedHost.map((data, index) => {
                    return (
                      <tr>
                        <td className="accepted__host__data">
                          {(page - 1) * perPage + index + 1}
                        </td>
                        <td className="accepted__host__data">{data?.userId}</td>
                        <td className="accepted__host__data">{data?.name}</td>
                        <td className="accepted__host__data">
                          {data?.dateOfBirth}
                        </td>
                        <td className="accepted__host__data">{data?.age}</td>
                        <td className="accepted__host__data">
                          {data?.mobileNumber}
                        </td>
                        <td className="accepted__host__data">{data?.email}</td>
                        <td className="accepted__host__data">
                          {data?.profilePic && (
                            <BsFillEyeFill
                              className="eye__btn"
                              onClick={() => handleProfilePic(data?.profilePic)}
                            />
                          )}
                        </td>
                        <td className="accepted__host__data">
                          {data?.presentationPic && (
                            <BsFillEyeFill
                              className="eye__btn"
                              onClick={() =>
                                handlePresentationPic(data?.presentationPic)
                              }
                            />
                          )}
                        </td>
                        <td className="accepted__host__data">
                          <div
                            className="feedback__table__comment"
                            onClick={
                              data?.addBio?.length > 10
                                ? () =>
                                    modalProvider.handleCommentClick(
                                      data?.addBio,
                                      "Name"
                                    )
                                : () => {}
                            }
                          >
                            {data?.addBio}
                          </div>
                        </td>

                        <td className="accepted__host__data">
                          <div
                            className="feedback__table__comment"
                            onClick={
                              data?.proffession?.length > 10
                                ? () =>
                                    modalProvider.handleCommentClick(
                                      data?.proffession,
                                      "Name"
                                    )
                                : () => {}
                            }
                          >
                            {data?.proffession}
                          </div>
                        </td>

                        <td className="accepted__host__data">
                          {data?.audioCall_fees}

                          <AiFillEdit
                            className="accepted__host__edit_icon"
                            onClick={() => {
                              handleAudioCallCharge(
                                data?._id,
                                data?.audioCall_fees
                              );
                            }}
                          />
                        </td>
                        <td className="accepted__host__data">
                          {data?.hostuser_fees}
                          <AiFillEdit
                            className="accepted__host__edit_icon"
                            onClick={() => {
                              handleChangeCharge(data?._id);
                            }}
                          />
                        </td>
                        <td className="accepted__host__data">
                          <div className="host__leader__edit">
                            <div
                              className="feedback__table__comment"
                              onClick={
                                data?.leader?.leaderName?.length > 10
                                  ? () =>
                                      modalProvider.handleCommentClick(
                                        data?.leader?.leaderName,
                                        "Name"
                                      )
                                  : () => {}
                              }
                            >
                              {data?.leader?.leaderName}
                            </div>
                            <AiFillEdit
                              className="accepted__host__edit_icon"
                              onClick={() => {
                                handleChangeLeader(data?._id);
                              }}
                            />
                          </div>
                        </td>
                        <td className="accepted__host__data">
                          {moment(data?.acceptedDate).format("DD/MM/YYYY LT")}
                        </td>
                        <td className="accepted__host__data">
                          {data?.reasionUpdateProfile}
                        </td>
                        <td
                          className="accepted__host__view accepted__host__data"
                          onClick={() => {
                            navigate(
                              `/acceptedhost/hostmanagement/${data?._id}${
                                sessionStorage.getItem("selectedType") ===
                                "host"
                                  ? "?appType=host"
                                  : ""
                              }`
                            );
                          }}
                        >
                          View
                        </td>
                        <td className="accepted__host__data">
                          <AiFillEdit
                            onClick={() => {
                              handleHostEdit(data?._id);
                            }}
                            className="accepted__host__edit__icon"
                          />
                          <AiFillDelete
                            onClick={() => {
                              handleDeleteAcceptedHost(data?._id);
                            }}
                            className="accepted__host__delete__icon"
                          />
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>

          {!acceptedHost?.length
            ? !loader.loaderPopup && (
                <div className="host__no__data__found__icon">
                  <Lottie
                    options={{ animationData: noData, loop: true }}
                    style={{ width: "40%", height: "30%", color: "#6C5DD3" }}
                  />
                  <p className="no__data__found">No Data Found</p>
                </div>
              )
            : null}
        </div>

        <ImagePopUpModal
          open={showProfileAlert}
          handleClose={handleProfilePicClose}
          img={img}
        />

        <ImagePopUpModal
          open={showMultipleImages}
          handleClose={handlePresentationPicClose}
          images={images}
        />

        <AlertPopUp
          open={showDeleteAlert}
          handleOpen={handleDeleteAcceptedHostAlert}
          handleClose={handleDeleteAcceptedHostAlertClose}
          header="Delete Accepted Host"
          description="Are you sure you want to delete this accepted host?"
          submitText="Yes"
          onCancelClick={handleDeleteAcceptedHostAlertClose}
          cancelText="No"
          onSubmitClick={handleDeletedAcceptedHost}
        />

        <AlertPopUp
          open={showChangeLeaderAlert}
          handleOpen={handleChangeLeader}
          handleClose={handleChangeLeaderClose}
          submitText="Submit"
          cancelText="Cancel"
          header="Select a leader"
          dropdown={true}
          dropdownOptions={leaderNames}
          onChangeDropdown={(e) => setLeaderId(e.target.value)}
          onSubmitClick={handleAcceptedHostLeader}
          onCancelClick={handleChangeLeaderClose}
        />

        <FormAlertPopUp open={editForm} onRequestClose={handleHostEditClose}>
          <HostEditForm
            id={id}
            setEditForm={setEditForm}
            getAcceptedHost={getAcceptedHost}
          />
        </FormAlertPopUp>

        <FormAlertPopUp
          open={showChargeAlert}
          onRequestClose={handleChangeChargeClose}
          submitText="Submit"
        >
          <HostVideoCallCharge
            id={id}
            getAcceptedHost={getAcceptedHost}
            setShowChargeAlert={setShowChargeAlert}
          />
        </FormAlertPopUp>

        <FormAlertPopUp
          open={showAudioAlert}
          onRequestClose={handleAudioCallChargeClose}
          handleOpen={handleAudioCallCharge}
        >
          <HostAudioCharge
            id={id}
            getAcceptedHost={getAcceptedHost}
            setShowAudioAlert={setShowAudioAlert}
          />
        </FormAlertPopUp>
      </div>
      {acceptedHost?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          setPerPage={setPerPage}
          perPage={perPage}
          options={[50, 100, 150, 200]}
        />
      ) : null}
    </>
  );
};

export default AcceptedHostTable;
