import { useEffect, useState } from "react";
import "./style.css";
import { RxCross1 } from "react-icons/rx";

const WebModal = ({ onRequestClose, open, children, heading }) => {
  const [showVisible, setShowVisible] = useState(open);

  useEffect(() => {
    setShowVisible(open);
  }, [open]);

  const onClickOutside = (e) => {
    e.stopPropagation();
  };

  return (
    showVisible && (
      <div className="web__modal__container" onClick={onRequestClose}>
        <div className="web_modal_data" onClick={onClickOutside}>
          {/* <div className="web_modal_icon">
              <RxCross1 onClick={onRequestClose} />
            </div> */}
          <h2 className="web__modal__heading">{heading}</h2>
          <div className="web__modal__content">{children}</div>
        </div>
      </div>
    )
  );
};

export default WebModal;
