import { useApi } from "../../base/Context/apiProvider";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import "./style.css";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import ImagePopUpModal from "../../components/ImagePopUpModal";
import Pagination from "../../components/Pagination";
import moment from "moment";
import Lottie from "react-lottie";
import noData from "../../base/Animation/No Data Found.json";
import { useLoader } from "../../base/Context/loaderProvider";
import SearchInput from "../../components/SearchInput";
import { FiSearch } from "react-icons/fi";
import Button from "../../components/library/Button";
import { exportToExcel, exportToCSV, exportToPDF } from "react-easy-export";
import AlertPopUp from "../../components/AlertPopUp";
import jsPDF from "jspdf";

const Snapshots = () => {
  const navigate = useNavigate();
  const apiProvider = useApi();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  // const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [count, setCount] = useState("");
  const [snapshot, setSnapshot] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [img, setImg] = useState("");
  const [value, setValue] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [id, setId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const loader = useLoader();

  const handleDeleteClick = (id) => {
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleDeleteClickClose = () => {
    setShowDeleteAlert(false);
  };

  useEffect(() => {
    let array = [];
    snapshot.map((item,index) => {
      let obj = {
        "S.No":index+1,

        "user Name": item?.userId?.name,
        "Host Name": item?.hostId?.name,
        Snapshots: item?.snapshot,
        "Send By": item?.imageOf,
        "Created At": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [snapshot]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Warned Users Report";
    const headers = [
      ["user Name", "Host Name", "Snapshots", "Send By", "Created At"],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1) => [
      item1["user Name"],
      item1["Host Name"],
      item1["Snapshots"],
      item1["Send By"],
      item1["Created At"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Snapshots Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  useEffect(() => {
    fetchSnapshots();
  }, [page, perPage, apiProvider?.getApiUrl(), value, endDate]);

  const fetchSnapshots = () => {
    const appType = searchParams.get("appType") === "host" ? "host" : "User";
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.SNAPSHOTS,
      "POST",
      {
        appType,
        startDate,
        endDate,
        page,
        perPage,
        search: value,
      }
    )
      .then((res) => {
        console.log(res);
        setSnapshot(res?.allSnapshots);
        // setTotalCount(res?.totalCount);
        setCount(res?.count);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
        setSnapshot([]);
      });
  };

  const handleDelete = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DELETESNAPSHOT + `/${id}`,
      "DELETE"
    )
      .then((res) => {
        console.log(res);
        loader.showLoader(false);
        setShowDeleteAlert(false);
        fetchSnapshots();
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err);
      });
  };

  const handleEyeClick = (img) => {
    setShowImage(true);
    setImg(img);
  };

  const handleEyeClickClose = () => {
    setShowImage(false);
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportData = () => {
    const filename =`Snapshots Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCsv = () => {
    const filename =`Snapshots Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };
  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="snapshots__btn">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          onChange={handleEndDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
      <div className="snapshots__container web_table_container">
        <table className="snapshots__table">
          <thead>
            <th className="snapshots__header">S.No.</th>
            <th className="snapshots__header">user Name</th>
            <th className="snapshots__header">Host Name</th>
            <th className="snapshots__header">Snapshots</th>
            <th className="snapshots__header">Send By</th>
            <th className="snapshots__header">App Type</th>
            <th className="snapshots__header">Created At</th>
            <th className="snapshots__header">Action</th>
          </thead>
          <tbody>
            {snapshot.map((data, index) => {
              return (
                <tr>
                  <td className="snapshots__data">
                    {(page - 1) * perPage + index + 1}
                  </td>
                  <td className="snapshots__data">
                    {data?.userId?.name}
                    <AiFillEdit
                      className="snapshot__icon"
                      onClick={() => {
                        navigate(
                          `${
                            searchParams.get("appType") === "catchwoo"
                              ? `/usermanagement/${data?.userId?._id}?appType=` +
                                searchParams.get("appType")
                              : `/usermanagement/${data?.userId?._id}`
                          }`
                        );
                      }}
                    />
                  </td>
                  <td className="snapshots__data">
                    {data?.hostId?.name}
                    {/* <AiFillEdit
                      onClick={() => {
                        navigate(`/hostmanagement/${data?.hostId?._id}`);
                      }} */}
                    {/* className="snapshot__icon" /> */}
                  </td>

                  <td className="snapshots__data">
                    <AiFillEye
                      style={{ fontSize: "18px", cursor: "pointer" }}
                      onClick={() => handleEyeClick(data?.snapshot)}
                    />{" "}
                    {data?.name}
                  </td>
                  <td className="snapshots__data">{data?.imageOf}</td>
                  <td className="snapshots__data">{data?.userId?.appType}</td>

                  <td className="snapshots__data">
                    {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                  </td>
                  <td className="snapshots__data">
                    <AiFillDelete
                      className="snapshots__icon"
                      onClick={() => handleDeleteClick(data?._id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {!snapshot.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}

        <ImagePopUpModal
          open={showImage}
          handleClose={handleEyeClickClose}
          img={img}
        />

        <AlertPopUp
          open={showDeleteAlert}
          handleOpen={handleDeleteClick}
          handleClose={handleDeleteClickClose}
          header="Delete Alert"
          description="Are you sure you want to delete this snapshot?"
          submitText="Yes"
          cancelText="No"
          onSubmitClick={handleDelete}
          onCancelClick={handleDeleteClickClose}
        />
      </div>
      {snapshot && snapshot.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          // totalCount={ount}
          totalPages={totalPages}
          totalCount={count}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default Snapshots;
