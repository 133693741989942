import { useEffect, useState } from "react";
import Button from "../../components/library/Button";
import "./style.css";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import moment from "moment";
import FormAlertPopUp from "../../components/FormAlertPopUp";
import InactiveUserForm from "../../components/formComponents/InactiveUserForm";
import Pagination from "../../components/Pagination";
import SearchInput from "../../components/SearchInput";
import { FiSearch } from "react-icons/fi";
import { useLoader } from "../../base/Context/loaderProvider";
import Lottie from "react-lottie";
import noData from "../../base/Animation/No Data Found.json";
import { errorToast, successToast } from "../../utils/toast";
import BulkPopup from "../../components/sendMails/bulkPopup";
import MailPopup from "../../components/sendMails/mailPopup";

const ActiveHost = () => {
  const [activeHost, setActiveHost] = useState([]);
  const [bulkMail, setBulkMail] = useState(false);
  const [mail, setMail] = useState(false);
  const [data, setData] = useState([]);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [allData, setAllData] = useState([]);
  const [checkHeaderClick, setCheckHeaderClick] = useState(false);
  const [perPage, setPerPage] = useState(50);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [value, setValue] = useState("");
  const apiProvider = useApi();
  const loader = useLoader();

  const handleChange = (value) => {
    console.log(value, "datachanged", data);
    const itemExists = data.some((item) => item.id === value._id);
    if (itemExists) {
      const filteredData = data.filter((item) => item.id !== value._id);
      setData(
        filteredData.map((item) => ({
          id: item.id,
          name: item.name,
        }))
      );
    } else {
      setData([...data, { id: value._id, name: value.name }]);
    }
  };

  const handleAllchecked = (e) => {
    console.log(data, "data");
    if (e.target.checked) {
      let activeHostId = activeHost.map((id) => {
        return id?._id;
      });
      
      setAllData(activeHostId);
      setData([...activeHostId]);
      console.log(activeHostId,'activvvv')
      setCheckHeaderClick(true);
    } else {
      setAllData([]);
      setCheckHeaderClick(false);
    }
  };

  const handleFormClick = () => {
    if (data.length > 0) {
      setShowNotificationPopup(true);
    }
  };

  const handleFormClickClose = () => {
    setShowNotificationPopup(false);
  };

  useEffect(() => {
    fetchActiveHosts();
  }, [apiProvider?.getApiUrl(), page, perPage, value]);

  const fetchActiveHosts = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.ONLINEHOST,
      "POST",
      {
        key: value,
        page,
        perPage,
      }
    )
      .then((res) => {
        console.log(res);
        setActiveHost(res?.data);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };
  const handleSendBulkMail = () => {
    setBulkMail(true);
    setMail(false);
  };
  return (
    <>
      <SearchInput
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
        value={value}
      />
      <div className="active__user"style={{padding:'5px'}}>
      <Button
          onClick={handleSendBulkMail}
          text="Bulk Mail"
          className="header_btn "
          type="button"
          style={{marginRight:'20px'}}
        />
        <Button
          style={{ textAlign: "center" }}
          text="Send Notification"
          onClick={handleFormClick}
        />
      </div>
      <div className="active__user__container web_table_container">
        <table className="active__user__table ">
          <thead>
            <th className="active__user__header">
              <input
                onChange={handleAllchecked}
                type="checkbox"
                className="active__user__input"
              />
            </th>
            <th className="active__user__header">S.No.</th>
            <th className="active__user__header">Host Id</th>
            <th className="active__user__header">Name</th>
            <th className="active__user__header">Group Name</th>
            <th className="active__user__header">Leader ID</th>
            <th className="active__user__header">Joining Date</th>
            {/* <th className="active__user__header">Current Week Earning</th> */}
          </thead>
          <tbody>
            {activeHost && activeHost.length > 0
              ? activeHost.map((data, index) => {
                  return (
                    <tr>
                      <td className="active__user__data">
                        <input
                          className="active__user__input"
                          type="checkbox"
                          value={data?._id}
                          onChange={()=>handleChange(data)}
                          {...(checkHeaderClick ? { checked: true } : {})}
                        />
                      </td>
                      <td className="active__user__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="active__user__data">{data?.userId}</td>
                      <td className="active__user__data">{data?.name}</td>

                      <td className="active__user__data">
                        {data?.leader?.groupName}
                      </td>
                      <td className="active__user__data">
                        {data?.leader?.leaderId}
                      </td>
                      <td className="active__user__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY ,LT")}
                      </td>
                      {/* <td className="active__user__data">{data?.leaderId}</td> */}
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>

        {!activeHost.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
      </div>
      <FormAlertPopUp
        open={showNotificationPopup}
        onRequestClose={handleFormClickClose}
      >
        <InactiveUserForm
          user={false}
          data={data}
          setData={setData}
          setAllData={setAllData}
          setShowNotificationPopup={setShowNotificationPopup}
          allData={allData}
          checkHeaderClick={checkHeaderClick}
        />
      </FormAlertPopUp>

      {activeHost && activeHost.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
       {bulkMail ? (
            <BulkPopup
              show={bulkMail}
              setShow={setBulkMail}
              // handleMailList={handleMails}
              users={data}
            />
          ) : null}
          {mail ? (
            <MailPopup
              show={mail}
              setShow={setMail}
              // handleMailList={handleMails}
            />
          ) : null}
    </>
  );
};

export default ActiveHost;
