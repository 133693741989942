import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import SearchInput from "../../SearchInput";
import Button from "../../library/Button";
import "./style.css";
import Lottie from "react-lottie";
import WebModal from "../../WebModal";
import InputField from "../../library/InputField";
import AlertPopUp from "../../AlertPopUp";
import Pagination from "../../Pagination";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useApi } from "../../../base/Context/apiProvider";
import { useLoader } from "../../../base/Context/loaderProvider";
import moment from "moment";
import jsPDF from "jspdf";
import { FiSearch } from "react-icons/fi";
import {
  exportToCSV,
  exportToExcel,
} from "react-easy-export/dist/react-easy-export";
import noData from "../../../base/Animation/No Data Found.json";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";

export const SuspendedSingleHost = () => {
  const [suspendedHostList, setSuspendedHostList] = useState([]);
  // const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  // const [id, setId] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [endDate1, setEndDate1] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  // const [showEditAlert, setShowEditAlert] = useState(false);
  const { id } = useParams();
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    suspendedHostList.map((item,index) => {
      let obj = {
        "S.No":index+1,
        "Host Name": item?.hostId?.name,
        "Suspended ID": item?.suspendedId,
        "Suspended From": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
        "Suspended To": moment(item?.suspensionEndDate).format(
          "DD/MM/YYYY , LT"
        ),
        "Suspended Reason": item?.suspendedResion,
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [suspendedHostList]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Suspended Host Report";
    const headers = [
      [
        "Host Name",
        "Suspended ID",
        "Suspended From",
        "Suspended To",
        "Suspended Reason",
      ],
    ];
    console.log("exportAllData", exportAllData);

    const data = exportAllData.map((item1) => [
      item1["Host Name"],
      item1["Suspended ID"],
      item1["Suspended From"],
      item1["Suspended To"],
      item1["Suspended Reason"],
      // item1["Reason"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Suspended Single Host Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportExcel = () => {
    const filename =`Suspended Single Host Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCsv = () => {
    const filename =`Suspended Single Host Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  useEffect(() => {
    fetchSingleUser();
  }, [apiProvider?.getApiUrl(), page, perPage,value, endDate]);

  const fetchSingleUser = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.SINGLESUSPENSIONHOST,
      "POST",
      {
        id: id,
        key: value,
        startDate,
        endDate,
      }
    )
      .then((res) => {
        setSuspendedHostList(res?.result);
        loader.showLoader(false);
        console.log(res);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        setSuspendedHostList([])
        loader.showLoader(false);
        console.log(err);
      });
  };

  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="suspended_host_btn">
        <label>Start Date</label>
        <input
          className="user_request_date"
          max={new Date().toISOString().split("T")[0]}
          type="date"
          value={startDate}
          onChange={handleStartDate}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          onChange={handleEndDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportExcel} />
        <Button text="Export Pdf" onClick={exportPDF} />
        <Button text="Export CSV" onClick={handleExportCsv} />
      </div>
      <div className="suspended__host__table__container web_table_container">
        <div className="table_parent_box">
          <table className="suspended__table">
            <thead>
              <th className="suspended__host__table__header">S.no</th>
              {!searchParams.get("id") && (
                <>
                  <th className="suspended__host__table__header">Host ID</th>
                  <th className="suspended__host__table__header">Host Name</th>
                </>
              )}
              <th className="suspended__host__table__header">Suspended ID</th>

              <th className="suspended__host__table__header">Suspended From</th>
              <th className="suspended__host__table__header">Suspended To</th>
              <th className="suspended__host__table__header">
                Suspended Reason
              </th>

              {/* <th className="suspended__host__table__header">Action</th> */}
            </thead>
            <tbody>
              {suspendedHostList?.length > 0
                ? suspendedHostList.map((data, index) => {
                    return (
                      <tr>
                        <td className="suspended__host__table__data">
                          {(page - 1) * perPage + index + 1}
                        </td>
                        {!searchParams.get("id") && (
                          <>
                            <td className="suspended__host__table__data">
                              {data?.hostId?.userId}
                            </td>
                            <td className="suspended__host__table__data">
                              {data?.hostId?.name}
                            </td>
                          </>
                        )}
                        <td className="suspended__host__table__data">
                          {data?.suspendedId}
                        </td>
                        <td className="suspended__host__table__data">
                          {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                        </td>
                        <td className="suspended__host__table__data">
                          {moment(data?.suspensionEndDate).format(
                            "DD/MM/YYYY , LT"
                          )}
                        </td>
                        <td className="suspended__host__table__data">
                          {data?.suspendedResion}
                        </td>
                        <td className="suspended__host__table__data">
                          {/* <AiFillEdit
                            className="suspended__host__table__edit__icon"
                            onClick={() => handleOnClickEdit(data?.suspendedId)}
                          />
                          <AiFillDelete
                            onClick={() => {
                              handleOnClickAlert(data?._id);
                              console.log(data?._id, "data id");
                            }}
                            className="suspended__host__table__delete__icon"
                          /> */}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
          {!suspendedHostList?.length ? (
            <div className="host__no__data__found__icon">
              <Lottie
                options={{ animationData: noData, loop: true }}
                style={{ width: "40%", height: "30%" }}
              />
              <p className="no__data__found"> No Data Found</p>
            </div>
          ) : null}
        </div>

        {/* <WebModal
          open={showEditAlert}
          onRequestClose={handleOnClickEditClose}
          heading="Edit Suspended User"
        >
          <InputField
            type="date"
            onChange={(e) => setEndDate1(e.target.value)}
            value={endDate1}
          />
          <br />
          <Button
            style={{ margin: "auto" }}
            text="Update"
            onClick={handleEditHost}
          />
        </WebModal> */}

        {/* <AlertPopUp
          open={showDeleteAlert}
          handleOpen={handleDeleteAlert}
          handleClose={handleDeleteAlertClose}
          header="Delete Alert"
          description="Are you sure you want to delete this suspended host?"
          submitText="Yes"
          cancelText="No"
          onSubmitClick={handleDeleteApi}
          onCancelClick={handleDeleteAlertClose}
        /> */}
      </div>
      {suspendedHostList.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          setPerPage={setPerPage}
          perPage={perPage}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};
