import { useContext, useEffect, useState } from "react";
import "./style.css";
import moment from "moment";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import AlertPopUp from "../../AlertPopUp";
import { useNavigate } from "react-router-dom";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import ImagePopUpModal from "../../ImagePopUpModal";
import { useLoader } from "../../../base/Context/loaderProvider";
import SearchInput from "../../SearchInput";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { useApi } from "../../../base/Context/apiProvider";
import { Modal } from "../../../base/Context/modalProvider";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import Button from "../../library/Button";

const RejectedHostTable = () => {
  let navigate = useNavigate();
  const [rejectedHost, setRejectedHost] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [id, setId] = useState();
  const [rejectedReason, setRejectedReason] = useState("");
  const [showImageAlert, setShowImageAlert] = useState(false);
  const [img, setImg] = useState("");
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const modalProvider = useContext(Modal);
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    rejectedHost.map((item,index) => {
      let obj = {
        "S.No":index+1,
        "Host ID": item?._id,
        Name: item?.name,
        "Date Of Birth": item?.dateOfBirth,
        Email: item?.email,
        "Mobile Number": item?.mobileNumber,
        "Rejected At": item?.rejectedDate,
        "Rejected Reason": item?.rejectedReason,
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [rejectedHost]);

  const handleImageAlert = (img) => {
    setShowImageAlert(true);
    setImg(img);
  };

  const handleImageAlertClose = () => {
    setShowImageAlert(false);
  };

  useEffect(() => {
    getRejectedHost();
  }, [value, page, perPage, apiProvider?.apiUrl, endDate]);

  const getRejectedHost = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.REJECTEDHOST,
      "POST",
      {
        id: id,
        rejectedReason: rejectedReason,
        startDate,
        endDate,
        key: value,
        page,
        perPage,
      }
    )
      .then((res) => {
        setRejectedHost(res.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        setRejectedHost([])
        console.log(err);
      });
  };

  const handleRejectedHostDelete = (id) => {
    console.log(id);
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleRejectedHostDeleteClose = () => {
    setShowDeleteAlert(false);
  };

  const handleCancelRejectedHost = () => {
    setShowDeleteAlert(false);
    navigate("/rejectedhost");
  };

  const handleDeleteRejectedHost = (apiProvider) => {
    loader.showLoader(true);

    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DELETEHOST + `/${id}`,
      "DELETE"
    )
      .then((res) => {
        setShowDeleteAlert(false);
        getRejectedHost();
        loader.showLoader(false);
      })
      .catch((err) => {
        console.log(err);
        loader.showLoader(false);
      });
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const handleExportData = () => {
    const filename =`Rejected Host Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;
  
    exportToExcel(exportAllData,filename);
  };

  const handleExportPdf = () => {
    const filename =`Rejected Host Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    exportToPDF(exportAllData,filename);
  };

  const handleExportCsv = () => {
    const filename =`Rejected Host Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="rejected_host_btn">
        <label>Start Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={startDate}
          max={new Date().toISOString().split("T")[0]}
          onChange={handleStartDate}
        ></input>
        <label>End Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
          onChange={handleEndDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={handleExportPdf} />
      </div>

      <div className="rejected__host__container web_table_container">
        <table className="rejected__host__table">
          <thead>
            <th className="rejected__host__header">S.No.</th>
            <th className="rejected__host__header">Host ID</th>
            <th className="rejected__host__header">Name</th>
            <th className="rejected__host__header">Date Of Birth</th>
            <th className="rejected__host__header">Email</th>
            <th className="rejected__host__header">Mobile Number</th>
            <th className="rejected__host__header">Rejected At</th>
            <th className="rejected__host__header">Rejected Reason</th>
            {/* <th className="rejected__host__header">Profile Pic</th>  */}
            {/* <th className="rejected__host__header">Details</th> */}
            <th className="rejected__host__header">Action</th>
          </thead>
          <tbody>
            {rejectedHost?.length > 0
              ? rejectedHost.map((data, index) => {
                  return (
                    <tr>
                      <td className="rejected__host__data">{index + 1}</td>
                      <td className="rejected__host__data">{data?.userId}</td>
                      <td className="rejected__host__data">{data?.name}</td>
                      <td className="rejected__host__data">
                        {data?.dateOfBirth}
                      </td>
                      <td className="rejected__host__data">{data?.email}</td>
                      <td className="rejected__host__data">
                        {data?.mobileNumber}
                      </td>
                      <td className="rejected__host__data">
                        {moment(data?.rejectedDate).format("DD/MM/YYYY LT")}
                      </td>
                      <td className="rejected__host__data">
                        <div
                          className="feedback__table__comment"
                          onClick={
                            data?.rejectedReason?.length > 10
                              ? () =>
                                  modalProvider.handleCommentClick(
                                    data?.rejectedReason,
                                    "Name"
                                  )
                              : () => {}
                          }
                        >
                          {data?.rejectedReason}
                        </div>
                      </td>
                      {/* <td className="rejected__host__data">
                          <AiFillEye
                            className="rejected__host__eye__icon"
                            onClick={() => handleImageAlert(data?.profilePic)}
                          />
                        </td> */}
                      {/* <td className="rejected__host__data">View</td> */}
                      <td className="rejected__host__data">
                        {/* <AiFillEdit className="rejected__host__edit__icon" /> */}
                        <AiFillDelete
                          className="rejected__host__delete__icon"
                          onClick={() => handleRejectedHostDelete(data._id)}
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        {!rejectedHost?.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
      </div>

      <AlertPopUp
        open={showDeleteAlert}
        handleOpen={handleRejectedHostDelete}
        handleClose={handleRejectedHostDeleteClose}
        header="Delete Alert"
        description="Are you sure you want to delete this rejected host?"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleDeleteRejectedHost}
        onCancelClick={handleCancelRejectedHost}
      />

      <ImagePopUpModal
        open={showImageAlert}
        handleClose={handleImageAlertClose}
        img={img}
      />

      {rejectedHost?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
          perPage={perPage}
          setPerPage={setPerPage}
        />
      ) : null}
    </>
  );
};

export default RejectedHostTable;
