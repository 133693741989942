import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import "./style.css";
// import sidebarData from "../../../Constant/DataComponent";
import Button from "../../../components/library/Button";
import InputField from "../../../components/library/InputField";
import ResponsibilitiesDropdown from "../../../components/library/ResponsibilitiesDropdown";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { useApi } from "../../../base/Context/apiProvider";
import { useSidebar } from "../../../base/Context/sidebarProvider";
import { useLoader } from "../../../base/Context/loaderProvider";
import { errorToast, successToast } from "../../../utils/toast";

export default function EditSubAdmin() {
  const [options, setOptions] = useState([]);
  const [responsibilities, setResponsibilities] = useState([]);
  const [Accessablity, setAccessability] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [passInput, setPassInput] = useState(true);
  const [passInputTwo, setPassInputTwo] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const apiProvider = useApi();
  const sidebarProvider = useSidebar();
  const loader = useLoader();

  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    newPasswordError: "",
    confirmPasswordError: "",
  });

  const navigate = useNavigate();

  const handleSubAdmin = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.GETONESUBADMIN +
        `/${searchParams.get("id")}`,
      "GET"
    )
      .then((res) => {
        setResponsibilities(res?.result?.responsibility);
        const filteredAdminType = res?.result?.adminType?.filter(item => item !== null);
        setAccessability(filteredAdminType);
        // setAccessability(res?.result?.adminType)
      })
      .catch((err) => {});
  };

  useEffect(() => {
    handleSubAdmin();
  }, [searchParams]);

  //set side bar menu options for sub admin responsibilities
  useEffect(() => {
    // setAccessability()
    setOptions(
      sidebarProvider?.sidebarContent.map((item) => ({
        name: item.label,
        accessType: [],
      }))
    );
  }, [sidebarProvider?.sidebarContent]);

  const handleAccess = (name, accessType) => {
    let updatedData = [];
    updatedData = responsibilities?.map((item, index) => {
      if (item?.name === name) {
        return {
          name: item.name,
          accessType: item?.accessType
            ? item?.accessType.includes(accessType)
              ? item?.accessType.filter((accessVal) => accessVal != accessType)
              :[
                ...item?.accessType,
                accessType,
                ...(accessType === "edit" && !item?.accessType.includes("view") ? ["view"] : [])
              ]
            : accessType === "edit" ? ["view", accessType] : [accessType],
        };
      } else {
        return item;
      }
    });
    setResponsibilities(updatedData);
  };

  const handleRemove = (e) => {
    let newArray = responsibilities.filter((item) => {
      return item.name !== e.name;
    });

    setResponsibilities(newArray);
  };

  //handle Edit
  const handleEdit = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.EDITSUBADMIN,
      "PUT",
      {
        id: searchParams.get("id"),
        responsibility: responsibilities,
        adminType:Accessablity?.map((item)=>{return item?.name})
      }
    )
      .then((res) => {
        // successToast(res?.message);
        navigate(
          searchParams.get("appType") === "catchwoo"
            ? `/sub-Admin?appType=` + searchParams.get("appType")
            : "/sub-Admin"
        );
      })
      .catch((err) => {
        // errorToast(err?.message);
      });
  };
  const AccessibilityOptions = [
    { name: "Shudhlove" },
    { name: "ShudhloveHost" },
    { name: "Catchwoo" },
  ];
  const handlePassword = (e) => {
    setNewPassword(e.target.value);
    setError({ ...error, newPasswordError: "" });
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setError({ ...error, confirmPasswordError: "" });
  };

  const handleEye = () => {
    if (passInput) {
      return <FaEyeSlash />;
    } else {
      return <FaEye />;
    }
  };
  const handleType = () => {
    setPassInput(!passInput);
  };

  const handleEyeTwo = () => {
    if (passInputTwo) {
      return <FaEyeSlash />;
    } else {
      return <FaEye />;
    }
  };
  const handleTypeTwo = () => {
    setPassInputTwo(!passInputTwo);
  };

  const validate = () => {
    console.log("jellooo");
    let result = true;
    if (newPassword === "") {
      setError({ ...error, newPasswordError: "Invalid Password" });
      result = false;
    } else if (confirmPassword === "") {
      setError({ ...error, confirmPasswordError: "Password do not match" });
      result = false;
    }
    return result;
  };

  const handleEditPassword = () => {
    if (validate()) {
      console.log("pelo");

      loader.showLoader(true);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.EDITPASSWORD,
        "PUT",
        {
          id: searchParams?.get("id"),
          newPassword,
          confirmPassword,
        }
      )
        .then((res) => {
          // successToast(res?.message);
          loader.showLoader(false);
          navigate("/sub-Admin");
        })
        .catch((err) => {
          loader.showLoader(false);
          errorToast(err?.message);
        });
    }
  };

  return (
    <div className="edit_subadmin_bg">
      <div className="edit_subadmin_col">
        <div>
          <ResponsibilitiesDropdown
            options={AccessibilityOptions}
            responsibilities={Accessablity}
            setResponsibilities={setAccessability}
            error={error.accessbility}
            setText={" Select Accessibility"}
          />
        </div>
        <br /> <br />
        <ResponsibilitiesDropdown
          options={options}
          responsibilities={responsibilities}
          setResponsibilities={setResponsibilities}
           setText={" Select Responsibilities"}
          // error={null}
        />
        {responsibilities?.length ? (
          <>
            <label className="label_style" style={{ display: "block" }}>
              Allowance
            </label>
            <div className="assign_responsibilities_box">
              {responsibilities?.map((item, index) => {
                return (
                  <AllowedItem
                    item={item}
                    key={index}
                    handleAccess={handleAccess}
                    handleRemove={handleRemove}
                  />
                );
              })}
            </div>
          </>
        ) : null}
        <br /> <br />
        <Button
          onClick={handleEdit}
          text="Edit Sub Admin"
          style={{
            border: "none",
            textAlign: "center",
            width:'175px',
            padding:'5px',
            height:'45px'
          }}
          className="web_button"
        />
      </div>

      <div className="edit_subadmin_col">
        <div className="edit_subadmin">
          <label className="edit_subadmin_label" style={{ width: "100px" }}>
            Password
          </label>
          <InputField
            placeholder="Password"
            type={passInput ? "password" : "text"}
            onChange={handlePassword}
            error={error.newPasswordError}
            value={newPassword}
            icon={handleEye()}
            onEyeClick={handleType}
          />
        </div>
        <br />

        <div className="edit_subadmin">
          <label className="edit_subadmin_label" style={{ width: "100px" }}>
            Confirm Password
          </label>
          <InputField
            placeholder="Confirm Password"
            type={passInputTwo ? "password" : "text"}
            onChange={handleConfirmPassword}
            error={error.confirmPasswordError}
            value={confirmPassword}
            icon={handleEyeTwo()}
            onEyeClick={handleTypeTwo}
          />
        </div>
        <br />
        <br />

        <Button
          onClick={handleEditPassword}
          text="Edit Password"
         
            style={{
              border: "none",
              textAlign: "center",
              width:'175px',
              padding:'5px',
              height:'45px'
            }}
        
          className="web_button"
        />
      </div>
    </div>
  );
}

export const AllowedItem = ({ item, key, handleAccess, handleRemove }) => {
  const handleAssignAccess = (accessType) => {
    handleAccess(item?.name, accessType);
  };

  return (
    <div className="Allowance_div" key={key}>
      {item?.name}
      <div className="allowance_edit_delete_btns">
        <span
          onClick={() => handleAssignAccess("view")}
          className={
            item.accessType.includes("view") ? "active_allow_btn" : null
          }
        >
          View
        </span>
        <span
          onClick={() => handleAssignAccess("edit",)}
          className={
            item.accessType.includes("edit") ? "active_allow_btn" : null
          }
        >
          Edit
        </span>
        <button onClick={() => handleRemove(item)} className="remove_res_btn">
          <IoClose size={18} />
        </button>
      </div>
    </div>
  );
};
