import { useEffect, useState } from "react";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { useParams, useSearchParams } from "react-router-dom";
import { useLoader } from "../../../base/Context/loaderProvider";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { useApi } from "../../../base/Context/apiProvider";
import SearchInput from "../../SearchInput";
import { FiSearch } from "react-icons/fi";
import moment from "moment";

const CallHistory = () => {
  console.log("hereee");
  const [getCallHistory, setGetCallHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [value, setValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const apiProvider = useApi();

  const loader = useLoader();

  const formatVideoCallTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours > 0 ? `${hours < 10 ? "0" : ""}${hours}:` : ""}${
      minutes < 10 ? "0" : ""
    }${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    fetchCallHistory();
    console.log("her");
  }, [page, perPage, apiProvider?.getApiUrl(), value]);

  const fetchCallHistory = () => {
    let appType = searchParams.get("appType") === "catchwoo" ? "catchWoo" : "";
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETUSERCALLHISTORY,
      "POST",
      { id: id, page, perPage, key: value, appType }
    )
      .then((res) => {
        loader.showLoader(false);
        console.log(res);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
        setGetCallHistory(res?.result);
      })
      .catch((err) => {
        loader.showLoader(false);
        console.log(err);
      });
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  return (
    <>
      <SearchInput
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
        value={value}
      />

      <div className="user__call__history__container web_table_container">
        <table className="user__call__history__table">
          <thead>
            <th className="user__call__history__header">S.No.</th>
            <th className="user__call__history__header">Host ID</th>
            <th className="user__call__history__header">Host Name</th>
            <th className="user__call__history__header">Video coin</th>
            <th className="user__call__history__header">Gift Coins</th>
            <th className="user__call__history__header">Mode</th>
            <th className="user__call__history__header">Is Random</th>
            <th className="user__call__history__header">Call Type</th>
            <th className="user__call__history__header">Time Duration</th>
            <th className="user__call__history__header">Created At</th>
          </thead>
          <tbody>
            {getCallHistory && getCallHistory.length > 0
              ? getCallHistory.map((data, index) => {
                  return (
                    <tr>
                      <td className="user__call__history__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="user__call__history__data">
                        {data?.targetId?.userId}
                      </td>
                      <td className="user__call__history__data">
                        {data?.targetId?.name}
                      </td>
                      <td className="user__call__history__data">
                        {data?.videoCoins ? data?.videoCoins : 0}
                      </td>

                      <td className="user__call__history__data">
                        {data?.giftCoin ? data?.giftCoin : 0}
                      </td>
                      <td className="user__call__history__data">
                        {data?.callType}
                      </td>
                      <td className="user__call__history__data">
                        {data?.random ? (
                          <p className="user__random__call">Random</p>
                        ) : (
                          "-"
                        )}
                      </td>
                      <>
                        {data?.calling === "missed/incoming" ? (
                          <td className="user__call__history__data">
                            {" "}
                            {data?.calling.split("/incoming")}
                          </td>
                        ) : (
                          <td className="user__call__history__data">
                            {" "}
                            {data?.calling.split("/outgoing")}
                          </td>
                        )}
                      </>
                      <td className="user__call__history__data">
                        {formatVideoCallTime(
                          data?.total_minute ? data?.total_minute : ""
                        )}
                      </td>
                      <td className="user__call__history__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY , LT")}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        {!getCallHistory?.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
      </div>
      {getCallHistory?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default CallHistory;
