import { useContext, useEffect, useState } from "react";
import "./style.css";
import { fetchDataFromAPI } from "../../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../../network/NetworkConfiguration";
import { useApi } from "../../../../base/Context/apiProvider";
import { Modal } from "../../../../base/Context/modalProvider";
import Button from "../../../library/Button";
import AlertPopUp from "../../../AlertPopUp";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import profile from "../../../../base/Assets/dashboardProfile.png";

const UserDetails = ({ id }) => {
  const [getOneUserDetail, setGetOneUserDetail] = useState({});
  const apiProvider = useApi();
  const modalProvider = useContext(Modal);
  const [showBlockAlert, setShowBlockAlert] = useState(false);
  const [reason, setReason] = useState("");
  const [showReason, setShowReason] = useState(false);
  const [showUnblockAlert, setShowUnblockAlert] = useState(false);
  const [showProfilePopUp, setShowProfilePopUp] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    getUserDetail();
  }, [apiProvider?.getApiUrl()]);

  const getUserDetail = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.FINDONEUSER + `/${id}`,
      "GET"
    )
      .then((res) => {
        setGetOneUserDetail(res?.getOneUser);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBlockReason = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.BLOCKUSER,
      "PUT",
      {
        id,
        blockReasion: reason,
      }
    )
      .then((res) => {
        setShowBlockAlert(false);
        setShowReason(false);

        getUserDetail();
        navigate(
          `${
            searchParams.get("appType")
              ? "/blockdata?appType=" + searchParams.get("appType")
              : "/blockdata"
          }`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBlockUser = () => {
    setShowReason(true);
    setShowBlockAlert(false);
  };

  const handleReason = (e) => {
    setReason(e.target.value);
  };

  const handleBlockUserClose = () => {
    setShowReason(false);
  };

  const handleBlockClick = () => {
    setShowBlockAlert(true);
  };

  const handleBlockClickClose = () => {
    setShowBlockAlert(false);
  };

  const handleUnblockUser = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.UNBLOCKUSER,
      "PUT",
      {
        id,
      }
    )
      .then((res) => {
        setShowUnblockAlert(false);
        getUserDetail();
      })
      .catch((err) => {
        console.log(err);
        setShowUnblockAlert(false);
      });
  };

  const handleUnblockClick = () => {
    setShowUnblockAlert(true);
    // setId(id);
  };

  const handleUnblockClickClose = () => {
    setShowUnblockAlert(false);
  };

  const handleProfilePic = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.REMOVEUSERPROFILE,
      "PUT",
      { id: id }
    )
      .then((res) => {
        console.log(res);
        getUserDetail();
        setShowProfilePopUp(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleProfileOpen = () => {
    setShowProfilePopUp(true);
  };

  const handleProfileOpenClose = () => {
    setShowProfilePopUp(false);
  };

  return (
    <>
      <div style={{ position: "absolute", top: 5, right: 0 }}>
        {getOneUserDetail?.isBlock ? (
          <Button
            style={{
              backgroundColor: "#fe3b3b",
              width: "8rem",
              fontSize: "18px",
              textAlign: "center",
            }}
            text="Unblock User"
            onClick={handleUnblockClick}
          />
        ) : (
          <Button
            style={{
              backgroundColor: "#fe3b3b",
              width: "8rem",
              fontSize: "18px",
              textAlign: "center",
            }}
            text="Block User"
            onClick={handleBlockClick}
          />
        )}
      </div>
      <div className="userdetails_main_container">
        {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          height: "3rem",
          // position: "absolute",
          // top: "10px",
          // right: "10px",
        }}
      > */}

        {/* </div> */}
        {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      > */}
        <div className="user__management__profile">
          {/* {getOneUserDetail?.profilePic ? (
            <div className="user_management_icon" onClick={handleProfileOpen}>
              <AiFillEdit style={{ width: "18px", height: "18px" }} />
            </div>
          ) : null} */}
          <div className="image_profile_container"  onClick={handleProfileOpen}>
            <img
              className="user__management__profile_pic"
              src={
                getOneUserDetail?.profilePic
                  ? getOneUserDetail?.profilePic
                  : profile
              }
              alt=""
            />
          </div>
        </div>
        <div className="user__management__profile_name_container">
          <div className="user__management__profile__name">
            <div>
              <p>{getOneUserDetail?.name && getOneUserDetail?.name}</p>
              <p>user</p>
            </div>

            <p>Details</p>
          </div>
        </div>
        <div className="user__details__container ">
          <div className="user__details__row">
            <div className="user__details__table">
              <div className="user__table__data">
                <div>Username :</div>

                <div
                  className=""
                  onClick={
                    getOneUserDetail?.name?.length > 12
                      ? () =>
                          modalProvider.handleCommentClick(
                            getOneUserDetail?.name,
                            "Name"
                          )
                      : () => {}
                  }
                >
                  {getOneUserDetail?.name}
                </div>
              </div>
              <div className="user__table__data">
                <div>Gender :</div>
                <div>{getOneUserDetail?.gender}</div>
              </div>
              <div className="user__table__data">
                <div>Email : </div>
                <div
                  className=""
                  onClick={
                    getOneUserDetail?.email?.length > 12
                      ? () =>
                          modalProvider.handleCommentClick(
                            getOneUserDetail?.email,
                            "Email"
                          )
                      : () => {}
                  }
                >
                  {getOneUserDetail?.email}
                </div>
              </div>
              <div className="user__table__data">
                <div>Status :</div>
                {getOneUserDetail?.acctiveStatus &&
                  getOneUserDetail?.acctiveStatus}
              </div>
              <div className="user__table__data">
                <div>Role :</div>
                User
              </div>
              <div className="user__table__data">
                <div>Contact :</div>
                <div>{getOneUserDetail?.mobileNumber}</div>
              </div>
              {/* <div className="user__table__data">
                <div>Date Of Birth :</div>
                <div>{getOneUserDetail?.dateOfBirth}</div>
              </div> */}

              <div className="user__table__data">
                <div>Pin Code :</div>
                <div>{getOneUserDetail?.pinCode}</div>
              </div>
              <div className="user__table__data">
                <div>City :</div>
                <div>{getOneUserDetail?.city}</div>
              </div>
              <div className="user__table__data">
                <div>State :</div>
                <div>{getOneUserDetail?.state}</div>
              </div>

              <div className="user__table__data">
                <div>Country :</div>
                <div
                  className=""
                  onClick={
                    getOneUserDetail?.country?.length > 12
                      ? () =>
                          modalProvider.handleCommentClick(
                            getOneUserDetail?.country,
                            "Name"
                          )
                      : () => {}
                  }
                >
                  {getOneUserDetail?.country}
                </div>
              </div>

              <div className="edit_suspend_button_container">
                <div className="edit_suspend_edit">Edit</div>
                <div className="edit_suspend_suspend">suspend</div>
              </div>
            </div>
          </div>
          <AlertPopUp
            open={showBlockAlert}
            handleClose={handleBlockClickClose}
            handleOpen={handleBlockClick}
            header="Block Alert"
            description="Are you sure you want to block this user?"
            submitText="Yes"
            cancelText="No"
            onSubmitClick={handleBlockUser}
            onCancelClick={handleBlockClickClose}
          />

          <AlertPopUp
            onChangeField={handleReason}
            handleClose={handleBlockUserClose}
            textField={true}
            open={showReason}
            header="Reason for blocking this profile"
            placeholder="Type your reason here..."
            submitText="Okay"
            cancelText="Cancel"
            onSubmitClick={handleBlockReason}
            onCancelClick={handleBlockUserClose}
          />

          <AlertPopUp
            open={showUnblockAlert}
            handleClose={handleUnblockClickClose}
            handleOpen={handleUnblockClick}
            header="Unblock Alert"
            description="Are you sure you want to unblock this user?"
            submitText="Yes"
            cancelText="No"
            onSubmitClick={handleUnblockUser}
            onCancelClick={handleUnblockClickClose}
          />

          <AlertPopUp
            open={showProfilePopUp}
            handleOpen={handleProfileOpen}
            handleClose={handleProfileOpenClose}
            description="Are you sure you want to remove this profile pic?"
            header="Delete Profile Pic"
            onSubmitClick={handleProfilePic}
            onCancelClick={handleProfileOpenClose}
            submitText="Yes"
            cancelText="No"
          />
        </div>
      </div>
    </>
  );
};

export default UserDetails;
