import { useEffect, useState } from "react";
import "./style.css";
import { useApi } from "../../../base/Context/apiProvider";
import { useLoader } from "../../../base/Context/loaderProvider";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { Box, Modal } from "@mui/material";
import moment from "moment";
import { AiFillEye } from "react-icons/ai";
import ImagePopUpModal from "../../ImagePopUpModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "50%",
  border: "none",
  outline: "none",
  borderRadius: "10px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
  overflow: "auto",
};

const UserMomentTable = ({ open, handleClose, id }) => {
  const [getUserMoment, setGetUserMoment] = useState();
  const [showPostImage, setShowPostImage] = useState(false);
  const [img, setImg] = useState("");
  const apiProvider = useApi();
  const loader = useLoader();

  useEffect(() => {
    fetchPostDetails(id);
  }, [apiProvider?.getApiUrl(), id]);

  const fetchPostDetails = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.POSTDETAILS + `/${id}`,
      "GET"
    )
      .then((res) => {
        console.log(res);
        loader.showLoader(false);
        setGetUserMoment(res?.result);
      })
      .catch((err) => {
        console.log(err);
        loader.showLoader(false);
      });
  };

  const handlePostImage = (img) => {
    setImg(img);
    setShowPostImage(true);
  };

  const handlePostDetailsClose = () => {
    setShowPostImage(false);
  };
  // console.log(likedBy,'likedBY')

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className=" user_moment_container " style={{height:'100% !important'}}>
            <table className="user_moment_table">
              <thead>
                <th className="user_moment_header">Report ID</th>

                <th className="user_moment_header">Post Date</th>
                <th className="user_moment_header">Post Image</th>
                <th className="user_moment_header">Post Caption</th>
                <th className="user_moment_header">Hashtag</th>
                <th className="user_moment_header">Likes</th>
                <th className="user_moment_header">Post Type</th>

                <th className="user_moment_header">Reason</th>
                <th className="user_moment_header">Created At</th>
              </thead>
              <tbody>
                <td className="user_moment_data">
                  {getUserMoment?.reportId ? getUserMoment?.reportId : "-"}
                </td>
                <td className="user_moment_data">
                  {moment(
                    getUserMoment?.targetId?.postDate
                      ? getUserMoment?.targetId?.postDate
                      : "-"
                  ).format("DD/MM/YYYY , LT")}
                </td>
                <td className="user_moment_data">
                  {getUserMoment?.targetId?.postImage ? (
                    <AiFillEye
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handlePostImage(getUserMoment?.targetId?.postImage)
                      }
                    />
                  ) : (
                    "-"
                  )}
                </td>
                <td className="user_moment_data">
                  {getUserMoment?.comment ? getUserMoment?.comment : "-"}
                </td>
                <td className="user_moment_data">
                  {getUserMoment?.targetId?.hashTag
                    ? getUserMoment?.targetId?.hashTag
                    : "-"}
                </td>
                <td className="user_moment_data">
                  {getUserMoment?.targetId?.likes
                    ? getUserMoment?.targetId?.likes
                    : "-"}
                </td>
                <td className="user_moment_data">
                  {getUserMoment?.targetId?.postTypes
                    ? getUserMoment?.targetId?.postTypes
                    : "-"}
                </td>

                <td className="user_moment_data">
                  {getUserMoment?.Choose_the_Reason}
                </td>
                <td className="user_moment_data">
                  {moment(getUserMoment?.createdAt).format("DD/MM/YYYY , LT")}
                </td>
              </tbody>
            </table>
          </div>
        </Box>
      </Modal>

      <ImagePopUpModal
        open={showPostImage}
        handleClose={handlePostDetailsClose}
        img={img}
      />
    </>
  );
};

export default UserMomentTable;
