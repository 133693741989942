import React, { useEffect, useState } from "react";

import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import SecondaryButton from "../library/SecondaryButton";
import moment from "moment";
import { useApi } from "../../base/Context/apiProvider";
// import { render } from "react-dom";
import "./style.css";
import ReactApexChart from "react-apexcharts";
import {
  CurrWeekArray,
  OddMonthDateArray,
  YearArray,
  month,
} from "../../utils/utility";
import { useSearchParams } from "react-router-dom";

export default function DashboardChart() {
  ////////////////////////////////
  const [labels, setLabels] = useState([]);
  const [earnings, setEarnings] = useState([]);
  const [purchase, setPurchase] = useState([]);
  const [month, setMonth] = useState(true);
  const [week, setWeek] = useState(false);
  const [year, setYear] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [selectedValue, setSelectedValue] = useState("Month");
  const [series, setSeries] = useState([]);
  const [searchParams]=useSearchParams()
  const [option, setOption] = useState({
    options: {
      chart: {
        height: "90%",
        width:'80%',
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "string",
        categories: month,
      },
      // tooltip: {
      //   x: {
      //     format: "DD/MM/YYYY ",
      //   },
      // },
    },
  });

  const apiProvider = useApi();

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };
  const handleHostEarning = () => {
    let hostEarningPayload = {
      startDate: moment(startDate).format("DD-MM-YYYY"),
      endDate: moment(endDate).format("DD-MM-YYYY"),
      month,
      year,
      week,
    };

    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.TOTALHOST,
      "POST",
      hostEarningPayload
    )
      .then((res) => {
        let result = Object.values(res.result);
        if (year) {
          result = result.reverse();
        }
        const newSeries = [{ name: "Host", data: result }];
        setEarnings(res.result);
        setSeries((prevSeries) => [...prevSeries, ...newSeries]);
      })
      .catch((err) => {
        setEarnings([]);
      });
  };

  const handleAllUsers = () => {
    const graphPayload = {
      startDate: moment(startDate).format("DD-MM-YYYY"),
      endDate: moment(endDate).format("DD-MM-YYYY"),
      month,
      year,
      week,
    };
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.TOTALUSER,
      "POST",
      graphPayload
    )
      .then((res) => {
        let result = Object.values(res.result);
        if (year) {
          result = result.reverse();
        }

        const newSeries = [{ name: "User", data: result }];
        setPurchase(res?.result);
        setSeries((prevSeries) => [...prevSeries, ...newSeries]);
      })
      .catch((err) => {
        setPurchase([]);
      });
  };

  useEffect(() => {
    if (endDate) {
      setMonth(false);
      setYear(false);
      setWeek(false);
    }
    if(searchParams.get('appType')==='host'){

      handleHostEarning();
    }
    handleAllUsers();
    setSeries([]);
  }, [month, year, week, endDate]);

  const handleMonthClick = () => {
    setMonth(true);
    setYear(false);
    setWeek(false);
    setOption({
      ...option,
      xaxis: {
        categories: month,
      },
    });
  };

  const handleYearClick = () => {
    const categories = [];
    YearArray.forEach((month) => {
      categories.push(month.name);
    });
    setOption({
      ...option,
      xaxis: {
        categories: categories,
      },
    });
    setYear(true);
    setWeek(false);
    setMonth(false);
  };
  const handleWeekClick = () => {
    setWeek(true);
    setYear(false);
    setMonth(false);
    setOption({
      ...option,
      xaxis: {
        categories: CurrWeekArray,
      },
    });
  };

  useEffect(() => {
    switch (selectedValue) {
      case "Year":
        setLabels(YearArray);

        break;
      case "Month":
        setLabels(OddMonthDateArray);
        break;
      case "Week":
        setLabels(CurrWeekArray);
        break;
      default: {
      }
    }
  }, [selectedValue]);

  return (
    <>
      <div className="dashboard_graph_btns">
        <p className="dashboard__date">Start Date</p>
        <input
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        />

        {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
        <p className="dashboard__date">End Date</p>

        <input
          type="date"
          value={endDate}
          onChange={handleEndDate}
          min={startDate}
          // disabled={checkStartDate}
          max={new Date().toISOString().split("T")[0]}
        />
        {/* </div> */}
        <SecondaryButton
          style={{ cursor: "pointer" }}
          onClick={handleYearClick}
          text="Year"
          isActive={year}
        />
        <SecondaryButton
          style={{ cursor: "pointer" }}
          onClick={handleMonthClick}
          text="Month"
          isActive={month}
        />
        <SecondaryButton
          style={{ cursor: "pointer" }}
          onClick={handleWeekClick}
          text="Week"
          isActive={week}
        />
      </div>

      <div id="chart" style={{ width:'90%'}}>
        <ReactApexChart
          style={{ width: "100%", height:"90%", minHeight:'90%'}}
          options={option}
          series={series}
          type="line"
          // height={350}
        />
      </div>
    </>
  );
}
