import { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { useParams } from "react-router-dom";
import "./style.css";
import { useLoader } from "../../../base/Context/loaderProvider";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import Pagination from "../../Pagination";
import { useApi } from "../../../base/Context/apiProvider";
import Button from "../../library/Button";
import SearchInput from "../../SearchInput";
import { FiSearch } from "react-icons/fi";
import moment from "moment";

const PaymentHistoryTable = () => {
  const [getPaymentHistory, setGetPaymentHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // const [value, setValue] = useState("");
  const { id } = useParams();

  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    fetchPaymentHistory();
  }, [page, perPage, apiProvider?.getApiUrl(), endDate]);

  const fetchPaymentHistory = () => {
    loader.showLoader(true);

    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETPAYMENTHISTORY,
      "POST",
      {
        // key: value,
        startDate,
        endDate,
        id: id,
        page,
        perPage,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
        setGetPaymentHistory(res?.result);
      })
      .catch((err) => {
        console.log(err);
        loader.showLoader(false);
        setGetPaymentHistory([]);
      });
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  // const handleText = (e) => {
  //   setValue(e.target.value);
  // };

  // const searchIcon = () => {
  //   return <FiSearch />;
  // };
  return (
    <>
      {/* <SearchInput
        onChange={handleText}
        value={value}
        icon={searchIcon()}
        placeholder="Search"
      /> */}
      <div className="accepted_host_btn">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          onChange={handleEndDate}
        ></input>
        <Button text="Export Data" />
      </div>
      <div className="payment__history__container web_table_container">
        <table className="payment__history__table">
          <thead>
            <th className="payment__history__header">S.No.</th>
            <th className="payment__history__header">Transaction ID</th>
            <th className="payment__history__header">Coins</th>
            <th className="payment__history__header">Price</th>
            <th className="payment__history__header">Mode</th>
            <th className="payment__history__header">Mobile Model</th>
            <th className="payment__history__header">Mobile </th>
            <th className="payment__history__header">Payment Through</th>
            <th className="payment__history__header">Payment Status</th>
            <th className="payment__history__header">Payment Date</th>
            {/* <th className="payment__history__header">Status</th> */}
          </thead>
          <tbody>
            {getPaymentHistory &&
            getPaymentHistory?.length &&
            getPaymentHistory?.length > 0
              ? getPaymentHistory.map((data, index) => {
                  return (
                    <tr>
                      <td className="payment__history__data">{index + 1}</td>
                      <td className="payment__history__data">
                        {data?.transactionId}
                      </td>
                      <td className="payment__history__data">{data?.coins}</td>
                      <td className="payment__history__data">{data?.price}</td>
                      <td className="payment__history__data">
                        {data?.PaymentMethod}
                      </td>
                      <td className="payment__history__data">
                        {data?.mobileModel}
                      </td>
                      <td className="payment__history__data">
                        {data?.mobileName}
                      </td>

                      <td className="payment__history__data">
                        {data?.paymentThrough}
                      </td>
                      <td className="payment__history__data">
                        {data?.paymentStatus}
                      </td>
                      <td className="payment__history__data">
                        {moment(data?.paymentDate).format("DD/MM/YYYY , LT")}
                      </td>
                      {/* <td className="payment__history__data">{data?.status}</td> */}
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        {!getPaymentHistory
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="payment__no__data">No Data Found</p>
              </div>
            )
          : null}
      </div>

      {getPaymentHistory &&
      getPaymentHistory?.length &&
      getPaymentHistory?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default PaymentHistoryTable;
