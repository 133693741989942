import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { RiDownloadCloudFill } from "react-icons/ri";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../../base/Context/apiProvider";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { useLoader } from "../../../base/Context/loaderProvider";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { FaUserTie } from "react-icons/fa";
import "./style.css";

export default function MailDetail() {
  const date = new Date();
  const [searchParams, setSearchParams] = useSearchParams();
  const [files, setFiles] = useState();
  const [names, setNames] = useState([]);
  const [links, setLinks] = useState([]);
  const [mailData, setMailData] = useState({});
  const app = useLoader();
  const apiProvider = useApi();
  const navigate = useNavigate();

  const handleMailById = () => {
    app.showLoader(true);
      fetchDataFromAPI(apiProvider?.getApiUrl() + NetworkConfiguration.GETMAILBYID + `/${searchParams.get("id")}`, "GET")
      .then((res) => {
        console.log('res',res)
        app.showLoader(false);
        setMailData(res.email);
        setNames(res.email?.fileNames);
        setLinks(res.email?.imageUrls);
      })
      .catch((err) => {
        app.showLoader(false);
        console.log(err)
      });
  };

  useEffect(() => {
    handleMailById();
  }, []);

  useEffect(() => {
    let arr = names?.map((img, index) => {
      return { name: img, link: links[index] };
    });
    setFiles(arr);
  }, [names, links]);


  return (
    <div className="mail_detail_bg">
      <div className="mail_detail_box">
        <div className="mail_detail_row">
          <p className="mail_subject">{mailData?.subject}</p>
          <p className="mail_date"></p>
        </div>

        <div className="mail_detail_mails_box">
          <i><FaUserTie className="mail_detail_icon"/></i>
          {/* <img src={profile} alt="user" /> */}
          <div className="user_mails">
            <table>
              <tr>
                <td>To : </td>
                <td>
                  {mailData?.emails?.map((item) => {
                    return item + " , ";
                  })}
                </td>
              </tr>
              <tr>
                <td>Date : </td>
                <td>
                  {moment(mailData?.createdAt).format("Do MMM YYYY hh:mm a")}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <p className="mail_message">{mailData?.message}</p>

        <div className="mail_file_row">
          {files?.map((item, index) => {
            return (
              <Link to={item?.link} download target="_blank">
                <div className="send_mail_file_item" key={index}>
                  <p>{item?.name}</p>

                  <RiDownloadCloudFill color="#616161" size={17} />
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
