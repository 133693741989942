import { BsFillEyeFill } from "react-icons/bs";
import "./style.css";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import Button from "../../library/Button";
import FormAlertPopUp from "../../FormAlertPopUp";
import { useEffect, useState } from "react";
import AddGiftForm from "../../formComponents/AddGiftForm";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import moment from "moment";
import AlertPopUp from "../../AlertPopUp";
import ImagePopUpModal from "../../ImagePopUpModal";
import { errorToast, successToast } from "../../../utils/toast";
import { useLoader } from "../../../base/Context/loaderProvider";
import { FiSearch } from "react-icons/fi";
import SearchInput from "../../SearchInput";
import noData from "../../../base/Animation/No Data Found.json";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import { useApi } from "../../../base/Context/apiProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import jsPDF from "jspdf";

const GiftTable = () => {
  const [showGiftForm, setShowGiftForm] = useState(false);
  const [getGift, setGetGift] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showEditAlert, setShowEditAlert] = useState(false);
  const [showImageAlert, setShowImageAlert] = useState(false);
  const [img, setImg] = useState("");
  const [id, setId] = useState("");
  const [editedGift, setEditedGift] = useState({
    giftId: id,
    giftName: "",
    giftPrice: "",
    giftImage: "",
  });

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [value, setValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const navigate = useNavigate();
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    getGift?.map((item, index) => {
      let obj = {
        "S No.": index + 1,
        "GifT Id": item?.giftId,
        "Gift Name": item?.name,
        "Gift Image": item?.giftUrl,
        // "Gift Price": item?.price,
        "Created At": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
        "Updated At": moment(item?.updatedAt).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [getGift]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Gift";
    const headers = [
      [
        "S.No.",
        "Gift Id",
        "Gift Name",
        "Gift Image",
        // "Gift Price",
        "Created At",
        "Updated At",
      ],
    ];

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["GifT Id"],
      item1["Gift Name"],
      item1["Gift Image"],
      // item1["Gift Price"],
      item1["Created At"],
      item1["Updated At"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    const filename =`Gift Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.save(filename);
  };

  const handleOnClickAlert = (img) => {
    setShowImageAlert(true);
    setImg(img);
  };

  const handleOnClickAlertClose = () => {
    setShowImageAlert(false);
  };

  const handleDeleteAlert = () => {
    setShowDeleteAlert(true);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const handleAddGift = () => {
    setShowGiftForm(true);
  };

  const handleAddGiftClose = () => {
    fetchGift();
    setShowGiftForm(false);
  };

  const handleOnClickDelete = (id) => {
    setId(id);
    setShowDeleteAlert(true);
  };

  useEffect(() => {
    fetchGift();
  }, [value, page, perPage, apiProvider?.getApiUrl(), endDate]);

  const fetchGift = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETGIFT,
      "POST",
      {
        startDate,
        endDate,
        key: value,
        page,
        perPage,
      },
      { appType: searchParams.get("appType") === "catchWoo" ? "catchWoo" : "" }
    )
      .then((res) => {
        loader.showLoader(false);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
        setGetGift(res.result);
      })

      .catch((err) => {
        loader.showLoader(false);
        setGetGift([]);
      });
  };

  const handleDeleteApi = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DELETEGIFT + `/${id}`,
      "DELETE"
    )
      .then((res) => {
        loader.showLoader(false);
        setShowDeleteAlert(false);
        fetchGift();
      })
      .catch((err) => {
        loader.showLoader(false);
        errorToast(err.message);
      });
  };

  const handleOnClickEdit = (id, gift) => {
    setShowEditAlert(true);
    setEditedGift({
      giftId: id,
      giftName: gift?.name,
      giftPrice: gift?.price,
      giftImage: gift?.giftUrl,
    });
    setId(id);
  };

  const handleOnClickEditClose = () => {
    setShowEditAlert(false);
  };

  const onClickEdit = () => {
    setShowEditAlert(false);
    fetchGift();
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportCsv = () => {
    const filename =`Gift Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handleExportData = () => {
    const filename =`Gift Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      {/* <div> */}
      <div className="add__gift">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          min={startDate}
          max={new Date().toISOString().split("T")[0]}
          value={endDate}
          onChange={handleEndDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />

        <Button text="Add Gift" onClick={handleAddGift} />
      </div>

      <div className="gift__container web_table_container">
        <table className="gift__table__container">
          <thead>
            <th className="gift__table__heading">S.No</th>
            <th className="gift__table__heading">Gift ID</th>

            <th className="gift__table__heading">Gift Name</th>
            <th className="gift__table__heading">Gift Image</th>
            {searchParams.get("appType") === "host" ? (
              ""
            ) : (
              <th className="gift__table__heading">Gift Price</th>
            )}
            <th className="gift__table__heading">No.of Shares</th>

            <th className="gift__table__heading">Created At</th>
            <th className="gift__table__heading">Updated At</th>
            <th className="gift__table__heading">Action</th>
          </thead>

          <tbody>
            {getGift && getGift?.length && getGift?.length > 0
              ? getGift.map((data, index) => {
                  return (
                    <tr>
                      <td className="gift__table__body">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="gift__table__body">{data?.giftId}</td>

                      <td className="gift__table__body">{data?.name}</td>
                      <td className="gift__table__body">
                        <BsFillEyeFill
                          className="gift__table__eye__icon"
                          onClick={() => {
                            handleOnClickAlert(data?.giftUrl);
                          }}
                        />
                      </td>
                      {searchParams.get("appType") === "host" ? (
                        ""
                      ) : (
                        <td className="gift__table__body">{data?.price}</td>
                      )}
                      <td
                        className="gift__table__body"
                        onClick={() => navigate(`/shareHistory/${data?._id}`)}
                      >
                        {data?.count}
                      </td>

                      <td className="gift__table__body">
                        {moment(data?.createdAt).format("DD/MM/YYYY LT")}
                      </td>
                      <td className="gift__table__body">
                        {moment(data?.updatedAt).format("DD/MM/YYYY LT")}
                      </td>
                      <td className="gift__table__body ">
                        <AiFillEdit
                          onClick={() => {
                            handleOnClickEdit(data?._id, data);
                          }}
                          className="gift__table__edit__icon"
                        />
                        <AiFillDelete
                          onClick={() => {
                            handleOnClickDelete(data?._id);
                          }}
                          className="gift__table__delete__icon"
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        {!getGift
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
        {/* </div> */}

        <FormAlertPopUp open={showGiftForm} onRequestClose={handleAddGiftClose}>
          <AddGiftForm onSubmit={handleAddGiftClose} />
        </FormAlertPopUp>

        <AlertPopUp
          open={showDeleteAlert}
          handleOpen={handleDeleteAlert}
          handleClose={handleDeleteAlertClose}
          header="Delete Alert"
          description="Are you sure you want to delete this gift?"
          submitText="Yes"
          cancelText="No"
          onSubmitClick={handleDeleteApi}
          onCancelClick={handleDeleteAlertClose}
        />
        <ImagePopUpModal
          open={showImageAlert}
          handleClose={handleOnClickAlertClose}
          img={img}
        />
        <FormAlertPopUp
          open={showEditAlert}
          onRequestClose={handleOnClickEditClose}
        >
          <AddGiftForm
            edit={true}
            editedGift={editedGift}
            onClickEdit={onClickEdit}
          />
        </FormAlertPopUp>
      </div>
      {getGift && getGift?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={setPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default GiftTable;
