import React, { useEffect, useState } from "react";
import "./Assis.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import LoaderProvider, { useLoader } from "../../base/Context/loaderProvider";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
const Assist = () => {
  const loader = useLoader();
  const { id } = useParams();
  const apiProvider = useApi();
  const [data, setData] = useState([]);
  const [isHost, setIsHost] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (searchParams.get("appType") === "host") {
      setIsHost(true);
    } else {
      setIsHost(false);
    }
  });
  useEffect(() => {
    if (isHost) {
      setUrl(NetworkConfiguration.GETHOSTFEEDBACK);
    } else {
      setUrl(NetworkConfiguration.GETUSERFEEDBACK);
    }
  }, [isHost, searchParams]);

  const getAllUsersFeedback = () => {
    loader.showLoader(true);
    fetchDataFromAPI(apiProvider?.getApiUrl() + url, "POST", { userId: id })
      .then((res) => {
        loader.showLoader(false);
        setData(res.result);
      })
      .catch((err) => {
        setData([]);
        loader.showLoader(false);
      });
  };
  useEffect(() => {
    getAllUsersFeedback();
  }, [url]);

  //   return <>{isHost ? <HostFeedbackTable /> : <FeedbackUserTable />}</>;
  // };

  // export default UserFeedback;

  const navigate = useNavigate();

  return (
    <div className="assit_container">
      {data?.map((item, index) => (
        <div
          className="assist_chat"
          onClick={() => {
            navigate(`/chat/${item._id}`, {
              state: { userId: isHost ? item?.hostId?._id : item?.userId?._id },
            });
          }}
        >
          <div className="chat_head">
            <div>{`${isHost ? item?.hostFeedbackId : item.userId?.name}`}</div>
            <div>
              {`${isHost ? item?.hostFeedbackId : item.userFeedbackId}`}
            </div>
          </div>
          <div className="chat_description">{`${isHost ? item?.comment : item.comment}`}</div>
        </div>
      ))}
    </div>
  );
};

export default Assist;
