import TopTalentTable from "../../components/Table/TopTalentTable";
import Dropdown from "../../components/library/Dropdown";
import "./style.css";
import { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import { FiSearch } from "react-icons/fi";
import SearchInput from "../../components/SearchInput";
import Pagination from "../../components/Pagination";
import Lottie from "react-lottie";
import { useLoader } from "../../base/Context/loaderProvider";
import noData from "../../base/Animation/No Data Found.json";
import { useApi } from "../../base/Context/apiProvider";
import Button from "../../components/library/Button";
import {
  exportToExcel,
  exportToPDF,
  exportToCSV,
} from "react-easy-export/dist/react-easy-export";
import jsPDF from "jspdf";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

const TopGrowing = () => {
  const [getSetValue, setGetSetValue] = useState("Top Talent");
  const [tableData, setTableData] = useState([]);
  const [isHost, setIsHost] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [value, setValue] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    let obj;
    tableData.map((item, index) => {
      if (
        getSetValue === "Top Star" ||
        getSetValue === "Weekly Star" ||
        getSetValue === "New Star"
      ) {
        obj = {
          "S.No": index + 1,

          "Rank No.": index + 1,
          "User ID": item?.userId,
          "User Name": item?.name,
          "Sticker Coins": item?.stickerCoins,
          "Gift Coins": item?.giftCoins,
          "Video Call Coins": item?.videoCoins,
          "Audio Call Coins": item?.audioCoins,
          "Post Coins": item?.hostPostCoins,
          "Call Gift Coins": item?.callCoinsGift,
          "Total Spent": item?.host_balance,
          // "Spent coins": item?.spentCoins ? item?.spentCoins : 0,
        };
      }
      if (getSetValue === "Top Talent" || getSetValue === "Weekly Talent") {
        obj = {
          "S.No": index + 1,

          "Rank No.": index + 1,
          "User ID": item?.userId,
          "User Name": item?.name,
          "Sticker Coins": item?.stickerCoins ? item?.stickerCoins : 0,
          "Gift Coins": item?.giftCoins ? item?.giftCoins : 0,
          "Video Call Coins": item?.videoCoins ? item?.videoCoins : 0,
          "Rose COins": item?.roseCoins ? item?.roseCoins : 0,
          "Audio Call Coins": item?.audioCoins ? item?.audioCoins : 0,
          "Post Coins": item?.hostPostCoins ? item?.hostPostCoins : 0,
          "Call Gift Coins": item?.callCoinsGift ? item?.callCoinsGift : 0,
          "Total Spent": item?.spentCoins ? item?.spentCoins : 0,
        };
      }

      array.push(obj);
    });
    setExportAllData(array);
  }, [tableData]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Top Growing Report";
    const headers = [
      [
        "S.No",
        "Rank No.",
        "User ID",
        "User Name",
        "Sticker Coins",
        "Gift Coin",
        "Video Call Coins",
        "Audio Call Coins",
        "Post Coins",
        "Call Gift Coins",
        "Total Spent",
      ],
    ];

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Rank No."],
      item1["User ID"],
      item1["User Name"],
      item1["Sticker Coins"],
      item1["Gift Coins"],
      item1["Video Call Coins"],
      item1["Audio Call Coins"],
      item1["Post Coins"],
      item1["Call Gift Coins"],
      item1["Total Spent"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Top Growing Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  useEffect(() => {
    switch (getSetValue) {
      case "Weekly Talent": {
        const appType =
          searchParams.get("appType") === "host" ? "host" : "user";
        fetchDataFromAPI(
          apiProvider?.getApiUrl() + NetworkConfiguration.WEEKLYTALENT,
          "POST",
          {
            appType,
            startDate,
            endDate,
            key: value,
            page,
            perPage,
          }
        )
          .then((res) => {
            setTableData(res.result);
            setTotalCount(res?.totalCount);
            setTotalPages(res?.totalPages);
            setIsHost(false);
          })
          .catch((err) => {
            setTableData([]);
          });
        break;
      }

      case "Top Star": {
        fetchDataFromAPI(
          apiProvider?.getApiUrl() + NetworkConfiguration.TOPSTAR,
          "POST",
          {
            startDate,
            endDate,
            key: value,
            page,
            perPage,
          }
        )
          .then((res) => {
            setTableData(res.hostUsers);
            setTotalCount(res.totalCount);
            setTotalPages(res.totalPages);
            setIsHost(true);
          })
          .catch((err) => {
            setTableData([]);
          });
        break;
      }

      case "Weekly Star": {
        fetchDataFromAPI(
          apiProvider?.getApiUrl() + NetworkConfiguration.WEEKLYSTAR,
          "POST",
          {
            startDate,
            endDate,
            key: value,
            page,
            perPage,
          }
        )
          .then((res) => {
            setTableData(res.hostUsers);
            setTotalCount(res.totalCount);
            setTotalPages(res.totalPages);
            setIsHost(true);
          })
          .catch((err) => {
            setTableData([]);
          });
        break;
      }

      case "New Star": {
        fetchDataFromAPI(
          apiProvider?.getApiUrl() + NetworkConfiguration.NEWSTAR,
          "POST",
          {
            startDate,
            endDate,
            key: value,
            page,
            perPage,
          }
        )
          .then((res) => {
            setTableData(res.result);
            setTotalCount(res.totalCount);
            setTotalPages(res.totalPages);
            setIsHost(true);
          })
          .catch((err) => {
            setTableData([]);
          });
        break;
      }

      default: {
        const appType =
          searchParams.get("appType") === "host" ? "host" : "user";
        fetchDataFromAPI(
          apiProvider?.getApiUrl() + NetworkConfiguration.GETTOPTALENT,
          "POST",
          {
            appType,
            startDate,
            endDate,
            page,
            perPage,
            key: value,
          }
        )
          .then((res) => {
            setTableData(res.result);
            setIsHost(false);
            setTotalCount(res?.totalCount);
            setTotalPages(res?.totalPages);
          })
          .catch((err) => {
            setTableData([]);
          });
      }
    }
  }, [getSetValue, page, perPage, apiProvider?.getApiUrl(), endDate, value]);

  const onChangeDropdown = (e) => {
    setGetSetValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };
  const handleText = (e) => {
    setValue(e.target.value);
  };

  const handleExportData = () => {
    const filename =`Top Growing Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportCSV = () => {
    const filename =`Top Growing Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      {/* <div> */}
      <div className="top__growing__dropdown">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          onChange={handleEndDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        ></input>
        <Dropdown
          onChange={onChangeDropdown}
          options={[
            { name: "Top Talent", value: "Top Talent" },
            { name: "Weekly Talent", value: "Weekly Talent" },
            { name: "Top Star", value: "Top Star" },
            { name: "Weekly Star", value: "Weekly Star" },
            { name: "New Star", value: "New Star" },
          ]}
        />

        <Button text="Export CSV" onClick={handleExportCSV} />
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export PDF" onClick={exportPDF} />
      </div>
      <TopTalentTable
        isHost={isHost}
        tableData={tableData}
        page={page}
        perPage={perPage}
      />

      {tableData && tableData.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : (
        ""
      )}
      {/* </div> */}
    </>
  );
};

export default TopGrowing;
