import { useEffect, useState } from "react";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { AiFillEdit } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../../base/Context/apiProvider";
import Pagination from "../../Pagination";
import noData from "../../../base/Animation/No Data Found.json";
import Lottie from "react-lottie";
import { useLoader } from "../../../base/Context/loaderProvider";
import SearchInput from "../../SearchInput";
import { FiSearch } from "react-icons/fi";
import Button from "../../library/Button";
import { exportToExcel, exportToCSV, exportToPDF } from "react-easy-export";
import jsPDF from "jspdf";
import moment from "moment";

const UserSuspiciousData = () => {
  const apiProvider = useApi();
  const [suspiciousList, setSuspiciousList] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState("");
  const [count, setCount] = useState("");
  const [value, setValue] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  let loader = useLoader();
  let navigate = useNavigate();

  useEffect(() => {
    let array = [];
    suspiciousList.map((item,index) => {
      let obj = {
        "S.No":index+1,
        Name: item?.name,
        Gender: item?.gender,
        "Ai Gender": item?.attributes[0]?.gender?.Value,
        Age: item?.age,
        "Ai Age": `${item?.attributes[0]?.ageRange?.Low} -
          ${item?.attributes[0]?.ageRange?.High} `,
        Explicit: item?.isExplicit ? "TRUE" : "FALSE",
        Reason: item?.reason,
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [suspiciousList]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Warned Users Report";
    const headers = [
      [
        "S.No",
        "Name",
        "Gender",
        "Ai Gender",
        "Age",
        "Ai Age",
        "Explicit",
        "Reason",
      ],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Name"],
      item1?.Gender,
      item1["Ai Gender"],
      item1["Age"],
      item1["Ai Age"],
      item1["Explicit"],
      item1["Reason"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`User Suspicious data Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  useEffect(() => {
    fetchSuspiciousData();
  }, [apiProvider?.getApiUrl(), page, perPage, endDate, value]);
  const fetchSuspiciousData = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.SUSPICIOUSDATA,
      "POST",
      {
        startDate,
        endDate,
        type: "user",
        page,
        perPage,
        search: value,
      }
    )
      .then((res) => {
        console.log(res, "!!!!!!!!!!!!!!!!!!");
        setSuspiciousList(res?.users);
        setCount(res?.count);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        setSuspiciousList([]);
        console.log(err);
      });
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportData = () => {
    const filename =`User Suspicious data Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCSV = () => {
    const filename =`User Suspicious data Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };
  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />

      <div className="suspicious_data_btn">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          onChange={handleEndDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCSV} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>

      <div className="suspicious__data__container web_table_container">
        <table className="suspicious__data__table">
          <thead>
            <th className="suspicious__data__header">S.No</th>
            <th className="suspicious__data__header">Name</th>
            <th className="suspicious__data__header">Gender</th>
            <th className="suspicious__data__header">Ai Gender</th>
            <th className="suspicious__data__header">Age</th>
            <th className="suspicious__data__header">Ai Age</th>
            <th className="suspicious__data__header">Explicit</th>
            <th className="suspicious__data__header">Reason</th>
            <th className="suspicious__data__header">Action</th>
          </thead>
          <tbody>
            {suspiciousList &&
              suspiciousList.length > 0 &&
              suspiciousList.map((data, index) => {
                if (!data.name || !data.gender) {
                  // Skip rendering this row if name or gender is missing
                  return null;
                }

                return (
                  <tr key={data._id}>
                    <td className="suspicious__data__data">
                      {(page - 1) * perPage + index + 1}
                    </td>
                    <td className="suspicious__data__data">{data.name}</td>
                    <td className="suspicious__data__data">{data.gender}</td>
                    <td className="suspicious__data__data">
                      {data.attributes[0]?.gender?.Value}
                    </td>
                    <td className="suspicious__data__data">{data.age}</td>
                    <td className="suspicious__data__data">
                      {data.attributes[0]?.ageRange?.Low}-
                      {data.attributes[0]?.ageRange?.High}
                    </td>
                    <td className="suspicious__data__data">
                      {data.isExplicit ? "TRUE" : "FALSE"}
                    </td>
                    <td className="suspicious__data__data">{data.reason}</td>
                    <td className="suspicious__data__data">
                      <AiFillEdit
                        onClick={() => {
                          navigate(
                            `${
                              searchParams.get("appType") === "catchwoo"
                                ? `/usermanagement/${data._id}?appType=` +
                                  searchParams.get("appType")
                                : `/usermanagement/${data._id}`
                            }`
                          );
                        }}
                        className="suspicious__edit__icon"
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {!suspiciousList.length ? (
          <div className="host__no__data__found__icon">
            <Lottie
              options={{ animationData: noData, loop: true }}
              style={{ width: "40%", height: "30%"}}
            />
            <p className="no__data__found">No Data Found</p>
          </div>
        ) : null}
      </div>
      {suspiciousList && suspiciousList.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={count}
          totalPages={totalPages}
          options={[5, 10, 15, 20]}
        />
      ) : null}
    </>
  );
};

export default UserSuspiciousData;
