import "./style.css";

const Dropdown = (props) => {
  return (
    <div style={props.dropdownStyle} className={props.dropDownClass} >
      {props?.label ? <label style={props.style}>{props.label}</label> : null}
      <select
        className={`dropdown__input ${props.className}`}
        style={props.style}
        onChange={props.onChange}
        onClick={props.onClick}
        value={props.value}
      >
        <option className="dropdown__content" value={''}>
              Select 
            </option>
        {props.options?.map((option) => {
          return (
            
            <option className="dropdown__content" value={option?.value}>
              {option?.name}
            </option>
          );
        })}
      </select>
      {props?.error ? <p className="input__error">{props.error}</p> : null}
    </div>
  );
};

export default Dropdown;
