import { Box, Modal } from "@mui/material";
import "./style.css";

import { useEffect, useState } from "react";
import { exportToExcel, exportToCSV, exportToPDF } from "react-easy-export";

import { BsFillEyeFill } from "react-icons/bs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ImagePopUpModal from "../../ImagePopUpModal";
import Button from "../../library/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "80%",
  border: "none",
  outline: "none",
  borderRadius: "10px",
  bgcolor: "white",
  boxShadow: 24,
  p: 2,
  overflow: "auto",
};

const TablePopUp = ({ open, handleClose, data,fileNameData }) => {
  let navigate = useNavigate();
  const [showImage, setShowImage] = useState(false);
  const [img, setImg] = useState("");
  const [exportAllData, setExportAllData] = useState([]);


  const handleEyeProfilePicPopUp = (img) => {
    setShowImage(true);
    setImg(img);
  };

  const handleEyeProfilePicPopUpClose = () => {
    setShowImage(false);
  };

 
 
  useEffect(() => {
    let array = [];
    data.map((item, index) => {
      let obj = {
        "S.No": index + 1,
        "User ID":item?.userId,
        "Name":item?.name,
        "Gender":item?.gender,
        "Date Of Birth":item?.dateOfBirth,
        "Age":item?.age,
        "Country":item?.country,
        "State":item?.state,
        "City":item?.city,
        "Mobile Number":item?.mobileNumber,
        "Profession":item?.proffession,
        "Bio":item?.addBio,
        "Profile Pic":item?.profilePic,
        "Created At":moment(item.createdAt).format("MM/DD/YYYY LT")
       
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [data]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Accepted Host Report";
    const headers = [
      [
        "S.No",
        "User ID",
        "Name",
        "Gender",
        "Date Of Birth",
        "Age",
        "Country",
        "State",
        "City",
        "Mobile Number",
        "Profession",
        "Bio",
        "Profile Pic",
        "Created At",
      ],
    ];

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1['User ID'],
      item1?.Name,
      item1['Gender'],
      item1["Date Of Birth"],
      item1['Age'],
      item1['Country'],
      item1['State'],
      item1['City'],
      item1['Mobile Number'],
      item1['Profession'],
      item1['Bio'],
      item1['Profile Pic'],
      item1["Created At"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`${fileNameData?.name}(${fileNameData.id}).pdf`);
  };
  const handleExportCsv = () => {
    const filename = `${fileNameData?.name}(${fileNameData.id}).csv`;
    exportToCSV(exportAllData, filename);
  };

  const handleExportData = () => {
    const filename = `${fileNameData?.name}(${fileNameData.id}).xlsx`;
    exportToExcel(exportAllData, filename);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{display:"flex" ,gap:'10px',marginBottom:'10px'}}>

          <Button
            text="Export Excel"
            onClick={handleExportData}
            textStyle={{ fontSize: "16px" }}
          />
          <Button text="Export CSV" onClick={handleExportCsv} />
          <Button text="Export Pdf" onClick={exportPDF} />
          </div>

          <table className="user__request__table">
            <thead>
              <th className="user__request__headers">S.No.</th>
              <th className="user__request__headers">User ID</th>
              <th className="user__request__headers">Name</th>
              <th className="user__request__headers">Gender</th>
              <th className="user__request__headers">Date Of Birth</th>
              <th className="user__request__headers">Age</th>
              <th className="user__request__headers">Country</th>
              <th className="user__request__headers">State</th>
              <th className="user__request__headers">City</th>
              <th className="user__request__headers">Mobile Number</th>
              <th className="user__request__headers">Profession</th>
              <th className="user__request__headers">Bio</th>
              <th className="user__request__headers">Profile Pic</th>
              <th className="user__request__headers">Created At </th>
              {/* <th className="user__request__headers">View Profile</th> */}
            </thead>
            <tbody>
              {data?.length === 0 ? (
                <p>No data available</p>
              ) : (
                data?.map((data, index) => {
                  return (
                    <tr>
                      <td className="user__request__data">{index + 1}</td>
                      <td className="user__request__data">{data?.userId}</td>
                      <td className="user__request__data">{data?.name}</td>
                      <td className="user__request__data">{data?.gender}</td>
                      <td className="user__request__data">
                        {data?.dateOfBirth}
                      </td>
                      <td className="user__request__data">{data?.age}</td>
                      <td className="user__request__data">{data?.country}</td>
                      <td className="user__request__data">{data?.state}</td>
                      <td className="user__request__data">{data?.city}</td>
                      <td className="user__request__data">
                        {data?.mobileNumber}
                      </td>
                      <td className="user__request__data">
                        {data?.proffession}
                      </td>
                      <td className="user__request__data">{data?.addBio}</td>
                      <td className="user__request__data">
                        {data?.profilePic && (
                          <BsFillEyeFill
                            onClick={() => {
                              handleEyeProfilePicPopUp(data?.profilePic);
                            }}
                            className="user__request__eye__icon"
                          />
                        )}
                      </td>
                      <td className="user__request__data">
                        {" "}
                        {moment(data.createdAt).format("MM/DD/YYYY LT")}
                      </td>
                      {/* <td
                        className="user__request__data user__management__view__btn"
                        onClick={() => {
                          navigate(`/usermanagement/${data._id}`);
                        }}
                      >
                        View more...
                      </td> */}
                    </tr>
                  );
                })
              )}

              <ImagePopUpModal
                open={showImage}
                handleOpen={handleEyeProfilePicPopUp}
                handleClose={handleEyeProfilePicPopUpClose}
                img={img}
              />
            </tbody>
          </table>
        </Box>
      </Modal>
    </div>
  );
};

export default TablePopUp;
