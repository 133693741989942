import { useContext, useState } from "react";
import { Modal } from "../../../base/Context/modalProvider";
// import "./style.css";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import Button from "../../library/Button";
import { useLocation } from "react-router-dom";

const TotalRechargeTable = ({ tableData, isHost, page, perPage }) => {
  const modalProvider = useContext(Modal);
  // const {data}=useLocation()
  console.log(tableData);
  return (
    <div className="top__talent__table">
      {/* <div className="top__talent__container web_table_container"> */}
      <table className="">
        <thead style={{ height: "50px" }}>
          <th className="top__talent__header">S No.</th>
          <th className="top__talent__header">User ID</th>
          <th className="top__talent__header">Payment Method</th>
          <th className="top__talent__header">Coins</th>
          <th className="top__talent__header">Customer Email</th>
          <th className="top__talent__header">Customer Name</th>
          <th className="top__talent__header">Customer Phone</th>

          <th className="top__talent__header">Mobile Model </th>
          <th className="top__talent__header">Mobile Name</th>
          <th className="top__talent__header">Order Currency </th>
          <th className="top__talent__header">Order ID</th>
          <th className="top__talent__header">Payment Date </th>
          <th className="top__talent__header">Payment Status </th>
          <th className="top__talent__header">PAyment Through </th>
          <th className="top__talent__header">Price </th>
          <th className="top__talent__header">Reference ID</th>
          <th className="top__talent__header">Transaction Id</th>
        </thead>
        <tbody>
          {tableData && tableData?.length > 0
            ? tableData.map((data, index) => {
                return (
                  <tr>
                    <td className="top__talent__data">
                      {(page - 1) * perPage + index + 1}
                    </td>
                    <td className="top__talent__data">
                      {data?.userId ? data?.userId : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.PaymentMethod ? data?.PaymentMethod : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.coins ? data?.coins : 0}
                    </td>
                    <td className="top__talent__data">
                      {data?.customerEmail ? data?.customerEmail : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.customerName ? data?.customerName : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.customerPhone ? data?.customerPhone : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.mobileModel ? data?.mobileModel : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.mobileName ? data?.mobileName : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.orderCurrency ? data?.orderCurrency : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.orderkeyId ? data?.orderkeyId : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.paymentDate ? data?.paymentDate : "0"}
                    </td>
                    <td className="top__talent__data">
                      {data?.paymentStatus ? data?.paymentStatus : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.paymentThrough ? data?.paymentThrough : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.price ? data?.price : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.referenceId ? data?.referenceId : "-"}
                    </td>{" "}
                    <td className="top__talent__data">
                      {data?.transactionId ? data?.transactionId : "-"}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>

      {tableData &&!tableData?.length ? (
        <div className="host__no__data__found__icon">
          <Lottie
            options={{ animationData: noData, loop: true }}
            style={{ width: "40%", height: "30%" }}
          />
          <p className="no__data__found">No Data Found</p>
        </div>
      ) : null}
      {/* </div> */}
    </div>
  );
};

export default TotalRechargeTable;
