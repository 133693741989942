import { useState } from "react";
import Button from "../../../library/Button";
import InputField from "../../../library/InputField";
import "./style.css";
import { fetchDataFromAPI } from "../../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../../network/NetworkConfiguration";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useApi } from "../../../../base/Context/apiProvider";
import AlertPopUp from "../../../AlertPopUp";

const SuspendHost = () => {
  let navigate = useNavigate();
  const [endDate, setEndDate] = useState();
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const apiProvider = useApi();
  const [error, setError] = useState({
    reasonError: "",
    endDateError: "",
  });

  const validate = () => {
    let result = true;
    if (!endDate) {
      setError({ ...error, endDateError: "Enter a valid date" });
      result = false;
    }

    return result;
  };

  const validate1 = () => {
    let result = true;
    if (!reason) {
      setError({ ...error, reasonError: "Enter a valid reason" });
      result = false;
    }
    return result;
  };

  const handleSuspendedHost = () => {
    if (validate1()) {
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.POSTSUSPENDEDHOST,
        "POST",
        {
          id: id,
          suspensionEndDate: endDate,
          suspendedResion: reason,
        }
      )
        .then((res) => {
          // navigate(`/suspendusers/?type=host&id=${id}`);

          navigate(`/suspendeddata?appType=host`);
        })
        .catch((err) => {
          console.log("098765", err);
        });
    }
  };

  const handleSuspendedBtn = () => {
    if (validate()) {
      setShowReason(true);
    }
  };

  const handleSuspendedBtnClose = () => {
    setShowReason(false);
  };

  return (
    <div className="user__management__suspend__user">
      <p className="suspend__user__heading">Suspend Account</p>
      <InputField
        onChange={(e) => {
          setEndDate(e.target.value);
          setError({ ...error, endDateError: "" });
        }}
        style={{boxShadow:'none',borderBottom:'1px solid black',borderRadius:'0px'}}
        type="date"
        min={new Date().toISOString().split("T")[0]}
        error={error.endDateError}
      />
      <br />
      <Button
        onClick={handleSuspendedBtn}
        text="Submit"
        style={{
          
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      />

      <AlertPopUp
        open={showReason}
        handleOpen={handleSuspendedBtn}
        handleClose={handleSuspendedBtnClose}
        header="Reason for Suspension"
        submitText="Yes"
        cancelText="No"
        textField={true}
        onSubmitClick={handleSuspendedHost}
        onCancelClick={handleSuspendedBtnClose}
        error={error.reasonError}
        onChangeField={(e) => {
          setReason(e.target.value);
          setError({ ...error, reasonError: "" });
        }}
      />
    </div>
  );
};

export default SuspendHost;
