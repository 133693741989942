import { useEffect, useState } from "react";
import Button from "../../components/library/Button";
import Dropdown from "../../components/library/Dropdown";
import InputField from "../../components/library/InputField";
import "./style.css";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../network/NetworkConfiguration";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApi } from "../../base/Context/apiProvider";
import Select from "react-select";
import { useLoader } from "../../base/Context/loaderProvider";

const SendNotification = () => {
  let navigate = useNavigate();

  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [image1, setImage1] = useState("");
  const [title1, setTitle1] = useState("");
  const [body1, setBody1] = useState("");
  const [body, setBody] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [error, setError] = useState({
    title: "",
    body: "",
    title1: "",
    body1: "",
    selectedOption:''
  });
  const [userData, setUserData] = useState([
    {
      name: "",
      value: "",
    },
  ]);

  const apiProvider = useApi();
  const loader = useLoader();

  useEffect(() => {
    if (searchParams.get("appType") === "host") {
      setSelectedTo("hosts");
      getAcceptedHost();
    } else if (searchParams.get("appType") === "catchwoo") {
      setSelectedTo("users");
      getUserRequest();
    } else {
      getUserRequest();
      setSelectedTo("users");
    }
  }, [searchParams]);

  const getUserRequest = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETUSERS,
      "POST",
      {}
    )
      .then((res) => {
        console.log("12345678o", res);
        let mapped = res.result.map((result) => {
          return { label: result.name, value: result._id };
        });
        console.log(mapped);
        setUserData(mapped);
      })
      .catch((err) => {
        console.log(err, "err==========");
      });
  };

  const getAcceptedHost = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.ACCEPTEDHOST,
      "POST",
      {}
    )
      .then((res) => {
        let mappedData = res.result.map((result) => {
          return { label: result.name, value: result._id };
        });
        console.log(mappedData);
        setUserData(mappedData);
        console.log(res);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  const [selectedTo, setSelectedTo] = useState("");

  const handleSendNotification = () => {
    if(validate2()){
    const data = new FormData();
    data.append("title", title);
    data.append("body", body);
    data.append("to", selectedTo);
    data.append("image", image);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.SENDTOALL,
      "POST",
      data,
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .then((res) => {
        navigate(
          `${
            searchParams.get("appType")
              ? "/notification?appType=" + searchParams.get("appType")
              : "/notification"
          }`
        );
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  const [selectedOption, setSelectedOption] = useState([]);

  var handleChange = (selectedOption) => {
    let array = [];

    console.log(selectedOption);
    selectedOption.map((item) => {
      array.push(item.value);
    });

    setSelectedOption(array);
  };

  const handleMultipleNotification = () => {
    if (validate1()) {
      loader.showLoader(true);
      let data = new FormData();
      data.append("title", title1);
      data.append("body", body1);
      data.append("image", image1);
      selectedOption.map((item) => {
        data.append(
          searchParams.get("appType") === "host" ? "hosts" : "users",
          item
        );
      });

      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.SENDTOFEW,
        "POST",
        data,
        {
          "Content-Type": "multipart/form-data",
        }
      )
        .then((res) => {
          loader.showLoader(false);
          navigate(
            `${
              searchParams.get("appType")
                ? "/notification?appType=" + searchParams.get("appType")
                : "/notification"
            }`
          );
          console.log(res);
        })
        .catch((err) => {
          loader.showLoader(false);
          console.log(err);
        });
    }
  };
useEffect(()=>{
  if (selectedOption.length !== 0) {
   setError((error)=>({...error,selectedOption:''}))
  }
  if (title1.trim().length > 0) {
    setError((error)=>({...error,title1:''}))
  }
  if (body1.trim().length>0) {
    setError((error)=>({...error,body1:''}))
  }
  if (title.trim().length > 0) {
    setError((error)=>({...error,title:''}))
  }
  if (body.trim().length>0) {
    setError((error)=>({...error,body:''}))
  }
},[selectedOption,title1,body1,title,body])

  const validate1 = () => {
    let isValid = true;
    console.log(selectedOption,title1,body1)
    let newError = {};
    if (selectedOption.length === 0) {
      newError.selectedOption = "This field is required";
      isValid = false;
    }
    if (!title1 || title1.trim().length === 0) {
      newError.title1 = "This field is required";
      isValid = false;
    }
    if (!body1||body1.trim().length === 0) {
      newError.body1 = "This field is required";
      isValid = false;
    }

    setError(newError);
    return isValid;
  };
  const validate2 = () => {
    let isValid = true;
    console.log(selectedOption,title1,body1)
    let newError = {};
   
    if (!title || title.trim().length === 0) {
      newError.title = "This field is required";
      isValid = false;
    }
    if (!body||body.trim().length === 0) {
      newError.body = "This field is required";
      isValid = false;
    }

    setError(newError);
    return isValid;
  };

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div className="notification__form">
        <div className="notification__content">
          {searchParams.get("appType") === "host" ? (
            <h2 className="notification__header"> Notification To All Hosts</h2>
          ) : (
            <h2 className="notification__header">Notification To All Users</h2>
          )}

          <InputField
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            error={error.title}
            placeholder="Enter Title"
          />
          <br />
          <InputField
            value={body}
            error={error.body}

            onChange={(e) => {
              setBody(e.target.value);
            }}
            placeholder="Enter Message"
          />
          <br />
          <InputField
            type="file"
            accept=".png,.jpeg,.jpg,"
            onChange={(e) => {
              setImage(e.target.files[0]);
            }}
          />
          <br />
          <br />
          <Button
            onClick={handleSendNotification}
            className=""
            text="Send"
            style={{ margin: "auto" }}
          />
        </div>
      </div>
      <div className="send__notification__form">
        <div className="notification__content">
          {searchParams.get("appType") === "host" ? (
            <h2 className="notification__header">
              Notification To Selected Hosts
            </h2>
          ) : (
            <h2 className="notification__header">
              Notification To Selected Users
            </h2>
          )}
          <Select
            isMulti
            onChange={handleChange}
            options={userData}
            className="multi_select"
          />
          {error.selectedOption&&<p className="error_alert">{error.selectedOption}</p>}
          <br />
          <InputField
            value={title1}
            error={error.title1}
            onChange={(e) => {
              setTitle1(e.target.value);
            }}
            placeholder="Enter Title"
          />
          <br />
          <InputField
            value={body1}
            error={error.body1}
            onChange={(e) => {
              setBody1(e.target.value);
            }}
            placeholder="Enter Message"
          />
          <br />
          <InputField
            type="file"
            accept=".png,.jpeg,.jpg,"

            onChange={(e) => {
              setImage1(e.target.files[0]);
            }}
          />
          <br />
          <Button
            onClick={handleMultipleNotification}
            text="Send"
            style={{ margin: "auto" }}
          />
        </div>
      </div>
    </div>
  );
};

export default SendNotification;
