import moment from "moment";
import "./style.css";
import { RxCross2 } from "react-icons/rx";
import { useLoader } from "../../base/Context/loaderProvider";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { useApi } from "../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import { errorToast, successToast } from "../../utils/toast";

const PopUpNotification = ({
  children,
  popOpen,
  notifications,
  handleNotificationClick,
}) => {
  const loader = useLoader();
  const apiProvider = useApi();

  const handleClearAll = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.CLEARALLNOTIFICATIONS,
      "PUT",
      {}
    )
      .then((res) => {
        successToast(res?.message);
        loader.showLoader(false);
        handleNotificationClick();
      })
      .catch((err) => {
        errorToast(err?.message);
        loader.showLoader(false);
      });
  };

  const handleSingleClear = (id) => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.CLEARSINGLENOTIFICATION,
      "PUT",
      { id: id }
    )
      .then((res) => {
        successToast(res?.message);
        loader.showLoader(false);
        handleNotificationClick();
      })
      .catch((err) => {
        errorToast(err?.message);
        loader.showLoader(false);
      });
  };
  return (
    <div className="notification_box">
      {children}
      <div
        style={{ display: popOpen ? "block" : "none" }}
        className="pop_container"
      >
        <div className="popup__notifications ">
          <div className="popup__heading__content notification_box">
            <p className="pop__content notification_box">Notifications</p>
            <p
              className="popup__clear__all notification_box"
              onClick={handleClearAll}
            >
              Clear All
            </p>
          </div>

          <div className="popup__notify__content notification_box">
            {notifications && notifications?.length ? (
              notifications?.map((data) => {
                return (
                  <div className="popup__row notification_box">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <div className="appType__styling notification_box">
                        <p className="popup__appType notification_box">
                          {data?.roleType}
                        </p>
                      </div>
                      <p className="popup__heading notification_box">
                        {data?.title}
                      </p>

                      <div>
                        <p className="popup__date_time notification_box">
                          {moment(data?.createdAt).format("DD/MM/YY ,LT")}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <p className="popup__notification__body notification_box">
                        {data?.body}
                      </p>
                      <div onClick={() => handleSingleClear(data._id)}>
                        <RxCross2 className="popup__cross__icon notification_box" />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="popup__notification__nodata">No Data Found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpNotification;
