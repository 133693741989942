import { useEffect, useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { errorToast, successToast } from "../../../utils/toast";
import { useLoader } from "../../../base/Context/loaderProvider";
import { useApi } from "../../../base/Context/apiProvider";
import { useSearchParams } from "react-router-dom";

const StickerForm = ({ onSubmit, edit, editedSticker, onClickEdit, id }) => {
  const [stickerName, setStickerName] = useState("");
  const [stickerPrice, setStickerPrice] = useState("");
  const [stickerImage, setStickerImage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  // const [id, setId] = useState("");
  const [error, setError] = useState({
    name: "",
    price: "",
    image: "",
  });

  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    if (edit && editedSticker) {
      setStickerData(editedSticker?.name, editedSticker?.price, apiProvider);
    }
  }, [edit]);

  const setStickerData = (stickerName, stickerPrice, stickerImage) => {
    setStickerName(stickerName);
    setStickerPrice(stickerPrice);
    setStickerImage(stickerImage);
  };

  const handleStickerName = (e) => {
    setError({ ...error, name: "" });
    setStickerName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ""));
  };

  const handleStickerPrice = (e) => {
    setError({ ...error, price: "" });
    setStickerPrice(e.target.value.replace(/\D/g, ""));
  };

  const handleStickerImage = (e) => {
    setError({ ...error, image: "" });
    setStickerImage(e.target.files[0]);
  };

  const validate = () => {
    let result = true;
    if (!stickerName || stickerName.trim()?.length === 0) {
      setError({ ...error, name: "Enter valid Sticker Name" });
      result = false;
    } else if (!stickerPrice) {
      setError({ ...error, price: "Enter valid Sticker Price" });
      result = false;
    } else if (!stickerImage) {
      setError({ ...error, image: "Upload Sticker Image" });
      result = false;
    }
    return result;
  };

  const handleEditForm = () => {
    if(validate()){

      loader.showLoader(true);
      let data = new FormData();
      data.append("name", stickerName);
      data.append("price", stickerPrice);
      data.append("image", stickerImage);
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.UPDATESTICKER + `/${id}`,
        "PUT",
        data,
        {
          "Content-Type": "multipart/form-data",
        }
      )
        .then((res) => {
          onClickEdit();
          loader.showLoader(false);
        })
        .catch((err) => {
          loader.showLoader(false);
          errorToast(err);
        });
    }
  };

  const handleStickerForm = () => {
    if (validate()) {
      loader.showLoader(true);
      loader.showLoader(true);
      let data = new FormData();
      data.append("name", stickerName);
      data.append("price", stickerPrice);
      data.append("image", stickerImage);
      const appType = searchParams.get("appType") === "host" ? "host" : "user";
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.ADDSTICKER,
        "POST",
        data,
        {
          "Content-Type": "multipart/form-data",
          appType,
        }
      )
        .then((res) => {
          loader.showLoader(false);
          onSubmit();
          successToast(res?.message);
        })
        .catch((err) => {
          loader.showLoader(false);
          errorToast(err);
        });
    }
  };

  return (
    <div className="sticker__form__container">
      {edit ? (
        <h2 className="sticker__form__header">Update Sticker</h2>
      ) : (
        <h2 className="sticker__form__header">Add Sticker</h2>
      )}
      <div className="sticker__form">
        <InputField
          value={stickerName}
          onChange={handleStickerName}
          placeholder="Sticker Name"
          maxlength="25"
          error={error.name}
        />
        <br />
        {/* {searchParams.get("appType") !== "host" ? ( */}
          <InputField
            type="text"
            maxlength="4"
            value={stickerPrice}
            onChange={handleStickerPrice}
            placeholder="Sticker Price"
            error={error.price}
          />
        {/* ) : null} */}
        <br />
        <InputField
          onChange={handleStickerImage}
          type="file"
          placeholder="Sticker Image"
          accept=".png, .jpg, .jpeg , .gif"
          error={error.image}
        />
        <p className="add_gift_para">NOTE: Image should be 1000x1000 px</p>

        <br />
        <Button
          onClick={edit ? handleEditForm : handleStickerForm}
          className="sticker__submit__btn"
          text={edit ? "Update" : "Submit"}
        />
      </div>
    </div>
  );
};

export default StickerForm;
