import { Avatar } from "@mui/material";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { IoSend } from "react-icons/io5";
import { socketProvider } from "../../base/Context/socketProvider";
import { IoMdSend } from "react-icons/io";
import { useLoader } from "../../base/Context/loaderProvider";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import { useApi } from "../../base/Context/apiProvider";
import AlertPopUp from "./SuccessPopup";
import moment from "moment";

const IndividualChat = ({}) => {
  const [message, setmessage] = useState("");
  const [activeStatus, setActiveStatus] = useState("");
  const [messages, setMessages] = useState([]);
  const [showClosedPopup, setShowClosedPopup] = useState(false);
  const chatBoxRef = useRef(null);
  const { socketRef } = useContext(socketProvider);
  const location = useLocation();
  const loader = useLoader();
  const { id } = useParams();
  const apiProvider = useApi();
  const [closePanel, setClosePanel] = useState(false);
  const roomId = "";
  const userId = "";
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useLayoutEffect(() => {
    setActiveStatus("online");
    return () => {
      setActiveStatus("offline");
    };
  }, []);

  useLayoutEffect(() => {
    if (socketRef) {
      updateSocketId();
    }
  }, [activeStatus, socketRef]);

  useEffect(() => {
    socketRef?.current?.emit("previous", {
      to: location?.state?.userId,
      feedbackId: id,
    });
    socketRef?.current?.on("previous", (data) => {
      console.log(data, "previous emited useee");
      setMessages(data?.chat?.messages);
    });
    return () => {
      if (socketRef) {
        socketRef.current.off("previous");
      }
    };
  }, [socketRef]);

  useEffect(() => {
    console.log(
      "component of chat mounted",
      socketRef.current,
      socketRef,
      socketRef?.current?.id,
      location
    );
  }, []);

  const updateSocketId = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.SOCKETUPDATE,
      "PUT",
      { acctiveStatus: activeStatus, socketId: socketRef?.current?.id }
    )
      .then((res) => {
        loader.showLoader(false);
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };

  console.log(showClosedPopup, closePanel);
  const sendMessages = () => {
    const data = {
      message: message,
      sendingUser: "admin",
      to: location?.state?.userId,
      feedbackId: id,
      isClosed: closePanel,
      timestamp: new Date().toLocaleTimeString(),
    };
    socketRef.current.emit("feedbackMessage", data);
    socketRef.current.emit("previous", {
      to: location?.state?.userId,
      feedbackId: id,
    });
    socketRef?.current?.on("previous", (data) => {
      console.log(data, "previous emited");
      setMessages(data?.chat?.messages);
    });
    setmessage("");
    if (closePanel) {
      setShowClosedPopup(true);
    }
    console.log(data, "messagee");
  };

  useEffect(() => {
    const chatBox = chatBoxRef.current;

    if (chatBox) {
      const observer = new MutationObserver((mutations) => {
        const hasNewMessages = mutations.some(
          (mutation) =>
            mutation.type === "childList" || mutation.type === "subtree"
        );

        if (hasNewMessages) {
          chatBox.scroll({
            top: chatBox.scrollHeight,
            behavior: "smooth",
          });
        }
      });
      observer.observe(chatBox, {
        childList: true,
        subtree: true,
      });
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setmessage("");
      sendMessages();
      console.log(message, "msgket");
      e.target.value = "";
    }
  };
console.log(searchParams.get("appType"),'searchParams.get("appType")',sessionStorage.getItem("selectedType"))
  return (
    <div style={{width:'100%',height:'100%',position:'relative'}}>
      <div className="chat_inputs_box" ref={chatBoxRef}>
        <p
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "bold",
            position: "absolute ",
            left: "50%",
            top: "-10%",
            transform:'translate(-50% ,10%)'
          }}
        >
          Feedback type : {location?.state?.feedBackType}
        </p>
        <div
          className="chat_individual"
          style={{
            marginRight: "auto",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgb(32, 87, 135,0.6)",
            padding: "10px",
          }}
        >
          <p></p>
          <p> Issue :-</p>
          <p>{location?.state?.description}</p>
        </div>
        {messages?.map(
          (msg, i) =>
            msg.text &&
            msg.text !== "" && (
              <div
                key={i}
                className="chat_individual"
                style={{
                  marginLeft: msg.isAdmin === true ? "auto" : "",
                  marginRight: msg.isAdmin !== true ? "auto" : "",
                }}
              >
                {msg.text && msg.text !== "" && (
                  <>
                    <p className={""}>
                      {msg.sender && msg.sender === userId
                        ? "You"
                        : msg.senderName}
                    </p>

                    <p className={""}>{msg.text}</p>

                    <p className="timestamp_text">
                      {moment(msg.timestamp).format("DD-MM-YYYY HH:MM")}
                    </p>
                  </>
                )}
              </div>
            )
        )}
      </div>
      {location?.state?.status !== "Closed" && (
        <div className="button_section">
          <input
            className=""
            placeholder="Type a message..."
            type="text"
            id="sendInput"
            value={message}
            onKeyPress={handleKeyPress}
            onChange={(e) => setmessage(e.target.value)}
          />
          <div className="checkbox_input_container">
            <input
              type="checkbox"
              onChange={() => setClosePanel(!closePanel)}
            ></input>
            Close chat
          </div>

          <div className={"button_container"} onClick={sendMessages}>
            <IoMdSend color="blue" size="28px" />
          </div>
        </div>
      )}
      <AlertPopUp
        open={showClosedPopup}
        handleOpen={() => setClosePanel(true)}
        handleClose={() => navigate( ( sessionStorage.getItem("selectedType") === "catchwoo"|| sessionStorage.getItem("selectedType") === "host")
                ? `/feedback?appType=` +  sessionStorage.getItem("selectedType")
                : `/feedback`)}
        header="Hurry Succesful !"
        description={
          "This chat has been closed succesfully you can go back now !"
        }
        submitText="Go Back"
        // cancelText="No"
        textField={true}
        onSubmitClick={() =>  navigate( ( sessionStorage.getItem("selectedType") === "catchwoo"|| sessionStorage.getItem("selectedType") === "host")
          ? `/feedback?appType=` +  sessionStorage.getItem("selectedType")
          : `/feedback`)}
        // onCancelClick={handleSuspendedBtnClose}
        // error={error.reasonError}
      />
    </div>
  );
};

export default IndividualChat;
