import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.css";
import { useLoader } from "../../base/Context/loaderProvider";
import { useApi } from "../../base/Context/apiProvider";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import Button from "../../components/library/Button";
import Pagination from "../../components/Pagination";
import BulkPopup from "../../components/sendMails/bulkPopup";
import MailList from "../../components/sendMails/mailList";
import MailPopup from "../../components/sendMails/mailPopup";
import Lottie from "react-lottie";
import noData from "../../base/Animation/No Data Found.json";
import WebModal from "../../components/WebModal";
import InputField from "../../components/library/InputField";
import Select from "react-select";

export default function SendMails() {
  const [bulkMail, setBulkMail] = useState(false);
  const [mail, setMail] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState();
  const [totalPages, setTotalPages] = useState();
  const [dataFound, setDataFound] = useState(false);
  const [mails, setMails] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userData, setUserData] = useState([]);
 const [selectedOption, setSelectedOption] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectMultipleuSersPopUp, setSelectMultipleuSersPopUp] =
    useState(false);
  function handleChange(event) {
    setSelectedOption(event.target.value);
    console.log(event.target.value, "eeeeeeeeeeeeeeeeeeeee");
    handleSelectChange(userData)
  }
  useEffect(()=>{
    setSelectedUsers(userData)
  },[userData])
  const loader = useLoader();
  const apiProvider = useApi();
  // const message = useMessage();
  const navigate = useNavigate();

  const handleSendBulkMail = () => {
    setBulkMail(true);
    setMail(false);
  };

  const handleSendMail = () => {
    setMail(true);
    setBulkMail(false);
  };

  const handleMails = () => {
    loader.showLoader(true, "wait for a minute");
    const data = {
      key: "",
      page: page,
      perPage: perPage,
      to: searchParams.get("appType") === "host" ? "hosts" : "users",
    };

    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.BULKMAILLIST,
      "POST",
      data
    )
      .then((res) => {
        loader.showLoader(false);
        setMails(res?.mails);
        setDataFound(true);
        setTotalCount(res.count);
        setTotalPages(res.totalPages);
      })
      .catch((err) => {
        loader.showLoader(false);
        setDataFound(false);
      });
  };
  const ClosePopup = () => {
    setSelectMultipleuSersPopUp(false);
    setSelectedOption("");
    console.log("users", selectedUsers);
    setBulkMail(true);
  };
  useEffect(() => {
    handleMails();
  }, [page, perPage]);

  useEffect(() => {
    if (selectedOption === "sendToSelectedUsers") {
      setSelectMultipleuSersPopUp(true);
    }
  }, [selectedOption]);
  useEffect(() => {
    if (searchParams.get("appType") === "host") {
      getAcceptedHost();
    } else if (searchParams.get("appType") === "catchwoo") {
      getUserRequest();
    } else {
      getUserRequest();
    }
  }, [searchParams]);

  const getUserRequest = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETUSERS,
      "POST",
      {}
    )
      .then((res) => {
        console.log("12345678o", res);
        let mapped = res.result.map((result) => {
          return { label: result.name, value: result._id };
        }).filter(item => item.label && item.value);;
        console.log(mapped);
        setUserData(mapped);
      })
      .catch((err) => {
        console.log(err, "err==========");
      });
  };

  const getAcceptedHost = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.ACCEPTEDHOST,
      "POST",
      {}
    )
      .then((res) => {
        let mappedData = res.result.map((result) => {
          return { label: result.name, value: result._id };
        }).filter(item => item.label && item.value);;
        console.log(mappedData);
        setUserData(mappedData);
        console.log(res);
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const handleSelectChange = (selectedOptions) => {
    // let array = [];

    // console.log(selectedUsers);
    // selectedUsers.map((item) => {
    //   array.push(item.value);
    // });

    setSelectedUsers((prev) =>
      selectedOptions.map((item) => ({
        id: item.value,
        name: item.label,
      }))
    );
  };
  return (
    <>
      <div className="send_mails_header">
        <label style={{ display: "flex", alignItems: "center" }}>
          Send To All Users
          <input
            type="radio"
            value={"sendToAll"}
            checked={selectedOption === "sendToAll"}
            onChange={handleChange}
          />
        </label>
        <label style={{ display: "flex", alignItems: "center" }}>
          Send To Selected Users
          <input
            type="radio"
            value={"sendToSelectedUsers"}
            checked={selectedOption === "sendToSelectedUsers"}
            onChange={handleChange}
          />
        </label>
        <Button
          onClick={handleSendBulkMail}
          text="Bulk Mail"
          className="header_btn"
          type="button"
        />

        {/* <Button
          onClick={handleSendMail}
          text="Send Mail"
          className="header_btn"
          type="button"
        /> */}
      </div>

      {dataFound && mails?.length > 0 ? (
        <MailList
          handleMails={handleMails}
          mails={mails}
          page={page}
          perPage={perPage}
          type='sendMail'
        />
      ) : (
        ""
        // <NoDataFound message="no mails found" />
      )}

      {!mails.length ? (
        <div className="host__no__data__found__icon">
          <Lottie
            options={{ animationData: noData, loop: true }}
            style={{ width: "40%", height: "30%" }}
          />
          <p className="no__data__found">No Data Found</p>
        </div>
      ) : null}

      <div className="send_mail_box">
        {bulkMail ? (
      selectedUsers&&    <BulkPopup
            show={bulkMail}
            setShow={setBulkMail}
            handleMailList={handleMails}
            users={selectedUsers}
            type='sendMail'
          />
        ) : null}
        {mail ? (
          <MailPopup
            show={mail}
            setShow={setMail}
            handleMailList={handleMails}
          />
        ) : null}
      </div>
      <div className="category_pagination">
        {dataFound && mails.length > 0 ? (
          <Pagination
            page={page}
            setPage={setPage}
            setPerPage={setPerPage}
            perPage={perPage}
            totalCount={totalCount}
            totalPages={totalPages}
            options={[20, 25, 20, 35]}
          />
        ) : null}
      </div>
      <WebModal
        open={selectMultipleuSersPopUp}
        onRequestClose={ClosePopup}
        heading="Select Multiple Users"
      >
        <div style={{ width: "300px", height: "200px" }}>
          <Select
            isMulti
            onChange={handleSelectChange}
            options={userData}
            className="multi_select"
          />
          <br />
          {/* <Button
          style={{ margin: "auto" }}
          text="Update"
          // onClick={handleEditHost}
        /> */}
        </div>
      </WebModal>
    </>
  );
}
