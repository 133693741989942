import { useState } from "react";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../../../network/NetworkConnection";
import Button from "../../../library/Button";
import InputField from "../../../library/InputField";
import "./style.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useApi } from "../../../../base/Context/apiProvider";

const WarnedHost = () => {
  let navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const { id } = useParams();
  const [error, setError] = useState({
    titleError: "",
    bodyError: "",
  });
  const apiProvider = useApi();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleWarnedHost = () => {
    if (validate()) {
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + NetworkConfiguration.WARNHOSTNOTIFICATION,
        "POST",
        {
          id,
          title,
          body,
        }
      )
        .then((res) => {
          console.log(res);
          navigate(`/warneddata?appType=${searchParams.get("appType")}`);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleTitle = (e) => {
    setError({ ...error, titleError: "" });
    setTitle(e.target.value);
  };

  const handleBody = (e) => {
    setError({ error, bodyError: "" });
    setBody(e.target.value);
  };

  const validate = () => {
    let result = true;
    if (!title) {
      setError({ ...error, titleError: "Please enter a title" });
      result = false;
    } else if (!body) {
      setError({ ...error, bodyError: "Please enter body" });
      result = false;
    }
    return result;
  };

  return (
    <div className="user__management__warn__user">
      <p className="warn__user__heading">Send warning haha</p>
      {/* <br /> */}
      <InputField
        onChange={handleTitle}
        placeholder="Custom title"
        error={error.titleError}
      />
      {/* <br /> */}

      <InputField
        onChange={handleBody}
        placeholder="Description"
        className="warn__user__description"
        error={error.bodyError}
      />
      {/* <br /> */}
      <Button
        onClick={handleWarnedHost}
        text="Submit"
        className="warn__user__button"
        // style={{minHeight:'30px'}}
      />
    </div>
  );
};

export default WarnedHost;
