import { useContext, useState } from "react";
import { Modal } from "../../../base/Context/modalProvider";
// import "./style.css";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import Button from "../../library/Button";
import { useLocation } from "react-router-dom";

const TotalHOstEarningTable = ({ tableData, isHost, page, perPage }) => {
  const modalProvider = useContext(Modal);
  // const {data}=useLocation()
  console.log(tableData);
  return (
    <div className="top__talent__table">
      {/* <div className="top__talent__container web_table_container"> */}
      <table className="">
        <thead style={{ height: "50px" }}>
          <th className="top__talent__header">S No.</th>
          <th className="top__talent__header">User ID</th>
          <th className="top__talent__header">host Id</th>
          <th className="top__talent__header">Post ID</th>
          <th className="top__talent__header">Coin Type</th>
          <th className="top__talent__header">Host Balance</th>
          <th className="top__talent__header">User Coins</th>
        </thead>
        <tbody>
          {tableData && tableData?.length > 0
            ? tableData.map((data, index) => {
                return (
                  <tr>
                    <td className="top__talent__data">
                      {(page - 1) * perPage + index + 1}
                    </td>
                    <td className="top__talent__data">
                      {data?.userId ? data?.userId : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.hostId ? data?.hostId : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.postId ? data?.postId : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.coinType ? data?.coinType : "-"}
                    </td>
                    <td className="top__talent__data">
                      {data?.hostBalance ? data?.hostBalance : "0"}
                    </td>
                    <td className="top__talent__data">
                      {data?.userCoins ? data?.userCoins : "0"}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>

      {tableData && !tableData?.length ? (
        <div className="host__no__data__found__icon">
          <Lottie
            options={{ animationData: noData, loop: true }}
            style={{ width: "40%", height: "30%" }}
          />
          <p className="no__data__found">No Data Found</p>
        </div>
      ) : null}
      {/* </div> */}
    </div>
  );
};

export default TotalHOstEarningTable;
