import { useEffect, useState } from "react";
import LeaderTable from "../../components/Table/LeaderTable";
import Button from "../../components/library/Button";
import "./style.css";
import FormAlertPopUp from "../../components/FormAlertPopUp";
import AddLeaderForm from "../../components/formComponents/AddLeaderform/index";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import SearchInput from "../../components/SearchInput";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../components/Pagination";
import { useApi } from "../../base/Context/apiProvider";
import noData from "../../base/Animation/No Data Found.json";
import { exportToExcel, exportToCSV, exportToPDF } from "react-easy-export";
import Lottie from "react-lottie";
import moment from "moment";
import jsPDF from "jspdf";

const Leader = () => {
  const [showAddLeaderAlert, setshowAddLeaderAlert] = useState(false);
  const [showLeaderList, setShowLeaderList] = useState([]);
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const apiProvider = useApi();
  const [exportAllData, setExportAllData] = useState([]);

  const handleAddLeader = () => {
    setshowAddLeaderAlert(true);
  };

  const handleAddLeaderClose = () => {
    setshowAddLeaderAlert(false);
  };

  useEffect(() => {
    let array = [];
    showLeaderList.map((item,index) => {
      let obj = {
        "S No.": index + 1,

        "Leader ID": item?.leaderId,
        Name: item?.leaderName,
        Gender: item?.gender,
        Email: item?.email,
        "Mobile Number": item?.mobileNumber,
        "ID Proof": item?.idProof,
        "Group Name": item?.groupName,
        Country: item?.country,
        State: item?.state,
        City: item?.city,
        "Pin Code": item?.pin,
        "Created At": moment(item?.createdAt).format("DD/MM/YYYY"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [showLeaderList]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Leader";
    const headers = [
      [
        "S.no.",
        "LeaderID",
        "Name",
        "Gender",
        "Email",
        "Mobile Number",
        "ID Proof",
        "Group Name",
        "Country",
        "State",
        "City",
        "Pin Code",
        "Created At",
      ],
    ];
    console.log(exportAllData);
    const filename =`Leader Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Leader ID"],
      item1["Name"],
      item1["Gender"],
      item1["Email"],
      item1["Mobile Number"],
      item1["ID Proof"],
      item1["Group Name"],
      item1["Country"],
      item1["State"],
      item1["City"],
      item1["Pin Code"],
      item1["Created At"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  useEffect(() => {
    getAllLeaders();
  }, [value, page, perPage, apiProvider?.apiUrl, endDate]);

  const getAllLeaders = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETLEADER,
      "POST",
      {
        startDate,
        endDate,
        key: value,
        page,
        perPage,
      }
    )
      .then((res) => {
        setShowLeaderList(res?.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        setShowLeaderList([]);
        console.log(err);
      });
  };

  const onSubmit = () => {
    setshowAddLeaderAlert(false);
    getAllLeaders();
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportData = () => {
    const filename =`Leader Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCsv = () => {
    const filename =`Leader Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="add__leader__styling">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
          onChange={handleEndDate}
        ></input>
        <Button text="Add Leader" onClick={handleAddLeader} />
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export PDF" onClick={exportPDF} />
        <Button text="Export CSV" onClick={handleExportCsv} />
      </div>
      <LeaderTable
        page={page}
        perPage={perPage}
        showLeaderList={showLeaderList}
        getAllLeaders={getAllLeaders}
      />

      <FormAlertPopUp
        open={showAddLeaderAlert}
        onRequestClose={handleAddLeaderClose}
      >
        <AddLeaderForm onSubmit={onSubmit} handleClose={handleAddLeaderClose} />
      </FormAlertPopUp>

      {showLeaderList && showLeaderList.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default Leader;
