import { useContext, useEffect, useState } from "react";
import { useApi } from "../../base/Context/apiProvider";
import Button from "../../components/library/Button";
import { NetworkConfiguration } from "../../network/NetworkConfiguration";
import { fetchDataFromAPI } from "../../network/NetworkConnection";
import "./style.css";
import moment from "moment";
import { Modal } from "../../base/Context/modalProvider";
import FormAlertPopUp from "../../components/FormAlertPopUp";
import InactiveUserForm from "../../components/formComponents/InactiveUserForm";
import Pagination from "../../components/Pagination";
import Lottie from "react-lottie";
import { useLoader } from "../../base/Context/loaderProvider";
import noData from "../../base/Animation/No Data Found.json";
import SearchInput from "../../components/SearchInput";
import { FiSearch } from "react-icons/fi";
import { errorToast, successToast } from "../../utils/toast";
import BulkPopup from "../../components/sendMails/bulkPopup";
import MailPopup from "../../components/sendMails/mailPopup";

const InactiveUser = () => {
  const apiProvider = useApi();
  const [bulkMail, setBulkMail] = useState(false);
  const [mail, setMail] = useState(false);
  const [inactiveUser, setInactiveUser] = useState([]);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const modalProvider = useContext(Modal);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [checkHeaderClick, setCheckHeaderClick] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [value, setValue] = useState("");
  const loader = useLoader();

  const handleAllChecked = (e) => {
    if (e.target.checked) {
      let userId = inactiveUser.map((id) => {
        return id?._id;
      });
      setAllData(userId);
      setData([...userId]);
      setCheckHeaderClick(true);
    } else {
      setAllData([]);
      setCheckHeaderClick(false);
    }
  };

  useEffect(() => {
    fetchInactiveUser();
  }, [page, perPage, value]);

  const fetchInactiveUser = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETINACTIVEUSER,
      "POST",
      {
        page,
        perPage,
        key: value,
      }
    )
      .then((res) => {
        setInactiveUser(res?.data);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNotificationAlert = () => {
    if (data.length > 0) {
      setShowNotificationPopup(true);
    }
  };

  const handleNotificationAlertClose = () => {
    setShowNotificationPopup(false);
  };
  //const abcd = [];

  const handleChange = (value) => {
    console.log(value, "datachanged", data);
    const itemExists = data.some((item) => item.id === value._id);
    if (itemExists) {
      const filteredData = data.filter((item) => item.id !== value._id);
      setData(
        filteredData.map((item) => ({
          id: item.id,
          name: item.name,
        }))
      );
    } else {
      setData([...data, { id: value._id, name: value.name }]);
    }
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };
  const handleSendBulkMail = () => {
    setBulkMail(true);
    setMail(false);
  };
  return (
    <>
      <SearchInput
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
        value={value}
      />
      <div className="active__user" style={{padding:'5px'}}>
      <Button
          onClick={handleSendBulkMail}
          text="Bulk Mail"
          className="header_btn"
          type="button"
          style={{ textAlign: "center",marginRight:'20px' }}
        />
        <Button
         style={{ textAlign: "center" }}
          text="Send Notification"
          onClick={handleNotificationAlert}
        />
      </div>
      <div className="active__user__container web_table_container">
        <table className="active__user__table">
          <thead>
            <th className="active__user__header">
              <input
                className="active__user__input"
                type="checkbox"
                onChange={handleAllChecked}
              />{" "}
            </th>
            <th className="active__user__header">S.No.</th>
            <th className="active__user__header">User ID</th>
            <th className="active__user__header">Name</th>
            <th className="active__user__header">Email</th>
            <th className="active__user__header">Last Online</th>
            <th className="active__user__header">Mobile Number</th>
            <th className="active__user__header">Created At</th>
          </thead>
          <tbody>
            {inactiveUser && inactiveUser.length > 0
              ? inactiveUser.map((data, index) => {
                  return (
                    <tr>
                      <td className="active__user__data">
                        <input
                          className="active__user__input"
                          type="checkbox"
                          onChange={()=>handleChange(data)}
                          value={data?._id}
                          {...(checkHeaderClick ? { checked: true } : {})}
                        />{" "}
                      </td>
                      <td className="active__user__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="active__user__data">{data?.userId}</td>
                      <td className="active__user__data">
                        <div
                          className="feedback__table__comment"
                          onClick={
                            data?.name.length > 10
                              ? () =>
                                  modalProvider.handleCommentClick(
                                    data?.name,
                                    "Name"
                                  )
                              : () => {}
                          }
                        >
                          {data?.name}
                        </div>
                      </td>
                      <td className="active__user__data">{data?.email}</td>
                      <td className="active__user__data">
                        {data?.daysSinceOnline} days before
                      </td>
                      <td className="active__user__data">
                        {data?.mobileNumber}
                      </td>
                      <td className="active__user__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY ,LT")}
                      </td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
        {!inactiveUser.length
        ? !loader.loaderPopup && (
            <div className="host__no__data__found__icon">
              <Lottie
                options={{ animationData: noData, loop: true }}
                style={{ width: "40%", height: "30%" }}
              />
              <p className="no__data__found">No Data Found</p>
            </div>
          )
        : null}
         {bulkMail ? (
            <BulkPopup
              show={bulkMail}
              setShow={setBulkMail}
              // handleMailList={handleMails}
              users={data}
            />
          ) : null}
          {mail ? (
            <MailPopup
              show={mail}
              setShow={setMail}
              // handleMailList={handleMails}
            />
          ) : null}

      </div>

   
      <FormAlertPopUp
        open={showNotificationPopup}
        onRequestClose={handleNotificationAlertClose}
      >
        <InactiveUserForm
          user={true}
          data={data}
          setData={setData}
          setAllData={setAllData}
          setShowNotificationPopup={setShowNotificationPopup}
          allData={allData}
        />
      </FormAlertPopUp>
      {inactiveUser && inactiveUser.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default InactiveUser;
