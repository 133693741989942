import { useEffect, useState } from "react";
import "./style.css";
import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { useParams } from "react-router-dom";
import ImagePopUpModal from "../../ImagePopUpModal";
import moment from "moment";
import AlertPopUp from "../../AlertPopUp";
import { useLoader } from "../../../base/Context/loaderProvider";
import { FiSearch } from "react-icons/fi";
import SearchInput from "../../SearchInput";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { useApi } from "../../../base/Context/apiProvider";
import Button from "../../library/Button";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import { errorToast, successToast } from "../../../utils/toast";
import jsPDF from "jspdf";
import HostLikedProfile from "../HostLikedProfile";

const HostMomentTable = () => {
  const [getHostMoment, setGetHostMoment] = useState([]);
  const [showImageAlert, setShowImageAlert] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [img, setImg] = useState("");
  const { id } = useParams();
  const [getId, setGetId] = useState("");
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [reason, setReason] = useState("");
  const [showDeleteReason, setShowDeleteReason] = useState(false);
  const [showPostImage, setShowPostImage] = useState(false);
  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    getHostMoment.map((item, index) => {
      let obj = {
        "S.No": index + 1,
        "Host Name": item?.hostId?.name,
        Caption: item?.subject,
        Likes: item?.likes,
        "Image/Video": item?.postImage,
        "Created At": moment(item?.postDate).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [getHostMoment]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Call History Report";
    const headers = [
      ["S.no.", "Host Name", "Caption", "Likes", "Image/Video", "Created At"],
    ];

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["Host Name"],
      item1["Caption"],
      item1["Likes"],
      item1["Image/Video"],
      item1["Created At"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Host Moment Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  useEffect(() => {
    fetchHostMoment();
  }, [value, page, perPage, apiProvider?.getApiUrl(), endDate]);

  const handleDeleteMoment = (id) => {
    setShowDeleteAlert(true);
    setGetId(id);
  };

  const handleDeleteMomentClose = () => {
    setShowDeleteAlert(false);
  };

  const handleImageAlert = (img) => {
    setShowImageAlert(true);
    setImg(img);
  };

  const handleImageAlertClose = () => {
    setShowImageAlert(false);
  };

  const handleDeleteApi = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() +
        NetworkConfiguration.DELETEHOSTMOMENT +
        `/${getId}`,
      "POST",
      {
        resion: reason,
      }
    )
      .then((res) => {
        loader.showLoader(false);
        setShowDeleteReason(false);
        setShowDeleteAlert(false);
        successToast(res.message);
        fetchHostMoment();
      })
      .catch((err) => {
        loader.showLoader(false);
        errorToast(err.message);
      });
  };

  const handleMomentDelete = () => {
    setShowDeleteReason(true);
  };

  const handleDeleteReasonClose = () => {
    setShowDeleteReason(false);
  };

  const fetchHostMoment = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.HOSTMOMENT,
      "POST",
      { hostId: id, startDate, endDate, key: value, page, perPage }
    )
      .then((res) => {
        loader.showLoader(false);
        setGetHostMoment(res.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        loader.showLoader(false);
        setGetHostMoment([]);
      });
  };

  const handleLikesPost = (getId) => {
    setShowPostImage(true);
    setGetId(getId);
  };

  const handleLikesPostClose = () => {
    setShowPostImage(false);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportData = () => {
    const filename =`Host Moment Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleExportCsv = () => {
    const filename =`Host Moment Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };
  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="moment_btn">
        <label>Start Date</label>
        <input
          type="date"
          onChange={handleStartDate}
          value={startDate}
          max={new Date().toISOString().split("T")[0]}
        />
        <label>End Date</label>
        <input
          type="date"
          onChange={handleEndDate}
          value={endDate}
          max={new Date().toISOString().split("T")[0]}
          min={startDate}
        />
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
      </div>
      <div className="host__moment__container web_table_container">
        <div className="table_parent_box">
          <table className="host__moment__table">
            <thead>
              <th className="host__moment__header">S.No</th>
              {!id && <th className="host__moment__header">Host Name</th>}
              <th className="host__moment__header">Caption</th>
              <th className="host__moment__header">Likes</th>
              <th className="host__moment__header">Image/Video</th>
              {/* <th className="moment__table__head">Post Type</th> */}

              <th className="host__moment__header">Created At</th>
              <th className="host__moment__header">Action</th>
            </thead>
            <tbody>
              {getHostMoment?.length > 0
                ? getHostMoment.map((data, index) => {
                    return (
                      <tr>
                        <td className="host__moment__data">
                          {(page - 1) * perPage + index + 1}
                        </td>
                        {!id && (
                          <td className="host__moment__data">
                            {data?.hostId?.name}
                          </td>
                        )}
                        <td className="host__moment__data">{data?.subject}</td>
                        <td
                          className="host__moment__data"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleLikesPost(data?._id)}
                        >
                          {data?.likes}
                        </td>
                        <td className="host__moment__data ">
                          <AiFillEye
                            onClick={() => {
                              handleImageAlert(data?.postImage);
                            }}
                            className="host__moment__eye__icon"
                          />
                        </td>
                        {/* <td className="host__moment__data">
                          {data?.postTypes}
                        </td> */}
                        <td className="host__moment__data">
                          {moment(data?.postDate).format("DD/MM/YYYY , LT")}
                        </td>
                        <td className="host__moment__data">
                          {/* <AiFillEdit className="host__moment__edit__icon" /> */}
                          <AiFillDelete
                            onClick={() => {
                              handleDeleteMoment(data?._id);
                            }}
                            className="host__moment__delete__icon"
                          />
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
          {!getHostMoment?.length
            ? !loader.loaderPopup && (
                <div className="host__no__data__found__icon">
                  <Lottie
                    options={{ animationData: noData, loop: true }}
                    style={{ width: "40%", height: "30%" }}
                  />
                  <p className="host__no_data_found">No Data Found</p>
                </div>
              )
            : null}
        </div>

        <HostLikedProfile
          open={showPostImage}
          handleClose={handleLikesPostClose}
          id={getId}
        />

        <AlertPopUp
          open={showDeleteAlert}
          handleOpen={handleDeleteMoment}
          handleClose={handleDeleteMomentClose}
          submitText="Yes"
          cancelText="No"
          header="Delete Alert"
          description="Are you sure you want to delete this host moment?"
          onSubmitClick={handleMomentDelete}
          onCancelClick={handleDeleteMomentClose}
        />

        <AlertPopUp
          open={showDeleteReason}
          handleOpen={handleMomentDelete}
          handleClose={handleDeleteReasonClose}
          submitText="Yes"
          cancelText="No"
          textField={true}
          onChangeField={(e) => setReason(e.target.value)}
          header="Add a Reason"
          description="Add a reason as to why are you deleting this post?"
          onSubmitClick={handleDeleteApi}
          onCancelClick={handleDeleteReasonClose}
        />

        <ImagePopUpModal
          open={showImageAlert}
          handleClose={handleImageAlertClose}
          img={img}
        />
      </div>

      {getHostMoment && getHostMoment?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default HostMomentTable;
