import { AiFillDelete, AiFillEdit, AiFillEye } from "react-icons/ai";
import "./style.css";
import { useEffect, useState } from "react";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import moment from "moment";
import Button from "../../library/Button";
import ImagePopUpModal from "../../ImagePopUpModal";
import AlertPopUp from "../../AlertPopUp";
import FormAlertPopUp from "../../FormAlertPopUp";
import StickerForm from "../../formComponents/StickerForm";
import { errorToast, successToast } from "../../../utils/toast";
import { useLoader } from "../../../base/Context/loaderProvider";
import { FiSearch } from "react-icons/fi";
import SearchInput from "../../SearchInput";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import { useApi } from "../../../base/Context/apiProvider";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  exportToCSV,
  exportToExcel,
  exportToPDF,
} from "react-easy-export/dist/react-easy-export";
import jsPDF from "jspdf";
import { useRef } from "react";

const StickerTable = () => {
  const [getSticker, setGetSticker] = useState([]);
  const [img, setImg] = useState("");
  const [showImageAlert, setShowImageAlert] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showStickerForm, setShowStickerForm] = useState(false);
  const [showEditAlert, setShowEditAlert] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [id, setId] = useState("");
  const [editedSticker, setEditedSticker] = useState({
    name: "",
    price: "",
    stickerUrl: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();
const navigate=useNavigate()
  const [value, setValue] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  // const [searchParams, setSearchParams] = useSearchParams();
  const [exportAllData, setExportAllData] = useState([]);

  const loader = useLoader();
  const apiProvider = useApi();

  useEffect(() => {
    let array = [];
    getSticker.map((item, index) => {
      let obj = {
        "S No.": index + 1,

        "Sticker Id": item?.stickerId,
        "Sticker Name": item?.name,
        "Sticker Image": item?.stickerUrl,
        "Sticker Price": item?.price,
        "Created At": moment(item?.createdAt).format("DD/MM/YYYY , LT"),
        "Updated At": moment(item?.updatedAt).format("DD/MM/YYYY , LT"),
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [getSticker]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Stickers";
    const headers = [
      [
        "S No",
        "Sticker Id",
        "Sticker Name",
        "Sticker Image",
        "Sticker Price",
        "Created At",
        "Updated At",
      ],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,

      item1["Sticker Id"],
      item1["Sticker Name"],
      item1["Sticker Image"],
      item1["Sticker Price"],
      item1["Created At"],
      item1["Updated At"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`Stickers Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const handleDeleteAlert = () => {
    setShowDeleteAlert(true);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  // console.log("searchParams ", searchParams.get("appType"));

  useEffect(() => {
    fetchSticker();
  }, [value, page, perPage, apiProvider?.getApiUrl(), endDate]);

  const fetchSticker = () => {
    // const appType = searchParams.get("appType") === "host" ? "host" : "user";
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETSTICKER,
      "POST",
      {
        startDate,
        endDate,
        key: value,
        page,
        perPage,
      }
      // { appType }
    )
      .then((res) => {
        loader.showLoader(false);
        setGetSticker(res?.result);
        setTotalCount(res?.totalCount);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        loader.showLoader(false);
        setGetSticker([]);
        console.log(err);
      });
  };

  const handleEyeOnClick = (img) => {
    setImg(img);
    setShowImageAlert(true);
  };

  const handleEyeOnClickClose = () => {
    setShowImageAlert(false);
  };

  const handleOnClickAlert = (id) => {
    setId(id);
    setShowDeleteAlert(true);
  };

  const handleStickerForm = () => {
    setShowStickerForm(true);
  };

  const handleStickerFormClose = () => {
    setShowStickerForm(false);
    fetchSticker();
  };
const validate=()=>{
  
}
  const handleOnClickEdit = (id, sticker) => {
    setShowEditAlert(true);
    setId(id);
    setEditedSticker({
      name: sticker?.name,
      price: sticker?.price,
      stickerUrl: sticker?.stickerUrl,
    });
    setId(id);
  };

  const handleOnClickEditClose = () => {
    setShowEditAlert(false);
  };

  const onClickEdit = () => {
    setShowEditAlert(false);
    fetchSticker();
  };

  const handleDeleteApi = () => {
    loader.showLoader(true);

    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DELETESTICKER + `/${id}`,
      "DELETE"
    )
      .then((res) => {
        setShowDeleteAlert(false);
        loader.showLoader(false);

        // successToast(res.message);
        fetchSticker();
      })
      .catch((err) => {
        loader.showLoader(false);

        console.log(err);
        errorToast(err.message);
      });
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  // const handleExportPdf = () => {
  //   exportToPDF(exportAllData);
  // };

  const handleExportCSV = () => {
    const filename =`Stickers Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  const handleExportData = () => {
    const filename =`Stickers Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  return (
    <>
      <SearchInput
        value={value}
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
      />
      <div className="add__sticker">
        <label>Start Date</label>
        <input
          className="user_request_date"
          type="date"
          value={startDate}
          max={new Date().toISOString().split("T")[0]}
          onChange={handleStartDate}
        ></input>
        <label>End Date</label>
        <input
          className="user_request_date"
          type="date"
          value={endDate}
          min={startDate}
          max={new Date().toISOString().split("T")[0]}
          onChange={handleEndDate}
        ></input>
        <Button text="Export Excel" onClick={handleExportData} />
        <Button text="Export CSV" onClick={handleExportCSV} />
        <Button text="Export Pdf" onClick={exportPDF} />
        <Button text="Add Sticker" onClick={handleStickerForm} />
      </div>

      <div className="sticker_table_container web_table_container">
        <table className="sticker__table">
          <thead className="thead_fixed">
            <th className="sticker__table__heading">S.No</th>
            <th className="sticker__table__heading">Sticker ID</th>
            <th className="sticker__table__heading">Sticker Name</th>
            <th className="sticker__table__heading">Sticker Image</th>
            {searchParams.get("appType") === "host" ? (
              ""
            ) : (
              <th className="sticker__table__heading">Sticker Price</th>
            )}
              <th className="sticker__table__heading">No.of Shares</th>

            <th className="sticker__table__heading">Created At</th>
            <th className="sticker__table__heading">Updated At</th>
            <th className="sticker__table__heading">Action</th>
          </thead>
          <tbody>
            {getSticker && getSticker?.length > 0
              ? getSticker.map((data, index) => {
                  return (
                    <tr>
                      <td className="sticker__table__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="sticker__table__data">
                        {data?.stickerId}
                      </td>

                      <td className="sticker__table__data">{data?.name}</td>
                      <td className="sticker__table__data">
                        <AiFillEye
                          className="sticker__table__eye__icon"
                          onClick={() => {
                            handleEyeOnClick(data?.stickerUrl);
                          }}
                        />
                      </td>

                      {searchParams.get("appType") === "host" ? (
                        ""
                      ) : (
                        <td className="sticker__table__data">{data?.price}</td>
                      )}
                        <td className="sticker__table__data"onClick={()=>navigate(`/shareHistory/${data._id}`)}>{data?.count}</td>

                      <td className="sticker__table__data">
                        {moment(data?.createdAt).format("DD/MM/YYYY LT")}
                      </td>
                      <td className="sticker__table__data">
                        {moment(data?.updatedAt).format("DD/MM/YYYY LT")}
                      </td>
                      <td className="sticker__table__data">
                        <AiFillEdit
                          onClick={() => {
                            handleOnClickEdit(data?._id, data);
                          }}
                          className="sticker__table__edit__icon"
                        />
                        <AiFillDelete
                          onClick={() => {
                            handleOnClickAlert(data?._id);
                          }}
                          className="sticker__table__delete__icon"
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
        {!getSticker?.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{ width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
      </div>

      <ImagePopUpModal
        open={showImageAlert}
        handleClose={handleEyeOnClickClose}
        img={img}
      />

      <AlertPopUp
        open={showDeleteAlert}
        handleOpen={handleDeleteAlert}
        handleClose={handleDeleteAlertClose}
        header="Delete Alert"
        description="Are you sure you want to delete this sticker"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleDeleteApi}
        onCancelClick={handleDeleteAlertClose}
      />

      <FormAlertPopUp
        open={showStickerForm}
        handleOpen={handleStickerForm}
        onRequestClose={handleStickerFormClose}
      >
        <StickerForm onSubmit={handleStickerFormClose} />
      </FormAlertPopUp>

      <FormAlertPopUp
        open={showEditAlert}
        handleOpen={handleOnClickEdit}
        onRequestClose={handleOnClickEditClose}
      >
        <StickerForm
          edit={true}
          editedSticker={editedSticker}
          onClickEdit={onClickEdit}
          id={id}
        />
      </FormAlertPopUp>
      {getSticker && getSticker?.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          totalCount={totalCount}
          totalPages={totalPages}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default StickerTable;
