import { BsFillEyeFill } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AlertPopUp from "../../AlertPopUp";
import ImagePopUpModal from "../../ImagePopUpModal";
import moment from "moment/moment";
import "./style.css";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import {
  API_URL,
  NetworkConfiguration,
} from "../../../network/NetworkConfiguration";
import { useLoader } from "../../../base/Context/loaderProvider";
import SearchInput from "../../SearchInput";
import { FiSearch } from "react-icons/fi";
import Pagination from "../../Pagination";
import Lottie from "react-lottie";
import noData from "../../../base/Animation/No Data Found.json";
import FormAlertPopUp from "../../FormAlertPopUp";
import UserRequestForm from "../../formComponents/UserRequestForm";
import { Modal } from "../../../base/Context/modalProvider";
import { useApi } from "../../../base/Context/apiProvider";
import Button from "../../library/Button";
import { exportToExcel, exportToPDF, exportToCSV } from "react-easy-export";
import { IoCalendarNumber } from "react-icons/io5";
import jsPDF from "jspdf";

const UserTable = () => {
  let navigate = useNavigate();

  const [userRequest, setUserRequest] = useState([]);
  // const [showEyeAlert, setShowEyeAlert] = useState();
  const [showProfileAlert, setShowProfileAlert] = useState(false);
  const [img, setImg] = useState("");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showImageVideo, setShowImageVideo] = useState(false);
  const [images, setImages] = useState("");
  const [id, setId] = useState("");
  const [value, setValue] = useState("");
  const [showEditUser, setShowEditUser] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [exportAllData, setExportAllData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    let array = [];
    userRequest.map((item,index) => {
      let obj = {
        "S.No":index+1,
        "User ID": item?.userId,
        Name: item?.name,
        Gender: item?.gender,
        "Mobile Number": item?.mobileNumber,
        Email: item?.email,
        "Date Of Birth": item?.dateOfBirth,
        Age: item?.age,
        Country: item?.country,
        State: item?.state,
        City: item?.city,
        Profession: item?.proffession,
        Bio: item?.addBio,
        "Profile Pic": item?.profilePic,
        "Image/Video": item?.presentationPic.join("$$"),
        "Reason for change": item?.reasionUpdateProfile,
        "Created At": moment(item?.createdAt).format("DD/MM/YYYY, LT"),
        // "View Profile": item?.profilePic,
      };
      array.push(obj);
    });
    setExportAllData(array);
  }, [userRequest]);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;

    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Users Report";
    const headers = [
      [
        "S.No.",
        "User ID",
        "Name",
        "Gender",
        "Date Of Birth",
        "Age",
        "Mobile Number",
        "Email ID",
        "Country",
        "State",
        "City",
        "Profile Pic",
        "Image/Video",
        "Profession",
        "Bio",
        "Created At",
        // "Reason",
      ],
    ];
    console.log(exportAllData);

    const data = exportAllData.map((item1, index) => [
      index + 1,
      item1["User ID"],
      item1?.Name,
      item1?.Gender,
      item1["Date Of Birth"],
      item1.Age,
      item1["Mobile Number"],
      item1["Email"],
      item1?.Country,
      item1?.State,
      item1?.City,
      item1["Profile Pic"],
      item1["Image/Video"],
      item1?.Profession,
      item1?.Bio,
      item1["Created At"],
      // item1["Reason"],
    ]);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };
    const filename =`User Requests Report(${moment().format('DD-MM-YYYY hh:mm')}).pdf`;

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(filename);
  };

  const loader = useLoader();
  const modalProvider = useContext(Modal);
  const apiProvider = useApi();

  useEffect(() => {
    getUserRequest();
  }, [value, page, perPage, endDate, apiProvider?.getApiUrl()]);

  const handleUserEdit = (id) => {
    setShowEditUser(true);
    setId(id);
  };

  const handleUserEditClose = () => {
    setShowEditUser(false);
  };

  const handleDeleteAlert = () => {
    setShowDeleteAlert(true);
  };

  const handleDeleteAlertClose = () => {
    setShowDeleteAlert(false);
  };

  const handleUserDeleteClose = () => {
    setShowDeleteAlert(false);
  };

  const handleUserDeleteAlert = (id) => {
    setShowDeleteAlert(true);
    setId(id);
  };

  const handleEyeProfilePicPopUp = (img) => {
    setShowProfileAlert(true);
    setImg(img);
  };

  const handleEyeProfilePicPopUpClose = () => {
    setShowProfileAlert(false);
  };

  const handleImageVideoPopUp = (images) => {
    setShowImageVideo(true);
    setImages(images);
  };

  const handleImageVideoPopUpClose = () => {
    setShowImageVideo(false);
  };

  const handleUserDelete = () => {
    loader.showLoader(true);
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.DELETEUSER + `/${id}`,
      "DELETE"
    )
      .then((res) => {
        loader.showLoader(false);
        setShowDeleteAlert(false);
        getUserRequest();
      })
      .catch((err) => {
        loader.showLoader(false);
      });
  };

  const getUserRequest = () => {
    fetchDataFromAPI(
      apiProvider?.getApiUrl() + NetworkConfiguration.GETUSERS,
      "POST",
      {
        startDate,
        endDate,
        key: value,
        page,
        perPage,
      }
    )
      .then((res) => {
        setUserRequest(res?.result);
        setTotalCount(res.totalCount);
        setTotalPages(res.totalPages);
      })
      .catch((err) => {
        setUserRequest([]);
      });
  };

  const handleText = (e) => {
    setValue(e.target.value);
  };

  const searchIcon = () => {
    return <FiSearch />;
  };

  const onSubmit = () => {
    setShowEditUser(false);
    getUserRequest();
  };

  const handleExportData = () => {
    const filename =`User Requests Report(${moment().format('DD-MM-YYYY hh:mm')}).xls`;

    exportToExcel(exportAllData,filename);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleExportCsv = () => {
    const filename =`User Requests Report(${moment().format('DD-MM-YYYY hh:mm')}).csv`;

    exportToCSV(exportAllData,filename);
  };

  return (
    <>
      <SearchInput
        onChange={handleText}
        placeholder="Search"
        icon={searchIcon()}
        value={value}
        onKeyUp={handleText}
      />
      <div className="user_request_btn">
        <label>Start Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={startDate}
          onChange={handleStartDate}
          max={new Date().toISOString().split("T")[0]}
        ></input>
        <label>End Date</label>
        <input
          // className="user_request_date"
          type="date"
          value={endDate}
          min={startDate}
          max={new Date().toISOString().split("T")[0]}
          onChange={handleEndDate}
        ></input>

        <Button text="Export CSV" onClick={handleExportCsv} />
        <Button text="Export Pdf" onClick={exportPDF} />
        <Button text="Export Excel" onClick={handleExportData} />
      </div>
      <div className="user__request__table__container web_table_container">
        <table className="user__request__table">
          <thead>
            <th className="user__request__headers">S.No.</th>
            <th className="user__request__headers">User ID</th>
            <th className="user__request__headers">Name</th>
            <th className="user__request__headers">Gender</th>
            <th className="user__request__headers">Mobile Number</th>
            <th className="user__request__headers">Email</th>
            <th className="user__request__headers">Date Of Birth</th>
            <th className="user__request__headers">Age</th>
            <th className="user__request__headers">Country</th>
            <th className="user__request__headers">State</th>
            <th className="user__request__headers">City</th>
            <th className="user__request__headers">Profession</th>
            <th className="user__request__headers">Bio</th>
            <th className="user__request__headers">Profile Pic</th>
            <th className="user__request__headers">Image/Video</th>
            <th className="user__request__headers">Reason for change</th>
            <th className="user__request__headers">Created At </th>
            <th className="user__request__headers">View Profile</th>
            <th className="user__request__headers">Action</th>
          </thead>
          <tbody>
            {userRequest && userRequest.length > 0
              ? userRequest?.map((data, index) => {
                  return (
                    <tr>
                      <td className="user__request__data">
                        {(page - 1) * perPage + index + 1}
                      </td>
                      <td className="user__request__data">{data?.userId?data?.userId:'Deleted-user'}</td>
                      <td className="user__request__data">
                        <div
                          className="feedback__table__comment"
                          onClick={
                            data?.name?.length > 10
                              ? () =>
                                  modalProvider.handleCommentClick(
                                    data?.name,
                                    "Name"
                                  )
                              : () => {}
                          }
                        >
                          {data?.name?data?.name:'Deleted-user'}
                        </div>
                      </td>
                      <td className="user__request__data">
                        <div
                          className="feedback__table__comment"
                          onClick={
                            data?.gender?.length > 10
                              ? () =>
                                  modalProvider.handleCommentClick(
                                    data?.gender,
                                    "Gender"
                                  )
                              : () => {}
                          }
                        >
                          {data?.gender}
                        </div>
                      </td>
                      <td className="user__request__data">
                        {data?.mobileNumber}
                      </td>
                      <td className="user__request__data">{data?.email}</td>
                      <td className="user__request__data">
                        {data?.dateOfBirth}
                      </td>
                      <td className="user__request__data">{data?.age}</td>
                      <td className="user__request__data">
                        <div
                          className="feedback__table__comment"
                          onClick={
                            data?.country?.length > 10
                              ? () =>
                                  modalProvider.handleCommentClick(
                                    data?.country,
                                    "Name"
                                  )
                              : () => {}
                          }
                        >
                          {data?.country}
                        </div>
                      </td>
                      <td className="user__request__data">{data?.state}</td>
                      <td className="user__request__data">{data?.city}</td>

                      <td className="user__request__data">
                        <div
                          className="feedback__table__comment"
                          onClick={
                            data?.proffession?.length > 12
                              ? () =>
                                  modalProvider.handleCommentClick(
                                    data?.proffession,
                                    "Profession"
                                  )
                              : () => {}
                          }
                        >
                          {data?.proffession}
                        </div>
                      </td>
                      <td className="user__request__data">
                        <div
                          className="feedback__table__comment"
                          onClick={
                            data?.addBio?.length > 12
                              ? () =>
                                  modalProvider.handleCommentClick(
                                    data?.addBio,
                                    "Name"
                                  )
                              : () => {}
                          }
                        >
                          {data?.addBio}
                        </div>
                      </td>

                      <td className="user__request__data">
                        {data?.profilePic && (
                          <BsFillEyeFill
                            onClick={() => {
                              handleEyeProfilePicPopUp(data?.profilePic);
                            }}
                            className="user__request__eye__icon"
                          />
                        )}
                      </td>

                      <td className="user__request__data">
                        <BsFillEyeFill
                          onClick={() => {
                            handleImageVideoPopUp(data?.presentationPic);
                          }}
                          className="user__request__eye__icon"
                        />
                      </td>
                      <td className="user__request__data">
                        {data?.reasionUpdateProfile
                          ? data?.reasionUpdateProfile
                          : "-"}
                      </td>
                      <td className="user__request__data">
                        {" "}
                        {moment(data?.createdAt).format("DD/MM/YYYY LT")}
                      </td>
                      <td
                        className="user__request__data user__management__view__btn"
                        onClick={() => {
                          navigate(
                            `/usermanagement/${data?._id}${
                              sessionStorage.getItem("selectedType") ===
                              "catchwoo"
                                ? "?appType=catchwoo"
                                : ""
                            }`
                          );
                        }}
                      >
                        VIEW MORE...
                      </td>
                      <td className="user__request__data">
                        <AiFillEdit
                          onClick={() => handleUserEdit(data?._id)}
                          className="accepted__user__edit__icon"
                        />
                        <AiFillDelete
                          onClick={() => {
                            handleUserDeleteAlert(data?._id);
                          }}
                          className="accepted__user__delete__icon"
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>

        {!userRequest.length
          ? !loader.loaderPopup && (
              <div className="host__no__data__found__icon">
                <Lottie
                  options={{ animationData: noData, loop: true }}
                  style={{width: "40%", height: "30%" }}
                />
                <p className="no__data__found">No Data Found</p>
              </div>
            )
          : null}
      </div>

      <FormAlertPopUp
        open={showEditUser}
        handleOpen={handleUserEdit}
        onRequestClose={handleUserEditClose}
      >
        <UserRequestForm id={id} onSubmit={onSubmit} />
      </FormAlertPopUp>

      <AlertPopUp
        open={showDeleteAlert}
        handleOpen={handleDeleteAlert}
        handleClose={handleDeleteAlertClose}
        header="Delete Alert"
        description="Are you sure you want to delete this User?"
        submitText="Yes"
        cancelText="No"
        onSubmitClick={handleUserDelete}
        onCancelClick={handleUserDeleteClose}
      />

      <ImagePopUpModal
        open={showProfileAlert}
        handleClose={handleEyeProfilePicPopUpClose}
        img={img}
      />

      <ImagePopUpModal
        open={showImageVideo}
        handleClose={handleImageVideoPopUpClose}
        images={images}
      />
      {userRequest.length > 0 ? (
        <Pagination
          page={page}
          setPage={setPage}
          totalCount={totalCount}
          totalPages={totalPages}
          setPerPage={setPerPage}
          perPage={perPage}
          options={[20, 25, 30, 35]}
        />
      ) : null}
    </>
  );
};

export default UserTable;
