import { useState } from "react";
import Button from "../../library/Button";
import InputField from "../../library/InputField";
import "./style.css";
import Dropdown from "../../library/Dropdown";
import { fetchDataFromAPI } from "../../../network/NetworkConnection";
import { useApi } from "../../../base/Context/apiProvider";
import { NetworkConfiguration } from "../../../network/NetworkConfiguration";
import { useNavigate, useSearchParams } from "react-router-dom";
import { errorToast, successToast } from "../../../utils/toast";
import { useColorScheme } from "@mui/material";
import { useLoader } from "../../../base/Context/loaderProvider";

const InactiveUserForm = ({
  data,
  setShowNotificationPopup,
  user,
  allData,
  checkHeaderClick,
  setData,
  setAllData,
}) => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [image, setImage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState({
    titleError: "",
    bodyError: "",
    imageError: "",
  });

  const apiProvider = useApi();
  const loader = useLoader();
  const url = user
    ? NetworkConfiguration.INACTIVEUSER
    : NetworkConfiguration.INACTIVEHOST;

  const DropdownOptions = [
    {
      name: "--Select --",
    },
    {
      name: "Low Balance",
      value: "Low Balance",
    },
    {
      name: "Marketing",
      value: "Marketing",
    },
  ];

  const handleTitle = (e) => {
    setError({ ...error, titleError: "" });
    setTitle(e.target.value);
  };

  const handleBody = (e) => {
    setError({ ...error, bodyError: "" });
    setBody(e.target.value);
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleInactiveUser = () => {
    console.log(data,allData)
    if (validate()) {
      loader.showLoader(true);
      let notificationPayload = new FormData();
      notificationPayload.append("title", title);
      notificationPayload.append("body", body);
      notificationPayload.append("image", image);
      data?.map((item) => {
        notificationPayload.append(
          `${searchParams.get("appType") === "host" ? "hosts" : "users"}`,
          item.id?item?.id:item
        );
      })
      // allData.map((item) => {
      //   notificationPayload.append(
      //     `${searchParams.get("appType") === "host" ? "hosts" : "users"}`,
      //     item
      //   );
      // });}
console.log(notificationPayload,'notopayload')
      fetchDataFromAPI(
        apiProvider?.getApiUrl() + url,
        "POST",
        notificationPayload,
        {
          "Content-Type": "multipart/form-data",
        }
      )
        .then((res) => {
          loader.showLoader(false);
          setShowNotificationPopup(false);
          navigate(
            `${
              searchParams.get("appType")
                ? "/notification?appType=" + searchParams.get("appType")
                : "/notification"
            }`
          );
          setAllData([]);
          setData([]);
          successToast(res?.message);
        })
        .catch((err) => {
          console.log(err, "error response");
          loader.showLoader(false);
          errorToast(err?.message);
        });
    }
  };

  console.log("image", image);

  const validate = () => {
    let result = true;
    if (!title) {
      setError({ ...error, titleError: "Enter valid title" });
      result = false;
    } else if (!body) {
      setError({ ...error, bodyError: "Enter valid body" });
      result = false;
    }
    return result;
  };

  return (
    <div style={{ padding: "2px 0" }}>
      <h2 className="create__wallet__header">Send Notification</h2>

      <div style={{ padding: "1rem 2rem" }}>
        <Dropdown options={DropdownOptions} />
        <br />
        <InputField
          type="text"
          onChange={handleTitle}
          placeholder="Enter title"
          error={error.titleError}
          value={title}
        />
        <br />
        <InputField
          type="text"
          onChange={handleBody}
          placeholder="Enter body"
          error={error.bodyError}
          value={body}
        />
        <br />

        <InputField
          type="file"
          onChange={handleImage}
          error={error.imageError}
          accept=".png , .jpg , .jpeg"
        />
        <br />
        <Button
          onClick={handleInactiveUser}
          style={{ margin: "auto" }}
          text="Submit"
        />
      </div>
    </div>
  );
};

export default InactiveUserForm;
